import React from "react";
import { Route, Switch } from "react-router-dom";
import {Redirect, withRouter} from "react-router";
import {connect} from 'react-redux';
import {compose} from "redux";

import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";

import LogIn from "../LogIn/index";
import ResetPassword from "../ResetPassword/index";
import UpdatePassword from "../UpdatePassword/index";
import MainLayoutPages from './MainLayoutPages';

const Pages = () => (
    <Switch>
        <Route path="/" component={MainLayoutPages} />
    </Switch>
)

const Router = ({user}) => {
    return (
        <MainWrapper>
            <main>
                <Switch>
                    <Route exact path="/login" component={LogIn} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/update-password/:resetPasswordToken" component={UpdatePassword} />
                    <Route path="/" component={MainLayoutPages} />
                    {/*<Route path="/" render={() => user && user._id ? <MainLayoutPages/> : null } />*/}
                </Switch>
            </main>
        </MainWrapper>
    )
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default compose(
    withRouter,
    connect(mapStateToProps),
)(Router);
