import Select from './components/Select/Select'
import MultiSelect from './components/MultiSelect/MultiSelect'
import InputField from './components/InputField/InputField'
import TextAreaField from './components/TextAreaField/TextAreaField'
import CheckBoxField from './components/CheckBoxField/CheckBoxField'
import DatePicker from "./components/DatePicker/DatePicker";
import MultiDatesPicker from "./components/MultiDatesPicker/MultiDatesPicker";
import DropZone from "./components/DropZone/DropZone"
import WorkingHours from "./components/WorkingHours/WorkingHours"
import ProvidedServices from "./components/ProvidedServices/ProvidedServices"
import TreeSelect from "./components/TreeSelect/TreeSelect";

export default {
    text: InputField,
    number: InputField,
    textarea: TextAreaField,
    select: Select,
    multiSelect: MultiSelect,
    boolean: CheckBoxField,
    date: DatePicker,
    dateTime: DatePicker,
    multiDatesPicker: MultiDatesPicker,
    treeSelect: TreeSelect,
    workingHours: WorkingHours,
    providedServices: ProvidedServices,
    enum: Select,
    file: DropZone,
}
