import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from "moment";
class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.value,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { name, onChange, touch } = this.props;
    this.setState({
      startDate: date,
    });
    touch(name);
    if (date)
      onChange(date.valueOf());
  }

  render() {
    const { startDate } = this.state;
    const {value, meta: {touched, error} } = this.props;

    return (
        <div className="date-picker">
          <DatePicker
              className="form__form-group-datepicker"
              selected={new Date(startDate|| new Date().valueOf())}
              onChange={this.handleChange}
              dateFormat="dd/MM/yyyy"
              dropDownMode="select"
          />
          {touched && error && <span className="form__form-group-error">{error}</span>}
        </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input, ...rest } = props;

  return <DatePickerField {...input} {...rest} />;
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDatePickerField;
