import React, { PureComponent } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

class MultiSelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string
      })
    ),
    value: PropTypes.any.isRequired
  };

  static defaultProps = {
    placeholder: "",
    options: []
  };

  handleChange = v => {
    const { onChange } = this.props;
    const value = v && v.length ? v.map(x => x.value) : v;
    onChange(value);
  };

  render() {
    let { value, name, placeholder, options ,disabled} = this.props;

    if (value.length) {
      value = value.map(v => {
        if (typeof v === "object") return v;

        return options.find(option => option.value === v);
      });
    }

    return (
      <Select
        isMulti
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        closeOnSelect={false}
        removeSelected={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
      />
    );
  }
}

const renderMultiSelectField = props => {
  const { input, meta, options, placeholder, disabled } = props;
  return (
    <div className="form__form-group-input-wrap">
      <MultiSelectField
          disabled={disabled}

          {...input}
        options={options}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

renderMultiSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    })
  ),
  placeholder: PropTypes.string
};

renderMultiSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: ""
};

export default renderMultiSelectField;
