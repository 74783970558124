import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import '../../scss/component/check-box.scss';

import classNames from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import {localize} from "../../services/translation/localization";

export default class CheckBoxField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]),
        label: PropTypes.string,
        defaultChecked: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        color: PropTypes.string,
    };

    static defaultProps = {
        label: '',
        defaultChecked: false,
        disabled: false,
        className: '',
        color: '',
        // onChange: () => {}
    };

    componentDidMount () {
        // const { onChange, defaultChecked, input } = this.props;
        // onChange(defaultChecked);
    }

    render () {
        const {
            disabled, className, name, type, onChange, label, color, containerClass = '',
            input, meta: {touched, error}
        } = this.props;
        const CheckboxClass = classNames({
            'checkbox-btn': true,
            disabled,
        });
        const errorClass = touched && error ? 'error-color' : '';
        const hiddenClass = type === 'hidden' ? 'hidden' : '';
        const containerClasses = ['input-group', containerClass, errorClass, hiddenClass];

        return (
            <div className={containerClasses.join(' ')}>
                <div className="label-input">
                    <label
                        className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`}
                        htmlFor={name}
                    >
                        <span className="checkbox-btn__label">{label}</span>
                        <input
                            {...input}
                            className="checkbox-btn__checkbox"
                            type="checkbox"
                            id={name}
                            name={name}
                            checked={input.value}
                            disabled={disabled}
                        />
                        <span
                            className="checkbox-btn__checkbox-custom"
                            style={color ? {background: color, borderColor: color} : {}}
                        >
                            <CheckIcon/>
                         </span>
                        {className === 'button' ? (
                            <span className="checkbox-btn__label-svg">
                                <CheckIcon onchange={() => {}} className="checkbox-btn__label-check"/>
                                <CloseIcon className="checkbox-btn__label-uncheck"/>
                            </span>
                        ) : ''}
                    </label>
                </div>
            </div>
        );
    }
}
