import React from "react";
import {connect} from "react-redux";
import moment from "moment";

// services
import {localize, localized} from "../../services/translation/localization";
import {updateAppointment} from "../../services/appointment";

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';

// components
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";
import AppointmentModal from "../../components/AppointmentModal";
import ConfirmationModal from "../../shared/Modal/ConfirmationModal";
import EnumsService from "../../services/enums";

class AppointmentsManagement extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            taskTypesModalService: null,
            refreshSwitch: false,
        };

    }

    componentDidMount () {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.appointments_management'));
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.appointments_management'));
        }
    }

    openAppointmentModal = () => {
        this.setState({appointmentModal: true})
    }
    
    closeAppointmentModal = () => {
        this.setState({appointment: null, appointmentModal: false, refreshSwitch: !this.state.refreshSwitch})
    }

    closeDeleteModal = () => {
        this.setState({deleteModal: false, appointment: null})
    }

    handleDeleteAppointment = async () => {
        let { appointment } = this.state;
        await updateAppointment(appointment._id, {...appointment, status: 'deleted'})
        this.closeDeleteModal();
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    toggleArrived = async (appointment) => {
        const {service, car, arrived} = appointment;
        await updateAppointment(appointment._id, {arrived: !arrived, service: service._id, car: car._id});
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }
    
    render () {
        const {data, enums, roles, user} = this.props;
        const {deleteModal, refreshSwitch} = this.state;

        const tableConfig = {
            model: `appointments`,
            modelDisplayName: localize(null, 'pages.titles.appointment'),
            excelFileName: "דוח תורים",
            actions: [
                // {
                //     callback: (data) => this.toggleArrived(data),
                //     icon: 'check',
                //     title: 'סמן אם הלקוח הגיע',
                //     hidden: (data) => moment().isAfter(data.appointmentDate),
                // },
                'view',
                {
                    callback: (data) => this.setState({appointmentModal: true, appointment: data}),
                    icon: 'edit',
                    title: 'ערוך תור',
                    // hidden: (data) => moment().isAfter(data.appointmentDate),
                },
                {
                    callback: (data) => this.setState({deleteModal: true, appointment: data}),
                    icon: 'delete',
                    title: 'מחק תור',
                    hidden: (data) => moment().isAfter(data.appointmentDate),
                }
            ],
            endpoints: {
                create: "appointments/create",
                edit: "appointments"
            },
            handlers: {
                // "PRE_CREATE": this.onCreate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    showFilter: false
                },
                {
                    accessor: "appointmentDate",
                    alias: localize(null, "pages.appointments.appointmentDate"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: true
                },
                {
                    accessor: "car.number",
                    alias: localize(null, "pages.appointments.car"),
                    type: "text",
                    // getOptions: async (data, mode, user) => {
                    //     if(data.user) {
                    //         let res = await http.get('cars', {$filter: {field: 'id', text: data.user.cars}});
                    //         return res.map((e) => {
                    //             return {
                    //                 value: e._id,
                    //                 label: e.number,
                    //             }
                    //         })
                    //     } else {
                    //         return [];
                    //     }
                    // },

                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "user.fullname",
                    alias: localize(null, "pages.appointments.user"),
                    type: "text",
                    displayModes: [SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "user.phone",
                    alias: localize(null, "smartTable.fields.phone"),
                    type: "text",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "status",
                    alias: localize(null, "pages.stands.status"),
                    type: "select",
                    options: enums.appointmentStatus.map((e) => {
                        return {
                            value: e.value,
                            label: e.display,
                        }
                    }),
                    displayModes: [SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "branch",
                    alias: localize(null, "pages.stands.branchName"),
                    type: "select",
                    options: data.branches.map((e) => {
                        return {
                            value: e.id,
                            label: e.displayName,
                        }
                    }),
                    displayModes: user.role === roles.admin.value ? [SmartFormMode.view, SmartFormMode.edit, SmartFormMode.new] : [],
                    allowTableView: user.role === roles.admin.value,
                    show: false,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "service",
                    alias: localize(null, "pages.appointments.name"),
                    type: "select",
                    options: data.services.map((e) => {
                        return {
                            value: e._id,
                            label: e.label,
                        }
                    }),
                    displayModes: [SmartFormMode.view, SmartFormMode.edit, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "cost",
                    alias: localize(null, "pages.appointments.cost"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: true
                },
                {
                    accessor: "onlinePayment",
                    alias: localize(null, "pages.appointments.onlinePayment"),
                    type: "boolean",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "isPaid",
                    alias: localize(null, "pages.appointments.isPaid"),
                    type: "boolean",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "arrived",
                    alias: localize(null, "pages.appointments.arrived"),
                    type: "boolean",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "service.appointmentDuration",
                    alias: localize(null, "pages.appointments.appointmentDuration"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "appointmentStartingDate",
                    alias: localize(null, "pages.appointments.appointmentStartingDate"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    showFilter: true,
                    searchable: false
                }
            ],
            restOptions: {
                filter: {status: ['approved']},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "appointmentDate",
                    order: "desc"
                }
            },
        }

        const {appointmentModal, appointment} = this.state;
        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.appointments_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {tableConfig && <SmartWrapper filterable={false} allowAdd={false} allowExport={true}
                                                  disableFreeSearch={true}
                                                  viewButtonCallback={this.handleViewService}
                                                  tableConfig={tableConfig}
                                                  refreshSwitch={refreshSwitch}
                                                  actions={[
                                                      {
                                                          icon: "add",
                                                          label: localize(null, 'smartTable.addButton'),
                                                          onClick: this.openAppointmentModal,
                                                      }
                                                  ]}/>}
                </Row>
                <AppointmentModal isOpen={appointmentModal} appointment={appointment}
                                  onClose={this.closeAppointmentModal}/>
                <ConfirmationModal
                    isOpen={deleteModal}
                    onConfirm={this.handleDeleteAppointment}
                    onCancel={this.closeDeleteModal}
                    title={localize(null, "pages.appointments.deleteModal.title")}
                    description={<p>{localize(null, "pages.appointments.deleteModal.message")}</p>}
                />
                {/*{taskTypesModalService && <TaskTypes appointment={taskTypesModalService} onClose={() => this.setState({taskTypesModalService: null})}/>}*/}
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    data: state.app.data,
    enums: state.app.enums,
    roles: EnumsService.getEnumAsObject('permissions'),
    user: state.auth.user,
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsManagement);
