import React from 'react';
import moment from 'moment';
import { localize } from '../../../../../../../services/translation/localization'

import Enums from '../../../../../../../services/enums'

// Types/Components
class FilterTypes {

    // Customize cell value that is rendered on UI
    getFieldValue(field, handleView, handleEdit) {
        switch (field.type) {
            case 'date':
                return {
                    Cell: ({ value }) => (value && moment(value).isValid()) ? moment(value).format('DD/MM/YYYY') : ''
                }
            case 'dateTime':
                return {
                    Cell: ({ value }) => (value && moment(value).isValid()) ? moment(value).format('DD/MM/YYYY HH:mm') : ''
                }
            case 'time':
                return {
                    Cell: ({ value }) => (value && moment(value).isValid()) ? moment(value).format('HH:mm') : ''
                }
            case 'number':
                return {
                    Cell: ({value}) => (value || value==0) ? value.precision >= 0 ? parseFloat(value.toFixed(value.precision)) : value : '',

                }
            case 'boolean':
                return {
                    Cell: ({ value }) => value ? localize(null, 'smartForm.trueValue') : localize(null, 'smartForm.falseValue')
                }

            case 'select':
                return {
                    Cell: ({ value }) => {
                        let options = field.options || [];
                        const option = options.find(option => typeof value === 'object' ? option.value === value._id || option.value === value.id : option.value === value)
                        return option ? option.label : '';
                    }
                }
            case 'multiSelect':
                return {
                    Cell: ({ value }) => {
                        let options = field.options || [];
                        return value && value.length ? value.map(v => options.find(option => option.value === v).label).join(', ') : ''
                    }
                }
            case 'actions':
                return {
                    Cell: ({ original }) =>
                        <div className="button-container">
                            <button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleEdit(original);
                                }}
                                className="action-icon-pencil"
                            >
                            </button>
                            <button
                                onClick={() => handleView(original)}
                                className="action-icon-eye"
                            >
                            </button>
                        </div>
                    ,
                }
            case 'enum':
                const enums = Enums.getEnum(field.optionsKey);
                return {
                    Cell: ({ value }) => {

                        for (const item of enums) {
                            if (item.id == value) {
                                return (item.displayName);
                            }
                        }
                        return null;
                    }
                }
            default:
                return null;
                break;
        }
    }
}

export default new FilterTypes();
