import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import localize from "../translation/localization";

class TextLocalize extends PureComponent {
    render() {
        const { language, text } = this.props;

        if (!language || !text) return null;

        return (
            <Fragment>
                {localize(language.name, text)}
            </Fragment>
        );
    }
}

TextLocalize.propTypes = {
    text: PropTypes.string.isRequired
};

export default connect(state => ({ language: state.app.language }))(TextLocalize);
