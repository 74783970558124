import moment from 'moment'

const HebrewChars = new RegExp("^[\u0590-\u05FF ]+$");
const AlphaNumericChars = new RegExp("^[a-zA-Z0-9\-]+$");
const EnglishChars = new RegExp("^[a-zA-Z\-]+$");
const LegalChars = new RegExp("^[0-9\-\u0590-\u05FF ]+$");
const isNumber = value => typeof value === 'number';
const isIDNumber = value => value && !/^[0-9]{9}$/i.test(value) ? 'מספר תעודת זהות לא תקין' : undefined;
const isValidIsraeliID = (id) => {
    id = String(id).trim();
    if (id.length > 9 || id.length < 5 || isNaN(id)) return 'מספר תעודת זהות לא תקין';
    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    return (/\d{9}/.test(id) && Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0) ? undefined : 'תעודת זהות לא תקינה'
};
const isVerificationCode = value => value && !/^[0-9]{6}$/i.test(value) ? 'קוד אימות לא תקין, אנא הכנס קוד בן 6 ספרות' : undefined;
const isFullName = value => value && !/^([א-ת'-.]{2,}( {1}[א-ת'-.]{2,})+)$/i.test(value) ? 'שם לא תקין' : undefined;
const isPhoneNumber = value => value && !/^([0-9]{3}-?[0-9]{3}-?[0-9]{3,4})$/i.test(value) ? 'מספר טלפון לא תקין' : undefined;
const isAlpha = value => value && !HebrewChars.test(value) && !EnglishChars.test(value) ? 'נא להזין שם בעברית' : undefined;
const isAddress = value => value && !LegalChars.test(value) ? 'השדה יכול להכיל רק אותיות' : undefined;
const required = value => value ? undefined : 'שדה נדרש';
const checkAppointmentHour = (value, availableAppointments) => {
    if (required(value)) {
        return required(value);
    }
    if (!availableAppointments.includes(value.valueOf())) {
        return 'נא לבחור תאריך פנוי'
    }
    return undefined
}
/*const checkAppointmentHour = (value, allValues, props) => {
    if (!props.availableHours.includeTimes.includes(value.valueOf())) {
        return 'נא לבחור תאריך פנוי'
    }
    return undefined
}*/
const multiRequired = value => value ? (value.length === 0 ? "לפחות בחירה אחת נדרשת" : undefined) : 'שדה נדרש';
const yesOrNoRequired = value => value === undefined ? 'אנא בחר שיטת תשלום' : undefined;
const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'כתבות אימייל לא תקינה' : undefined;
const maxLength = max => value => value && value.length < max ? `Must be ${max} characters long or more` : undefined;
const checkCars = (value, allValues) => {
    if ((allValues.cars === undefined || allValues.cars.length === 0)) {
        return 'נא להזין לפחות רכב אחד'
    }
    return undefined
};

const timeSpan = value => {
    if (value) {
        const {from, to} = (value || {});
        if (!isNumber(from.hour) || !isNumber(from.minutes) || !isNumber(to.hour) || !isNumber(to.minutes)) {
            console.log('Please fill all from and to fields');
            return 'Please fill all from and to fields';
        }
        const fromTime = moment().add(from.hour, 'hour').add(from.minutes, 'minutes');
        const toTime = moment().add(to.hour, 'hour').add(to.minutes, 'minutes');
        if (fromTime.isAfter(toTime)) {
            return 'Invalid time, open time must be before close time'
        }
        return undefined;
    }
};
const passwordValidator = maxLength(8);
const passwordsMatch = (value, allValues) => {
    if (allValues.newPassword !== allValues.newPasswordConfirm) {
        return 'שדות הסיסמאות החדשות אינם תואמים'
    }
    return undefined
};

const normalizeNumber = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const normalizeCar = value => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
        return onlyNums
    }
    if (onlyNums.length <= 5) {
        return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}`
    }
    if (onlyNums.length <= 7) {
        return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}-${onlyNums.slice(5, 7)}`
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 8)}`

};

const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
    }
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10)
}

const normalizeCode = value => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 6) {
        return onlyNums
    }
    return `${onlyNums.slice(0, 6)}`
};

const normalizeCreditCard = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 4) {
            return onlyNums + ' '
        }
        if (onlyNums.length === 8) {
            return onlyNums.slice(0, 4) + ' ' + onlyNums.slice(4) + ' '
        }
        if (onlyNums.length === 12) {
            return onlyNums.slice(0, 4) + ' ' + onlyNums.slice(4, 8) + ' ' + onlyNums.slice(8) + ' '
        }
    }

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`
    }
    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4,8)} ${onlyNums.slice(8)}`
    }
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8, 12)} ${onlyNums.slice(12, 16)}`
};

const digitsOnly = value => value.replace(/[^\d]/g, '');

const cvvNumber = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3 || onlyNums.length === 4) {
            return onlyNums
        }
    }
    if (onlyNums.length <= 4) {
        return onlyNums
    }
    return onlyNums.slice(0, 4)
}

const citizenIdNumber = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 9) {
            return onlyNums
        }
    }
    if (onlyNums.length <= 9) {
        return onlyNums
    }
    return onlyNums.slice(0, 9)
}

export {
    isIDNumber,
    isValidIsraeliID,
    isVerificationCode,
    isPhoneNumber,
    isAlpha,
    isFullName,
    isAddress,
    isNumber,
    required,
    checkAppointmentHour,
    multiRequired,
    email,
    maxLength,
    timeSpan,
    passwordsMatch,
    yesOrNoRequired,
    checkCars,
    passwordValidator,
    normalizeNumber,
    normalizeCar,
    normalizePhone,
    normalizeCode,
    normalizeCreditCard,
    cvvNumber,
    citizenIdNumber,
    digitsOnly
}
