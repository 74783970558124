import React from 'react';

// Components
import DateRange from './dateRange/dateRange';
import NumberRange from './number/number';

// Third party libraries
import Moment from 'moment';

// Types/Components

// Implements two methods:
// filterMethod and Filter
class FilterComponents {
    get(field) {
        switch (field.type) {

            case 'select':
            case 'enum':
                const options = field.options;
                return {
                    // filter logic
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        else if (Number(filter.value) === row[field.accessor]._id) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    },
                    // filter html
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ""}
                            className='singleselect'
                        >
                            <option value="">הצג הכל</option>
                            {
                                options.map(option => {
                                    return (<option key={option.value} value={option.value}>{option.label}</option>)
                                })
                            }
                        </select >,
                };
            case 'dateTime':
                return {
                    // filter the rows with the selected date
                    filterMethod: (filter, row) => {
                        // compare row date with the range from the date picker on UI
                        if (filter.value) {
                            const startDate = Moment(filter.value[0]);
                            const endDate = Moment(filter.value[1]);
                            const rowDate = Moment(new Date(row.date));
                            return rowDate.isBetween(startDate, endDate);
                        } else {
                            return true;
                        }
                    },
                    // filter html
                    Filter: ({ filter, onChange }) =>
                        <DateRange endDate={"01/01/2999"} startDate={"01/01/2000"} onChange={selected => onChange(selected)} />
                };
            case 'search':
                return {
                    //search table by match of the Cell value with the provided string from UI
                    filterMethod: (filter, row) => {
                        return String(row[filter._id]).includes(filter.value);
                    },
                };
            case 'boolean':
                return {
                    Filter: ({filter, onChange}) => {
                        return (
                            <select
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ""}
                                className='singleselect'
                            >
                                <option value="">כן/לא</option>
                                {
                                    [{label: 'כן', value: 'Yes'}, {label: 'לא', value: 'No'}].map(option => {
                                        return (<option key={option.value} value={option.value}>{option.label}</option>)
                                    })
                                }
                            </select >
                        )
                    }
                }
            default:
                return null;
        }
    }
}

export default new FilterComponents();
