import React, {Component} from 'react';


// Import third party
import ReactTable from "react-table-v6";
// import "react-table/react-table.css";
import 'react-table-v6/react-table.css'

import {ReactTableDefaults} from 'react-table-v6'
import moment from "moment";

// Services
import {localize} from '../../../../../../services/translation/localization'
import SmartService from '../../SmartWrapperService'
// Import components
import ColumnSelector from './columnSelector/ColumnSelector';
import FilterComponents from './filterComponents/filterComponents';
import smartTableService from './SmartTableService';
import filterTypes from './filterTypes/filterTypes';
import headerComponent from './headerComponent/headerComponent';

//images
import search from '../../assets/search.png';
import PlusIcon from 'mdi-react/PlusIcon'
import ExportIcon from 'mdi-react/ExportIcon'
import TextLocalize from "../../components/TextLocalize/TextLocalize";
import {localized} from "../../../../../../services/translation/localization";
import Action from "./actions/action";
import {Icon} from "@material-ui/core";


export default class SmartTable extends Component {

    constructor (props) {
        super(props);

        this.state = {
            shouldReload: this.props.shouldReload,
            columns: [],
            data: [],
            pages: null,
            loading: true,
            filtered: [],
            searchFields: '',
            tableState: null
        };
        this.filters = {search: {}}
        this.pageSize = this.props.defaultPageSize;
        this.sorted = props.tableConfig.restOptions.defaultSort ? [{
            id: props.tableConfig.restOptions.defaultSort.field,
            desc: props.tableConfig.restOptions.defaultSort.order == "desc" ? false : true
        }] : null;
        this.toggleColumnSelector = this.toggleColumnSelector.bind(this);
        this.toggleFilterSelector = this.toggleFilterSelector.bind(this);
        this.onFilteredChange = this.onFilteredChange.bind(this);
    }

    // init method
    componentDidMount = () => {
        this.getColumns();
        this.fetchData(this)
        this.setState({
            tableState: {
                page: 0,
                pageSize: this.props.defaultPageSize,
                sorted: this.props.tableConfig.restOptions.defaultSort ? [{
                    id: this.props.tableConfig.restOptions.defaultSort.field,
                    desc: this.props.tableConfig.restOptions.defaultSort.order == "desc" ? false : true
                }] : null
            }
        })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.tableConfig) !== JSON.stringify(this.props.tableConfig) || this.props.shouldReload !== prevProps.shouldReload) {
            let {tableConfig} = this.props;
            this.getColumns();
            smartTableService
                .requestMoreData(this.state.tableState, this.props.data, this.props.tableConfig)
                .then(res => {
                    //execute post-fetch actions if exist
                    let handlers = tableConfig.handlers && tableConfig.handlers.POST_FETCH ? [tableConfig.handlers.POST_FETCH] : [];
                    if (handlers && res.rows) { //execute post fetch
                        res.rows = res.rows.map(doc => {
                            for (let i = 0; i < handlers.length; i++) {
                                doc = handlers[i](doc);
                            }
                            return (doc);
                        })
                    }
                    // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
                    this.setState({
                        data: res.rows,
                        pages: res.pages,
                        loading: false
                    });
                });
        }
    }

    getColumns = () => {
        const {tableConfig, data, filterable} = this.props;
        let columns = [];
        columns = tableConfig.fields.filter(field => field.allowTableView).map(field => (
            {
                ...field, // spread the field
                ...FilterComponents.get(field), // spread components
                ...filterTypes.getFieldValue(field, this.handleView, this.handleEdit), // spread types
                ...headerComponent.get(field, this.toggleFilterSelector, this.handleSort, field.showFilter), // spread the filter button component that handles the event from table header
            }
        ));

        if (tableConfig.actions)
            columns = columns.concat([this.getActions(tableConfig)]);

        this.setState({columns, data});
    }

    getActions = (tableConfig) => {
        return {
            type: 'actions',
            alias: localize(null, 'smartTable.fields.actions'),
            filterable: false,
            show: true,
            allowTableView: true,
            Header: () =>
                <div className="header-cell">
                    {tableConfig.actionsName ? tableConfig.actionsName : localize(null, 'smartTable.fields.actions')}
                </div>,
            Cell: ({original}) =>
                <div className="button-container">
                    {tableConfig.actions.map((a, index) => {
                        const actionButton = {
                            edit: (
                                <button
                                    key={index}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.handleEdit(original);
                                    }}
                                    // className="action-icon-pencil"
                                >
                                    <Icon className={"action-icon"} title={'עריכה'}>edit</Icon>
                                </button>
                            ),
                            view: (
                                <button
                                    key={index}
                                    onClick={() => this.handleView(original)}
                                    // className="action-icon-eye"
                                >
                                    <Icon className={"action-icon"} title={'צפייה'}>visibility</Icon>
                                </button>
                            ),

                            //custom delete actions, receives callback from props
                            delete: (
                                <button
                                    key={index}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.handleDelete(original)
                                    }}
                                    // className="action-icon-delete"
                                >
                                    <Icon className={"action-icon"} title={'מחיקה'}>delete</Icon>
                                </button>
                            )
                        }

                        switch (a) {
                            case 'edit':
                                return actionButton.edit;
                            case 'view':
                                return actionButton.view;
                            case 'delete':
                                return actionButton.delete; //custom delete actions, receives callback from props

                            default:
                                if (a.hidden && a.hidden(original)) {
                                    return null;
                                }

                                // extended edit | view | delete action
                                if (a.actionType) {
                                    return actionButton[a.actionType]
                                }

                                // custom action
                                return (
                                    a.element ? a.element({row: original, callback: a.callback}) :
                                        <div
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                a.callback(original);
                                            }}
                                            className={a.className}
                                        >
                                            {a.icon && <Icon className={"action-icon"} title={a.title}>{a.icon}</Icon>}
                                        </div>);
                        }
                    })}
                </div>
        }
    }


    toggleColumnSelector = (selected, removed) => {

        // get from state only selected columns
        let cols = this.state.columns.map(col => selected && selected.some(s => s.value === col.accessor) ? {
            ...col,
            show: true
        } : {...col, show: false});
        cols = cols.filter(col => col !== null);

        this.resetFilter(removed);
        this.setState({columns: cols});
    }

// selected is parameter from the filterSelector component
    toggleFilterSelector = (selected, isSelected) => {
        const newColumns = this.state.columns;

        // show the filter for selected column
        newColumns.forEach((c) => {
            if (selected && selected.accessor === c.accessor) {
                c.filterable = isSelected;
            }
        });

        // we create new array cause the table filtering does not change.
        const columns = [];
        for (const record of newColumns) {
            const newRecord = {...record};
            columns.push(newRecord);
        }

        // if we remove filter we want to remove that active filtering
        this.setState({columns});

        //reset filter
        if (!isSelected) {
            const removed = {
                value: selected.accessor
            }
            this.resetFilter(removed);
            this.setState();// hide the filter
        }
    }

//after deselecting column we must reset the values of the filter
    resetFilter = async (removed, currentFiltered) => {
        let {filters, search} = this.state;
        if (!removed) {
            return;
        }
        // id of the filtered array object & value of the removed parameter
        // is key/accessor
        const removedIndex = filters && filters.fields.findIndex(f => f.name === removed.value);
        if (removedIndex && removedIndex !== undefined && removedIndex !== -1) {
            filters.fields.splice(removedIndex, 1);

            // remove filter if we remove the column from column selector
            filters.fields.forEach((item, index) => {
                if (index === removedIndex) {
                    item.filtered = false
                }
            });
        }
        this.setState({filters});
        await this.fetchData(this.state.tableState, {filter: filters, search})
    }

/// on filter change event
    onFilteredChange = async (filtered, column, value) => {
        let {tableState, filters, search} = this.state;
        const {tableConfig} = this.props;
        let fields = [];
        for (let i = 0; i < filtered.length; i++) {
            let fieldType;
            let fieldAccessor;
            for (let j = 0; j < tableConfig.fields.length; j++) {
                if (tableConfig.fields[j].accessor === filtered[i].id) {
                    fieldType = tableConfig.fields[j].type;
                    fieldAccessor = tableConfig.fields[j].setAccessor;
                }
            }
            fields.push({name: filtered[i].id, type: fieldType, setName: fieldAccessor, searchText: filtered[i].value})
            if (fieldType === "dateTime") {
                let start = moment(filtered[0].value[0], 'ddd MMM DD YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm:ss")
                let end = moment(filtered[0].value[1], 'ddd MMM DD YYYY HH:mm:ss').format("YYYY-MM-DDTHH:mm:ss")
                fields[i]["start"] = start + "Z";
                fields[i]["end"] = end + "Z";
            }
        }

        filters = filtered.length > 0 ? {
            fields,
            // text: filtered[0] ? filtered[0].value : null,
            sort: `${tableState.sorted[0].id} ${tableState.sorted[0].desc === false ? 'desc' : 'asc'}`,
            AND: true,
        } : null;

        this.setState({filters});

        await this.fetchData(this.state.tableState, {filter: filters, search})
    }

// when manual is true this event is handled
    fetchData = (state, filters) => {
        // Whenever the table model changes, or the user sorts or changespages , this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({loading: true, tableState: state});
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        smartTableService
            .requestMoreData(state, this.props.data, this.props.tableConfig, filters)
            .then(res => {
                // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
                this.setState({
                    data: res.rows,
                    pages: res.pages,
                    loading: false
                });
            });
    }

    filterByTextSearch = async (text) => {
        let {tableState, filters} = this.state;
        let search = text.target.value;
        this.setState({search});
        let searchableFields = this.props.tableConfig.fields.filter(f => f.searchable).map(f => ({
            name: f.accessor,
            type: f.type,
            setName: f.setAccessor,
            searchText: search
        }));
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            name: text,
            typing: false,
            search: {
                fields: searchableFields, text: search,
                sort: `${tableState.sorted[0].id} ${tableState.sorted[0].desc === false ? 'desc' : 'asc'}`
            },
            // },
            typingTimeout: setTimeout(() => {
                this.fetchData(tableState, {
                    filter: filters,
                    search: {
                        fields: searchableFields,
                        text: search,
                        sort: `${tableState.sorted[0].id} ${tableState.sorted[0].desc === false ? 'desc' : 'asc'}`
                    }
                })
            }, 300)
        });

    }

    handleSort = async (column) => {
        const {filters, search, tableState} = this.state;
        if (filters && filters.search) {
            filters.search["sort"] = `${column[0].id} ${column[0].desc === false ? 'desc' : 'asc'}`
            if (column[0].desc === tableState.sorted[0].desc)
                return;
        }
        await this.setState({
            filters,
            search,
            tableState: {
                sorted: column,
                pageSize: this.props.defaultPageSize,
                pageNum: this.state.tableState.pageNum
            }
        });
        this.fetchData(this.state.tableState, {filter: filters, search})
    }

    handleView = entity => {
        this.props.viewEntity(entity);
    }

    handleDelete = entity => {
        this.props.handleDelete(entity);
    }

    handleEdit = entity => {
        this.props.editEntity(entity);
    }

    handleNew = entity => {
        if (this.props.tableConfig.allowCreation) {
            this.props.newEntity();
        }
    }

    exportTable = () => {
        if (this.props.allowExport) {
            this.props.exportTable(this.state.filters, this.props.tableConfig.excelFileName);
        }
    }

    render () {
        const {
            columns,
            pages,
            filters,
            search,
            data
        } = this.state;
        const {
            filterable,
            defaultPageSize,
            tableConfig,
            serverMode,
        } = this.props;
        const showTopBar = tableConfig.showTopBar == false ? false : true;
        const showPagination = tableConfig.showPagination == false ? false : true;
        const loading = serverMode ? this.state.loading : false;
        return (
            <div className="smart-table-wrapper">
                {showTopBar &&
                <div className="row">
                    {!this.props.disableColumnSelector && <div className="column-selector col-5">
                        <h5 className="title"><TextLocalize text={'smartTable.displayFields'}/></h5>
                        <ColumnSelector
                            columns={columns}
                            toggleColumnSelector={this.toggleColumnSelector}
                            tableConfig={tableConfig}
                        />
                    </div>}

                    {!this.props.disableFreeSearch && <div className="search-filters col-3">
                        <h5 className="title"><TextLocalize text={'smartTable.search'}/></h5>
                        <div className="input-wrapper">
                            <img src={search} className="search-filters-img"/>
                            <input
                                className="search-filters-input"
                                onChange={e => this.filterByTextSearch(e)}
                                name="searchFields"
                                type="text"
                            />
                        </div>
                    </div>}
                    <div className="add-container col-4">
                        {this.props.allowAdd && !this.props.reverseAdd && <div>
                            <h5 className="title"/>
                            <div className="button-container" onClick={this.handleNew}>
                                <PlusIcon/>
                                <div className="label"><TextLocalize text={'smartTable.addButton'}/></div>
                            </div>
                        </div>}
                        {this.props.allowExport && <div>
                            <h5 className="title"/>
                            <div className="button-container" onClick={this.exportTable}>
                                <ExportIcon/>
                                <div className="label"><TextLocalize text={'smartTable.exportButton'}/></div>
                            </div>
                        </div>}
                        {this.props.actions && this.props.actions.map((action, index) => {
                            return (<Action
                                    key={index}
                                    icon={action.icon}
                                    label={action.label}
                                    onClick={action.onClick}
                                />
                            )
                        })}
                    </div>
                </div>
                }
                <ReactTable
                    ref={ref => this.table = ref}
                    column={{...ReactTableDefaults.column, /*minWidth: 200,*/}}
                    data={data}
                    pages={pages} // Display the total number of pages
                    loading={null} // Display the loading overlay when we need it
                    defaultPageSize={defaultPageSize}
                    defaultSortDesc={true}
                    filterable={filterable}
                    columns={columns}
                    className="-highlight"
                    showPagination={showPagination}
                    manual={true} // informs React Table that you'll be handling sorting and pagination server-side
                    // filtered={filtered}
                    onFilteredChange={this.onFilteredChange}
                    nextText=">"
                    previousText="<"
                    onPageChange={async (pageNum) => {
                        await this.setState({
                            filters,
                            search,
                            tableState: {
                                page: pageNum,
                                pageSize: this.props.defaultPageSize,
                                sorted: this.state.tableState.sorted
                            }
                        });
                        this.fetchData(this.state.tableState, {filter: filters, search})
                    }}
                    onSortedChange={() => {
                    }}
                    pageText=<TextLocalize text={'smartTable.pagination.page'}/>
                noDataText= {localize(null, 'smartTable.noData')}
                ofText=<TextLocalize text={'smartTable.pagination.of'}/>
                getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                    return {
                        onClick: (e) => {
                            this.handleView(rowInfo.original);
                            if (this.props.enableSelectedRowHighlight)
                                this.setState({selected: rowInfo.index});
                        },
                        style: {
                            background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                            color: rowInfo.index === this.state.selected ? 'white' : 'black',
                            fontWeight: rowInfo.index === this.state.selected ? 'bold' : 'normal'
                        }
                    }
                } else {
                    return {}
                }
            }
            }
                />
                {this.props.allowAdd && this.props.reverseAdd ? <div className="add-container col-2">
                    <h5 className="title"></h5>
                    <div className="button-container" onClick={this.handleNew}>
                        <PlusIcon/>
                        <div className="label"><TextLocalize text={'smartTable.addButton'}/></div>
                    </div>
                </div> : null}
            </div>
        )
    }
}
