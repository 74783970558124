import React, { Component } from "react";

export default class NumberRange extends Component {
    constructor() {
        super();
        this.state = {
            from: '',
            to: '',
        };
        this.onfromChange = this.onfromChange.bind(this);
        this.ontoChange = this.ontoChange.bind(this);
    }
    // set current value of the input and pass to the prop event 
    onfromChange = (event) => {
        const from = Number(event.target.value);
        this.setState({ from });
        this.updateComponentFromPropEvent(from);
    }
    // set current value of the input and pass to the prop event 
    ontoChange = (event) => {
        const to = Number(event.target.value);
        this.setState({ to });
        this.updateComponentToPropEvent(to);
    }
    // update prop event with the state values
    updateComponentFromPropEvent = (from) => {
        this.props.onChange(
            {
                from,
                to: this.state.to,
            }
        )
    }
    // update prop event with the state values
    updateComponentToPropEvent = (to) => {
        this.props.onChange(
            {
                from: this.state.from,
                to,
            }
        )
    }
    render() {
        return (
            <div className='number-filter'>
                <div className='number-from'>
                    <span className='number-label'>
                        From
                    </span>
                    <input
                        type='number'
                        value={this.state.from}
                        onChange={this.onfromChange}
                        className='number-input'
                    />
                </div>
                <div className='number-to'>
                    <span className='number-label'>
                        To
                     </span>
                    <input
                        type='number'
                        value={this.state.to}
                        onChange={this.ontoChange}
                        className='number-input'
                    />
                </div>

            </div>
        )
    }
}