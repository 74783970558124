import React from 'react';
import TextLocalize from "../../TextLocalize/TextLocalize";
import Icon from '@material-ui/core/Icon';

const Action = ({title, icon, label, onClick, ...props}) => {
    return (
        <div className="">
            <h5 className="title">
                <TextLocalize text={title ? `smartTable.${title}` : ''}/>
            </h5>
            <div
                className="button-container"
                onClick={() => onClick()}>
                <Icon>
                    {icon}
                </Icon>
                <div className="label">
                    <TextLocalize text={`smartTable.${label}`}/>
                </div>
            </div>
        </div>
    )
};
export default Action;
