import React from 'react';
import {connect} from 'react-redux';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import engLogo from '../../shared/img/logo/logo.svg'
import logo from '../../shared/img/logo/logo.svg'
import styles from './updatePassword.scss'
import {withRouter} from "react-router-dom";

import {updatePassword} from '../../redux/actions/authActions'
import {showMessage, showError} from '../../redux/actions/messageActions'

import Permissions from '../../services/permissions'
import Translation from '../../services/translation/translation'

class UpdatePassword extends React.Component {
    constructor (props) {
        super(props);
    }

    handleUpdatePasswordSubmit = async ({oldPassword, newPassword, newPassword2}) => {
        if (newPassword !== newPassword2) {
            this.props.showError('שינוי סיסמא', 'הסיסמאות שהזנת אינן תואמות')
        }
        let result = await this.props.updatePassword(oldPassword, newPassword);

        if (result && result.success) {
            this.props.showError('שינוי סיסמא', 'הסיסמא עודכנה בהצלחה');
            this.props.history.push('/login');
        } else {
            this.props.showError('כניסה למערכת', 'אין לך הרשאות בכדי להיכנס למערכת')
        }
    };

    render () {
        return (
            <div className="account">
                <div className="account__wrapper">
                    <div className="account__card">
                        <div className="account__head">
                            <img src={Translation.getCurrentLanguage().name === "en" ? engLogo : logo} alt="logo"/>
                        </div>
                        <UpdatePasswordForm onSubmit={this.handleUpdatePasswordSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
});

const mapDispatchToProps = {
    updatePassword,
    showMessage,
    showError
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdatePassword));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
