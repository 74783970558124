import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { setTitle, setLanguage } from '../../redux/actions/appActions';
import { logoutUser } from '../../redux/actions/authActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
  };

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };

  setTitle = (title) => {
    const { dispatch } = this.props;
    dispatch(setTitle(title));
  };

  setLanguage = (language) => {
    const { dispatch } = this.props;
    dispatch(setLanguage(language));
  };

  render() {
    const { title, user, sidebar, language } = this.props;

    if (!language) return null;

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
    });

    return (
      <div className={layoutClass}>
        <Topbar
          title={title}
          language={language}
          setLanguage={this.setLanguage}
          user={user}
          logout={this.logout}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        {user && <Sidebar
          sidebar={sidebar}
          language={language}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          setTitle={this.setTitle}
        />}
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
  user: state.auth.user,
  title: state.app.title,
  language: state.app.language,
}))(Layout));
