const config = require(`./config.${process.env.TECHNOTEST_ENV}.json`);
console.log(process.env);
export default {
  server: {
    url: window.location.protocol === 'https:' ? `https://${config.serverUrl}` : `http://${config.serverUrl}`
  },
  website: {
    url: window.location.protocol === 'https:' ? `https://${config.websiteUrl}` : `http://${config.websiteUrl}`
  },
  club: {
    url: window.location.protocol === 'https:' ? `https://${config.clubUrl}` : `http://${config.clubUrl}`
  },
  isPilot: process.env.IS_PILOT === true
};
