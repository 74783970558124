import React from 'react';
import { Card, Col, Container, Modal, Row } from 'reactstrap';
import { connect } from 'react-redux';
import pick from "lodash/pick";
import SmartForm from '../../../../shared/components/SmartWrapper/src/components/smartForm/SmartForm'
import SmartFormMode from '../../../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode'
import http from "../../../../services/http";
import util from "../../../../services/util";
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon'
import { setTitle } from "../../../../redux/actions/appActions";
import TextLocalize from "../../../../shared/components/TextLocalize/TextLocalize";

class MessageTemplateDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            smartFormMode: null,
        }
    }

    handleFormCancel = () => {
        this.setState({ selectedRowData: null, smartFormMode: null })
    };

    handleSave = async (doc) => {
        const { currentUser, tableConfig } = this.props;
        try {
            await http.put(`${tableConfig.endpoints['save'] || tableConfig.model}/${currentUser._id}`, doc);

            await this.props.getUser();
            this.setState({ smartFormMode: null });
        }
        catch (err) {
            console.log('server error');
            throw new Error(`Error while saving info to server. err=${err}`);
        }
    };

    render() {
        const { smartFormMode } = this.state;
        const { currentUser, tableConfig } = this.props;
        if (!currentUser) return null;

        let initialValues = currentUser;

        // initialize form only with allowed fields to edit
        if (smartFormMode === SmartFormMode.edit && initialValues) {
            const allowedFields = Object
                .values(tableConfig.fields)
                .filter(f => f.allowEdit)
                .map(f => f.accessor);

            initialValues = pick(initialValues, allowedFields);
        }

        return (
            <Container className="user-details-container">
                <div className="user-details">
                    <div className="pd-head">
                        <span>
                            <TextLocalize text={'user.tabs.provider_details'} />
                        </span>
                        <button onClick={() => this.setState({ smartFormMode: SmartFormMode.edit })}>
                            <PencilOutlineIcon />
                            <span><TextLocalize text={'smartTable.edit'} /></span>
                        </button>
                    </div>
                    <div className="pd-body">
                        {tableConfig.fields.filter(field => !field.displayModes || field.displayModes.includes(SmartFormMode.view)).map((field, index) => {
                            return (
                                <div key={index} className="pd-field">
                                    <div className="pd-field-name"><TextLocalize text={`smartTable.fields.${field.alias}`} />:</div>
                                    <div className="pd-field-value">{util.getFieldValueByType(field, util.getFieldByPath(currentUser, field.accessor))}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Modal contentClassName="sf-modal" isOpen={!!smartFormMode}>
                    {
                        tableConfig &&
                        <SmartForm initialValues={initialValues}
                            mode={smartFormMode}
                            fields={tableConfig.fields}
                            title={tableConfig.modelDisplayName}
                            onCancel={this.handleFormCancel}
                            onSubmit={this.handleSave} />
                    }
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        tableConfig: state.app.tableConfig
    }
};

const mapDispatchToProps = { setTitle };

export default connect(mapStateToProps, mapDispatchToProps)(MessageTemplateDetails);
