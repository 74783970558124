import config from '../config';
import http from '../helpers/http';

class ProviderAPI {
    constructor() {
        this.url = `${config.server.url}`;
        this.get = async (path) => {
            let response = await http.connect(`${this.url}/${path}`);
            return response && response.json ? await response.json() : response
        };
        this.post = async (path, data) => {
            let response = await http.connect(`${this.url}/${path}`, {
                type: 'POST',
                body: data,
            });
            return response && response.json ? await response.json() : response
        };
        this.patch = async (path, data) => {
            let response = await http.connect(`${this.url}/${path}`, {
                type: 'PATCH',
                body: data,
                headersToAdd: [
                    ['Prefer', 'return=representation'],
                ],
            });

            return response && response.json ? await response.json() : response
        };
        this.delete = async (path) => {
            let response = await http.connect(`${this.url}/${path}`, {
                type: 'DELETE',
            }, true);

            if (response.status === 204) return null;

            return response && response.json ? await response.json() : response
        }
    }

    patchProvider = async (id, data) => {
        return await this.patch(`ServiceProviders(${id})`, data);
    };

    getProviderByPhone = async (phone) => {
        // let results = await this.get(`api/provider/login?phone=${phone}`);
        let results = await this.get(`ServiceProviders?$filter=id eq ${1114}`);
        if (results && results.value) {
            // if (results && results.id) {
            return results;
        }
        return null;
    }

    getProviderByID = async (id) => {
        let results = await this.get(`ServiceProviders?$filter=id eq ${id}`);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    };


    getProviderServiceCalls = async (pID) => {
        let results = await this.get(`ServiceCalls?$filter=ServiceProviderID eq ${pID}&$orderby=id desc`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    }

    getProviderSubjects = async (pID) => {
        let results = await this.get(`ServiceProviderSubjects?$filter=ProviderID eq ${pID} and IsActive eq true&$orderby=id desc`);
        if (results && results.value) {
            return results.value.map(s => ({...s, id: s.ID}));
        }
        return null;
    }

    postProviderSubjects = async (data) => {
        let results = await this.post(`ServiceProviderSubjects`, data);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    };

    patchProviderSubjects = async (id, data) => {
        return await this.patch(`ServiceProviderSubjects(${id})`, data);
    };


    getProviderRadiuses = async (pID) => {
        let results = await this.get(`ServiceProviderLocationCoverage?$filter=ServiceProviderID eq ${pID}`);

        if (results && results.value) {
            return results.value;
        }
        return null;
    };

    getSchedule = async (pID) => {
        let results = await this.get(`ServiceProviderWorkSchedule?$filter=ServiceProviderID eq ${pID}`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    };

    postSchedule = async (data) => {
        let results = await this.post(`ServiceProviderWorkSchedule`, data);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    };

    deleteSchedule = async (id) => {
        return await this.delete(`ServiceProviderWorkSchedule(${id})`);
    };

    patchSchedule = async (id, data) => {
        return await this.patch(`ServiceProviderWorkSchedule(${id})`, data);
    };


    getProviderExtended = async (pID) => {
        const provider = await this.getProviderByID(pID);
        const subjects = await this.getProviderSubjects(pID);
        const locations = await this.getProviderRadiuses(pID);
        console.log({provider, subjects, locations});
        provider.subjects = subjects;
        provider.locations = locations;

        return provider;
    }

    saveProviderLocations = async ({locations, provider}) => {
        const locationRows = [];
        const subjects = provider.subjects ? provider.subjects : await this.getProviderSubjects(provider.id);

        for (let i = 0; i < locations.length; i++) {
            for (let j = 0; j < subjects.length; j++) {
                locationRows.push({...locations[i], ServiceSubjectID: subjects[j].id})
            }
        }

        console.log('locationRows', locationRows);

        this.post(`ServiceProviderLocationCoverage`, locationRows)
    }

    postLocationCoverage = async (data) => {
        let results = await this.post(`ServiceProviderLocationCoverage`, data);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    }

    patchLocationCoverage = async (id, data) => {
        return await this.patch(`ServiceProviderLocationCoverage(${id})`, data);
    };

    deleteLocationCoverage = async (id) => {
        return await this.delete(`ServiceProviderLocationCoverage(${id})`);
    };

    getTelephones = async (pID) => {
        let results = await this.get(`ServiceProviderTelephones?$filter=ServiceProviderID eq ${pID}`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    };

    postTelephone = async (data) => {
        let results = await this.post(`ServiceProviderTelephones`, data);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    }

    patchTelephone = async (id, data) => {
        return await this.patch(`ServiceProviderTelephones(${id})`, data);
    };

    deleteTelephone = async (id) => {
        return await this.delete(`ServiceProviderTelephones(${id})`);
    };


    getPhonePrefixes = async () => {
        let results = await this.get(`PhonePrefix`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    };

    postProviderUnavailability=async(data)=>{
        let results = await this.post(`ServiceProviderAvailability`, data);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    }


    getServiceProviderAvailability = async (pID) => {
        let results = await this.get(`ServiceProviderAvailability?$filter=ServiceProviderID eq ${pID} &$orderby=NotAvailableFrom asc`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    };

    patchServiceProviderAvailability = async (id, data) => {
        return await this.patch(`ServiceProviderAvailability(${id})`, data);
    };

    getCallcenterphonelog = async (pID) => {
        return await this.get(`Callcenterphonelog(${pID})`);
    };

    patchCallcenterphonelog = async (id, data) => {
        return await this.patch(`Callcenterphonelog(${id})`, data);
    };

    getPendingCallCenterTasks = async (pID) => {
        let results = await this.get(`CallCenterTasks?$filter=ProviderId eq ${pID} and Status eq 1 &$orderby=Id desc`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    };

    executeTask = async (userId) => {
        await this.post('api/callcenter/executeTask', {userId});
    }



    patchCallCenterTasks = async (id, data) => {
        return await this.patch(`CallCenterTasks(${id})`, data);
    };

    getUsersAndTaskTypes = async (userId) => {
        let results = await this.get(`UsersAndTaskTypes?$filter=UserId eq ${userId}`);
        if (results && results.value) {
            return results.value;
        }
        return null;
    }

    postUsersAndTaskTypes = async (data) => {
        let results = await this.post(`UsersAndTaskTypes`, data);
        if (results && results.value) {
            return results.value[0];
        }
        return null;
    }

    deleteUsersAndTaskTypes = async (id) => {
        return await this.delete(`UsersAndTaskTypes(${id})`);
    };

    getReminder = async (reminderid) => {
        return await this.get(`Reminders(${reminderid})`);
    }

}

export default new ProviderAPI()
