import {
    SET_ERROR,
    SET_MESSAGE,
    CLEAR_MESSAGE
} from '../actions/messageActions';

const initialState = {show: false, message: null};


export default function (state = initialState, {type, message}) {
    const msg = typeof message == 'object' ? message : {content: message};
    switch (type) {
        case SET_ERROR:
            return {
                show: true,
                type: 'שגיאה',
                ...msg
            };
        case SET_MESSAGE:
            return {
                show: true,
                type: 'הודעה',
                ...msg
            };
        case CLEAR_MESSAGE:
            return {
                show: false,
            };
        default :
            return state;
    }
}
