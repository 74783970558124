import React, {Component} from 'react';

// import third party https://github.com/JedWatson/react-select
import Select from 'react-select';
import Localize from '../../translation/localization'

export default class ColumnSelector extends Component {

    state = {
        selected: [],
        options: []
    }


    removedItem = (selected, value) => {
        if (selected) {
            // calculates diff
            const removedArray = value ? selected.filter(x => !value.includes(x)) : selected;
            return removedArray[0]
        } else {
            return null;
        }
    }

    getOptions = () => {
        // bind selected columns from props value
        const {columns} = this.props;
        // all options  that can be selected - all columns
        let options = [].concat(
            columns.map(column => {
                return {
                    ///fileds that are supported by the third party component
                    label: Localize(null, `smartTable.fields.${column.alias}`),
                    value: column.accessor,
                    show: column.show,
                }
            }).sort((c1, c2) => {
                const c1Name = c1.label.toUpperCase(),
                    c2Name = c2.label.toUpperCase();
                return (c1Name < c2Name) ? -1 : (c1Name < c2Name) ? 1 : 0;
            })
        );

        // selected columns
        const selected = [].concat(
            columns
                .map(column => {
                    return {
                        ///fileds that are supported by the third party component
                        label: Localize(null, `smartTable.fields.${column.alias}`),
                        value: column.accessor,
                        show: column.show,
                    }
                })
                .filter(col => col.show)
        );

        if (!options) {
            return;
        }
        this.setState({selected});
        this.setState({options});
    }


    componentWillMount = () => {
        this.getOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.columns != this.props.columns) {
            this.getOptions();
        }
    }

    onSelectedChanged = value => {
        const removed = this.removedItem(this.state.selected, value);
        this.setState({selected: value});
        this.props.toggleColumnSelector(value, removed);
    }

    render() {
        const {selected, options} = this.state;
        return (
            <div>
                <Select
                    placeholder={'Select column'}
                    isMulti
                    value={selected}
                    onChange={this.onSelectedChanged}
                    options={options}
                />
            </div>
        )
    }
}