import action from "../middleware.js";

export const SET_MESSAGE = "SET_MESSAGE";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_MESSAGE = "CLEAR_ERROR";

export function clearMessage() {
  return action(async dispatch => {
    dispatch({ type: CLEAR_MESSAGE });
  });
}

export function showMessage(...args) {
  return action(async dispatch => {
    const message = {
      title: args.length == 2 ? args[0] : null,
      content: args.length == 2 ? args[1] : args[0]
    };
    dispatch({
      type: SET_MESSAGE,
      message
    });
  });
}

export function showError(...args) {
  return action(async dispatch => {
    const message = {
      title: args.length == 2 ? args[0] : null,
      content: args.length == 2 ? args[1] : args[0]
    };
    dispatch({
      type: SET_ERROR,
      message
    });
  });
}
