import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { SmartWrapper } from '../../../shared/components/SmartWrapper/src/index';
import { withRouter } from "react-router-dom";
import '../../../shared/components/SmartWrapper/src/styles/smartTable.scss';
import { localize } from "../../../services/translation/localization"

// actions
import { setTitle } from "../../../redux/actions/appActions";
import { compose } from "redux";
import http from '../../../services/http';

class MessagesTemplatesList extends React.Component {
    constructor(props) {
        super(props);
        http.setUrl('https://api.myspetz.com');
    }

    componentDidMount() {
        this.props.setTitle('pages.titles.message_templates_management');
    }

    handleViewUser = (selectedRowData) => {
        //this.props.history.push(`/message_templates_management/${selectedRowData.id}/details`);
    };

    render() {
        const { tableConfig } = this.props;
        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc">
                            {localize(null, "pages.titles.message_templates_management")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {
                        tableConfig
                        && <SmartWrapper
                            viewButtonCallback={this.handleViewUser}
                            tableConfig={tableConfig}
                            allowAdd={true}
                        />
                    }
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        tableConfig: state.app.tableConfig
    }
};

const mapDispatchToProps = { setTitle };

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(MessagesTemplatesList)
