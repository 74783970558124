import json from './languages.json';

class Translation {
  constructor() {
    this.language = {
      "name": "he",
      "displayName": "עברית",
      "dir": "rtl"
    }
  }

  supportedLanguages() {
    return json.languages;
  }

  getLanguage(name) {
    return json.languages.find(lang => lang.name === name);
  }

  getCurrentLanguage() {
    return this.language
  }

  setLanguage(name) {
    this.language = json.languages.find(lang => lang.name === name);
    document.body.setAttribute('dir', this.language.dir)
  }

}
export default new Translation();
