import React, {PureComponent} from "react";
import Select from "react-select";
import PropTypes from "prop-types";

class SelectField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                label: PropTypes.string
            })
        ),
        value: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number,
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                label: PropTypes.string
            })
        ]).isRequired
    };

    static defaultProps = {
        placeholder: "",
        options: []
    };

    handleChange = selectedOption => {
        const {onChange} = this.props;
        onChange(selectedOption.value);
    };

    render() {
        let {
            value,
            name,
            placeholder,
            options,
            styles,
            components,
            disabled
        } = this.props;
        value = typeof v === "object" ? value : options.find(option => option.value === value);
        return (
            <Select
                name={name}
                value={value}
                styles={{...styles}}
                onChange={this.handleChange}
                components={components}
                options={options}
                isDisabled={disabled}
                clearable={false}
                className="react-select"
                placeholder={placeholder}
                classNamePrefix="react-select"
            />
        );
    }
}

const renderSelectField = props => {
    const {input, meta, options, placeholder, styles, components, disabled} = props;
    return (
        <div className="form__form-group-input-wrap">
            <SelectField
                {...input}
                disabled={disabled}
                components={components}
                options={options}
                styles={styles}
                placeholder={placeholder}
            />

            {
                meta.touched && meta.error && (
                    <span className="form__form-group-error">{meta.error}</span>
                )
            }
        </div>
    );
};

renderSelectField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string
    }),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string
        })
    ),
    placeholder: PropTypes.string
};

renderSelectField.defaultProps = {
    meta: null,
    options: [],
    placeholder: ""
};

export default renderSelectField;
