import {
    SET_ERROR,
    CLEAR_ERROR
} from './actions/messageActions';

export default function action(_action) {
    return async dispatch => {
        try {
            return await _action(dispatch);
        }
        catch (ex) {
            console.log(`error! error = ${ex}`);
            dispatch({type: SET_ERROR, payload: ex});
            return {error: ex.toString()};
        }
    }
}
