import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import Translations from '../../../services/translation/translation'

export default class TopbarLanguageSwitch extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  changeLanguage = (lang) => {
    this.toggle();
    this.props.setLanguage(lang);
    localStorage.setItem("language", lang.name)
  };

  render() {
    const { collapse } = this.state;
    const { language } = this.props;

    if (!language) return null;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          {/*<img className="topbar__avatar-img" src={Ava} alt="avatar" />*/}
          <p className="topbar__collapse-name">{language.displayName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap" style={{left: language.name === 'he' ? 'initial' : 0, right: language.name === 'he' ? 0 : 'initial'}}>
          <div className="topbar__menu">
            {Translations.supportedLanguages().map((language, index) => {
              return (
                  <div key={index} className="menu-button" onClick={() => this.changeLanguage(language)}>{language.displayName}</div>
              )
            })}
          </div>
        </Collapse>
      </div>
    );
  }
}
