import React, {PureComponent} from "react";
import {Col, Button, ButtonToolbar} from "reactstrap";
import {Field, FieldArray, FormSection, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import validate from "./validate";
import componentTypeMap from "./componentTypeMap";
import SmartFormMode from "./SmartFormMode";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import moment from "moment";
import {localize} from "../../../../../../services/translation/localization"
import connect from "react-redux/es/connect/connect";
import Enums from "../../../../../../services/enums";
import Util from "../../../../../../services/util";

class SmartForm extends PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired
    };

    constructor() {
        super();
        this.state = {
            showPassword: false
        };
    }

    showPassword = e => {
        e.preventDefault();
        this.setState(prevState => ({showPassword: !prevState.showPassword}));
    };

    render() {
        let {
            handleSubmit,
            onCancel,
            touch,
            mode,
            fields,
            title,
            initialValues,
            user,
            showButtons
        } = this.props;

        let filter = field => field.displayModes ?
            field.displayModes.includes(mode) : true;

        fields = fields.filter(filter);

        return (
            <Col className={"smart-form"}>
                <div className="card__title">
                    <h4 className="bold-text">{`${title}`}</h4>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="form-input-fields">
                        {fields.map((field, i) => {
                            const component = componentTypeMap[field.type];

                            let viewModeValue =
                                initialValues && Util.getFieldByPath(initialValues, field.accessor);

                            let value = field.value
                                ? (typeof field.value == 'function' ? field.value(initialValues, mode, user) : field.value)
                                : viewModeValue;

                            let disabled = field.disabled
                                ? (typeof field.disabled == 'function' ? field.disabled(initialValues, mode, user) : field.disabled)
                                : false;

                            if (field.type === "select") {
                                viewModeValue = (
                                    field.options && field.options.find(
                                        option => option.value === viewModeValue
                                    ) || {}
                                ).label;
                            }
                            if (field.type === "enum") {
                                const enums = Enums.getEnum(field.optionsKey);
                                for (const item of enums) {
                                    if (item.id == value) {
                                        viewModeValue = item.displayName;
                                    }
                                }
                            }
                            if (field.type === "multiSelect") {
                                viewModeValue = viewModeValue
                                    ? viewModeValue.map(
                                        v =>
                                            (field.options && field.options.find(option => option.value === v) || {})
                                                .label
                                    )
                                    : [];
                                viewModeValue = viewModeValue.join(", ");
                            }

                            if (field.type === "date") {
                                if (viewModeValue )
                                    viewModeValue = moment(viewModeValue).format("DD/MM/YYYY");
                                else
                                    viewModeValue = moment().format("DD/MM/YYYY");
                            }

                            if (field.type === "dateTime") {
                                if (viewModeValue)
                                    viewModeValue = moment(viewModeValue).format("DD/MM/YYYY HH:mm");
                                else
                                    viewModeValue = moment().format("DD/MM/YYYY HH:mm");
                            }

                            if (field.type === "boolean" && mode === SmartFormMode.view) {
                                viewModeValue = localize(null, viewModeValue === true ? 'smartForm.trueValue' : 'smartForm.falseValue');
                            }

                            if (field.type === "text" && field.isImage && viewModeValue) {
                                viewModeValue = <img src={viewModeValue} alt={initialValues.name} />
                            }

                            return (

                                <div key={i}
                                     className={field.type === 'multiSelect' ? "form__form-group multiselect" : `form__form-group ${field.fieldStyles ? field.fieldStyles.join(" ") : "" }`}>
                                    <span
                                        className="form__form-group-label">{localize(null, `smartTable.fields.${field.alias}`)}</span>
                                    <div className="form__form-group-field">
                                        {
                                            mode === SmartFormMode.view
                                                ? (<div>{viewModeValue}</div>)
                                                : field.formFieldType === 'formSection' ?
                                                <FormSection name={field.accessor}>
                                                    {React.createElement(component, {...field, initialValues: initialValues || {}})}
                                                </FormSection>
                                                : field.formFieldType === 'fieldArray' ?
                                                    <FieldArray
                                                        name={field.accessor}
                                                        component={component}
                                                        props={{...field, value, initialValues: initialValues || {}}}
                                                    />
                                                : (<>
                                                    <Field
                                                        {...field}
                                                        name={field.accessor}
                                                        fieldStyles={field.fieldStyles}
                                                        props={{value}}
                                                        touch={touch}
                                                        parameterOptions={field.parameterOptions}
                                                        component={component}
                                                        disabled={disabled}
                                                        options={field.options}
                                                        type={field.type}
                                                        validate={field.validators ? field.validators : []}
                                                        placeholder={localize(null, `smartTable.fields.${field.alias}`)}
                                                    />
                                                    {
                                                        field.type === "date" &&
                                                        (
                                                            <div className="form__form-group-icon">
                                                                <CalendarBlankIcon/>
                                                            </div>
                                                        )
                                                    }
                                                </>)
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <ButtonToolbar className="sf-buttons">
                        {showButtons !== false && (
                            <button type="button" onClick={onCancel}>{localize(null, 'smartForm.cancel')}</button>
                        )}
                        {mode !== SmartFormMode.view && (
                            <button type="submit">{localize(null, 'smartForm.save')}</button>
                        )}
                    </ButtonToolbar>
                </form>
            </Col>
        );
    }
}

const smartForm = reduxForm({
    form: "smartForm",
    destroyOnUnmount: true,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true
    // validate,
})(SmartForm);
const mapStateToProps = (state, props) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(smartForm);

