import React, { Suspense } from 'react';
import TextLocalize from "../../TextLocalize/TextLocalize";

import filterSelected from '../../../assets/filter-selected.png';

// Component in the header that will handle which filter should be shown
class HeaderComponent {

    get(field, onClick, handleSort, showFilter = true) {
        let isSelected = false;
        let desc=false;
        return {
            Header: () => (
                <div className="header-cell" onClick={()=>{
                    desc=!desc
                    handleSort([{id: field.accessor, desc: desc}])
                }}>
                    <span title={`${field.alias}`}><TextLocalize text={`smartTable.fields.${field.alias}`}/></span>
                    {
                        showFilter ?
                            <img
                                className='filter-img'
                                src={filterSelected}
                                onClick={(event) => {
                                    isSelected = !isSelected;
                                    onClick(field, isSelected);
                                    event.stopPropagation();
                                }}
                            />
                            : null
                    }
                </div>
            ),
        }
    }
}

export default new HeaderComponent();
