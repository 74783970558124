import React from 'react';
import { connect } from 'react-redux';
import ResetPasswordForm from './components/ResetPasswordForm';
import engLogo from '../../shared/img/logo/logo.svg'
import logo from '../../shared/img/logo/logo.svg'
import styles from './resetPassword.scss'
import {withRouter} from "react-router-dom";

import {resetPassword} from '../../redux/actions/authActions'
import {showMessage, showError} from '../../redux/actions/messageActions'

import Permissions from '../../services/permissions'
import Translation from '../../services/translation/translation'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
  }

  handleResetPasswordSubmit = async ({email}) => {
    await this.props.resetPassword(email);
    //
    // if (user) {
    //   if (user.role !== 'CLIENT') {
    //     // await Permissions.init(user.id, user.Role);
    //     this.props.history.push('/login');
    //   } else {
    //     this.props.showError('כניסה למערכת', 'אין לך הרשאות בכדי להיכנס למערכת')
    //   }
    // }
  };

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <img src={Translation.getCurrentLanguage().name === "en" ? engLogo : logo} alt="logo"/>
            </div>
            <ResetPasswordForm onSubmit={this.handleResetPasswordSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
});

const mapDispatchToProps = {
  resetPassword,
  showMessage,
  showError
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
