import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CloseIcon from "mdi-react/CloseIcon";


const MultiDatesPicker = (props) => {
    const { input: { onChange }, value, ...rest } = props;
    const [dates, setDates] = useState(Array.isArray(value) ?
        value.map(d => new Date(d)) : []);
    const addDate = (date) => {
        if (date) {
            let updatedDates = [];
            const index = dates.map(d => d.getTime()).indexOf(date.getTime());
            if (index === -1) {
                updatedDates = [...dates, moment(date).toDate()];
            } else {
                updatedDates = [...dates];
                updatedDates.splice(index, 1);
            }
            setDates(updatedDates);
            onChange(updatedDates.map(d => moment(d).format('YYYY-MM-DD')));
        }
    }

    const removeDate = (event, date) => {
        const updatedDates = dates.filter(d => d !== date);
        setDates(updatedDates);
        onChange(updatedDates.map(d => moment(d).format('YYYY-MM-DD')));
        event.stopPropagation();
    }

    const CustomInput = ({ value, onClick }) => (
        <div className="multi-dates-wrapper" onClick={onClick}>
            {dates.length > 0 ?
                dates.map(date => (
                    <span className="date">
                        <span className="label">{moment(date).format('DD/MM/YYYY')}</span>
                        <CloseIcon className="delete-icon" size={16} color={"red"} onClick={(event) => removeDate(event, date)} />
                    </span>
                )) :
                <span className="placeholder">{rest.placeholder}</span>}
        </div>
    );
    return (
        <div className="date-picker">
            <DatePicker
                selected={dates.length > 0 ? dates[dates.length - 1] :  null}
                highlightDates={dates}
                onChange={date => addDate(date)}
                customInput={<CustomInput />}
                shouldCloseOnSelect={false}
            />
        </div>
    );
};

export default MultiDatesPicker;
