import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import { localize } from "../../../services/translation/localization"

//Components
import MessageTemplateDetails from './MessageTemplateDetails';

import { Container } from "reactstrap";

//Services
import http from "../../../services/http";

//OperatorActions
import { setCurrentUser } from '../../../redux/actions/userActions'
import { setTitle } from '../../../redux/actions/appActions'

class MessageTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.getUser();
    }

    getUser = async () => {
        const id = this.props.match.params.id;
        if (id) {
            try {
                let user = await http.get(`GetServiceProviders(${id})`);
                console.log({ user });
                this.props.setTitle(localize(null, "pages.titles.providerTitle"));
                this.props.setCurrentUser(user);
            } catch (e) {

            }
        }
    }

    render() {
        const messageTemplateId = this.props.match.params.id;
        console.log({ messageTemplateId });
        const { currentUser } = this.props;

        return (
            <Container className="user-container">
                <div className="user-tab-menu">
                    <NavLink
                        activeClassName="tab-active"
                        to={`/message_templates_management/${messageTemplateId}/details`}>
                        {localize(null, 'user.tabs.provider_details')}
                    </NavLink>
                </div>
                {
                    currentUser
                    && <div className="tab-content">
                        <Switch>
                            <Route
                                exact
                                path="/message_templates_management/:id/details"
                                component={() => <MessageTemplateDetails />}
                            />
                        </Switch>
                    </div>}
            </Container>

        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    language: state.app.language,
    currentUser: state.user.currentUser,
});

const mapDispatchToProps = {
    setCurrentUser,
    setTitle,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(MessageTemplate)
