import Translations from './translation'

const localization = {
    he: {
        pages: {
            titles: {
                customers: 'לקוחות',
                service_calls: 'פניות שירות',
                service_call: 'פניית שירות',
                service_calls_secondary: 'ניהול ועריכה של פניות שירות, יצירת פניות חדשות',
                callTitle: 'פרטי פניית שירות',
                providerTitle: 'פרטי נותן שירות',
                service_providers: 'נותני שירות',
                service_provider: 'נותן שירות',
                service_providers_secondary: 'ניהול, עריכה ויצירה של נותני שירות',
                message_templates_management: 'ניהול תבניות',
                documents_list: 'רשימת מסמכים',
                document: "מסמך",
                provider_phones: "מספרי טלפון",
                transaction: "פעולה",
                paymentMethod: "אמצעי תשלום",
                paymentMethods: "אמצעי תשלום",
                billing: "עדכוני יתרה",
                users_management: "ניהול משתמשים",
                branches_management: "ניהול סניפים",
                branches_stats: "נתוני רישום לפי סניף",

                branch_management: "ניהול סניף",
                stands_management: "ניהול עמדות",
                services_management: "ניהול שירותים",
                companies_management: "ניהול חברות",
                workers_committees_management: "ניהול לקוחות וועדי עובדים",
                coupons_management: "ניהול קופונים",
                coupons_packages_management: "ניהול חבילות קופנים",
                clients_management: "ניהול לקוחות",
                appointments_management: "ניהול תורים",
                users_management_secondary: "ניהול ועריכה של משתמשים פעילים",
                branches_management_secondary: "ניהול ועריכה של סניפים",
                stands_management_secondary: "ניהול ועריכה של עמדות",
                services_management_secondary: "ניהול ועריכה של שירותים",
                companies_management_secondary: "ניהול ועריכה של חברות",
                workers_committees_management_secondary: "ניהול ועריכה של לקוחות וועדי עובדים",
                coupons_management_secondary: "ניהול ועריכה של ניהול קופונים",
                coupons_packages_management_secondary: "ניהול ועריכה של ניהול חבילות קופנים",
                clients_management_secondary: "ניהול ועריכה של לקוחות",
                appointments_management_secondary: "ניהול ועריכה של תורים",
                user: "משתמש",
                client: "לקוח",
                branch: "סניף",
                stand: "עמדה",
                service: "שירות",
                appointment: "תור",
                company: "חברה",
                reminders: "תזכורות",
                rolsepermissions: "תפקידים והרשאות",
                rolespermissions_secondary: "ניהול ועריכה של תפקידים והרשאות",
                screens: "הרשאות צפייה במסכים",
                fields: "הרשאות צפייה בשדות",
                callCenterTasks: 'משימות מוקד',
                addAppointmentModal: 'הוסף תור',
                editAppointmentModal: 'ערוך תור',

            },
            login: {
                username: 'שם משתמש',
                email: 'כתובת אימייל',
                name: 'שם',
                password: 'סיסמא',
                signIn: 'התחברות',
                reset: 'שלח',
                update: 'עדכן',
                oldPassword: 'סיסמא ישנה',
                newPassword: 'סיסמא חדשה',
                newPassword2: 'אישור סיסמא חדשה',
                rememberMe: 'זכור אותי',
                forgotPassword: 'שכחתי סיסמא',
            },
            rolespermissions:{
                currentRole: "תפקיד נוכחי:",
                chooseRole: "בחר תפקיד"
            },
            users: {
                resetPassword: 'אפס סיסמא',
                blockModal: {
                    title: 'חסימת לקוח',
                    message: 'האם אתה בטוח שברצונך לחסום משתמש זה?'
                }
            },
            branches: {
                name: 'שם הסניף',
            },
            stands: {
                name: 'שם העמדה',
                branchName: 'שם הסניף',
                currentUser: 'לקוח בעמדה',
                currentCar: 'רכב בעמדה',
                openJoin: 'עבור למועדון לקוחות'
            },
            services: {
                name: 'שם השירות',
                notes: 'הערות',
                cost: 'מחיר (ש"ח)',
                advertisement: 'תיאור ההטבה',
                discount: 'גובה ההנחה',
                discountType: 'סוג ההנחה',
                allowOnlinePayment: 'אפשר תשלום אונליין',
                appointmentDuration: 'זמן השירות (בדקות)',
                paymentOptions: 'אפשרויות תשלום',
            },
            appointments: {
                name: 'שם השירות',
                appointmentDate: 'תאריך התור',
                appointmentStartingDate: 'תאריך תחילת התור',
                cost: 'מחיר (ש"ח)',
                user: 'שם הלקוח',
                car: 'מספר רכב',
                arrived: 'האם הלקוח הגיע',
                appointmentDuration: 'זמן השירות (בדקות)',
                isPaid: 'בוצע תשלום',
                onlinePayment: 'תשלום אונליין',
                deleteModal: {
                    title: 'מחיקת תור',
                    message: 'האם אתה בטוח שברצונך למחוק תור זה?'
                }
            },
        },
        models: {
            user: 'לקוח',
            serviceCall:"פניית שירות",
            serviceProvider:"נותן שירות"
        },
        sidebar: {
            menu: 'תפריט',
            customers: 'לקוחות',
            service_calls: 'פניות שירות',
            service_providers: 'נותני שירות',
            message_templates_management: 'ניהול תבניות',
            users_management: "ניהול משתמשים",
            branches_management: "ניהול סניפים",
            branch_management: "ניהול סניף",
            stands_management: "ניהול עמדות",
            services_management: "ניהול שירותים",
            companies_management: "ניהול חברות",
            workers_committees: "ניהול וועדי עובדים",
            coupons_management: "ניהול קופונים",
            coupons_packages_management: "ניהול חבילות קופונים",
            clients_management: "ניהול לקוחות",
            appointments_management: "ניהול תורים",
            roles_and_permissions: "תפקידים והרשאות",
            branches_stats: "דוח נרשמים"


        },
        user: {
            tabs: {
                details: 'פרטי הפנייה',
                status: 'מצב קיים',
                proposals: 'הצעות',
                actions: 'פעולות מרכזיה',
                log: 'יומן מערכת',
                list: 'רשימת הפצה לנותני שירות',
                balance: 'עדכוני יתרה',
                contact: 'פרטי התקשרות',
                finance: 'כספים',
                internet: 'אינטרנט',
                last_requests: 'פניות אחרונות',
                log1: 'יומן אירועים',
                payment: 'תשלום באשראי',
                provider_details: 'פרטי נותן שירות',
                reminders: 'תזכורות',
                requests: 'בקשות',
                subjects: 'שיוך לנושאים',
                tasks: 'משימות מוקד',
                workhours: 'שעות פעילות',
                areas: 'איזורי פעילות',
                availability: 'זמינות',
                working_areas: 'Working areas',
                messages: 'הודעות',
                documents: 'מסמכים',
                callCenterTasks: 'משימות מוקד'
            }
        },
        service_provider: {
            save_credit: 'שמירת פרטי כרטיס אשראי',
            charge_credit: 'חיוב כרטיס אשראי',
            charge_by_consum: 'חיוב לפי צריכה',
            amount_to_charge: 'סכום לחיוב לפי צריכה',
            cancelTask: 'בטל משימה',
            new: 'חדש',
            clone: 'שכפל תזכורת',
            old_reminders: 'תזכורות ישנות',
            sendMessage: "שלח הודעה",
            sendMessage2: "שלח הודעה ללקוח",
            messageType: "סוג ההודעה",
            messageTemplate: "תבנית ההודעה",
            newMessageTemplate: "צור הודעה חדשה",
            or: "או",
            send: 'שלח',
            view: "צפייה",

        },
        serviceCall: {
          modal: {
              title: 'סגור קריאת שרות',
              message: 'האם אתה בטוח שברצונך למחוק קריאת שירות זו?'
          }
        },
        smartTable: {
            noData: 'אין מידע להצגה',
            search: 'חיפוש חופשי',
            displayFields: 'בחר שדות להצגה',
            addButton: 'הוסף',
            exportButton: 'ייצא',
            edit: 'עריכה',
            view: 'צפייה',
            fields: {
                actions: 'פעולות',
                password: "סיסמא",
                id: 'מספר מזהה',
                date: 'תאריך',
                callType: 'סוג פניה',
                leadType: 'סוג ליד',
                customerName: 'שם הלקוח',
                subject: 'נושא',
                area: 'איזור',
                district: 'מחוז',
                field: 'תחום',
                source: 'מקור',
                firstName: 'שם פרטי',
                lastName: 'שם משפחה',
                email: 'דוא״ל',
                phone: 'טלפון',
                cars: 'רכבים',
                gender: 'מין',
                status: 'סטטוס',
                services: 'שירותים',
                city: 'עיר',
                street: 'רחוב',
                houseNumber: 'מספר בית',
                apartment: 'דירה',
                postalCode: 'תא דואר',
                zipCode: 'מיקוד',
                providerAgent: 'נציג משוייך',
                workingHours: 'שעות פתיחה',
                vacations: 'ימים סגורים',
                paymentOptions: 'אפשרויות תשלום',
                officeGuyCustomerID: 'מספר לקוח (OfficeGuy)',
                officeGuyItemID: 'מספר מזהה (OfficeGuy)',
                address: 'כתובת',
                income: 'הכנסה',
                profession: 'מקצוע',
                isMarried: 'נשוי',
                fromPilot: 'לקוח פיילוט',
                testData: 'תאריך טסט אחרון',
                companyName: 'שם החברה',
                workerNumber: 'מספר עובד',
                logo: 'לוגו',
                autoActivate: 'אישור לקוח אוטומטית',
                subDomain: 'נתיב ב-URL',
                packages: 'חבילות',
                birthDate: 'תאריך לידה',
                created: 'תאריך יצירה',
                lastLoginTime: 'תאריך התחברות אחרונה',
                lastUpdateTime: 'תאריך עדכון אחרון',
                editView: 'עריכה/צפייה',
                extraPhone: 'טלפון נוסף',
                description: 'תיאור',
                desiredDate: 'תאריך מתן שירות נדרש',
                providerAssigned: 'נותן שירות שהוקצה',
                expiryDate: 'תאריך תפוגה',
                showInSelected: 'הצג בדף פניות נבחרות',
                reachTime: 'זמן הגעה ללקוח',
                lastActivity: 'פעילות אחרונה',
                openingDate: 'תאריך פתיחה',
                supervisorComment: 'הערת מפקח',
                lastUpdated: 'עדכון אחרון',
                updatedBy: 'עודכן על ידי',
                callTime: 'שעת שיחה',
                timeElapsed: 'משך שיחה',
                callResult: 'תוצאת השיחה',
                businessName: 'שם העסק',
                businessId: 'ח.פ./ע.מ.',
                destination: 'יעד',
                charge: 'חיוב',
                isPressed: 'חיווי לחיצה',
                sent: 'נשלח',
                areaName: 'שם האיזור',
                chargeType: 'סוג חיוב',
                sendType: 'אופן שליחה',
                contact: 'איש קשר',
                assignmentDate: 'תאריך שיוך',
                assignmentReason: 'סיבת הקצאה',
                active: 'פעיל',
                autoCharge: 'חיוב אוטומטי',
                acceptsLeadByPhone: 'מקבל לידים בטלפון',
                AgreeToTerms: 'הסכים לתנאי השרות',
                paymentType: 'אופן תשלום',
                lastCharge: 'חיוב אחרון',
                currentBalance: 'יתרה נוכחית',
                maxCall: 'קריאה מקס׳',
                available: 'זמין',
                payed: 'לקוח משלם',
                virtualCredit: 'יתרה וירטואלית',
                functioning: 'מתפקד',
                perry: 'פרי',
                creationDate: 'נוצרה',
                completionDate: 'בוצעה',
                agent: 'נציג',
                answerTime: 'זמן מענה',
                finishTime: 'זמן ניתוק',
                campaign: 'קמפיין',
                user: 'משתמש',
                branch: 'סניף',
                stand: 'עמדה',
                service: 'שירות',
                appointment: "תור",
                hasFailed: 'כשל',
                hasSucceeded: 'תקין',
                acepayStatus: 'סטטוס אספיי',
                lastCheck: 'בדיקה אחרונה',
                passedTo: 'עבר ל',
                reminderDate: 'תאריך תזכורת',
                reminderTime: 'שעת תזכורת',
                closeCall: 'סגור קריאת שרות',
                closedEvent: 'אירוע סגור',
                comment: 'הערה',
                prioritize: 'עדיפות גבוהה',
                phoneCall: 'שיחת טלפון',
                callNumber: 'מספר להתקשרות',
                requestType: 'סוג בקשה',
                serviceProviderName: 'שם נותן שירות',
                requestedAmount: 'סכום מבוקש',
                requestStatus: 'סטטוס הבקשה',
                agentComment: 'הערות נציג',
                dealAssignment: 'שיוך עסקאות לאחור החל מתאריך',
                approvedAmount: 'סכום מאושר',
                type: 'סוג',
                amount: 'כמות',
                confirmationNum: 'אסמכתא',
                receipt: 'קבלה',
                comments: 'הערות',
                response: 'תגובה',
                eventType: 'סוג אירוע',
                time: 'זמן',
                operational: 'מתפקד',
                messageType: 'סוג ההודעה',
                messageTitle: 'כותרת',
                messageName: 'שם ההודעה',
                messageBody: 'גוף ההודעה',
                phonePrefix: 'קידומת',
                phoneNumber: 'מספר',
                isMainPhone: 'מספר ראשי?',
                oldBalance: "יתרה קודמת",
                newBalance: "יתרה חדשה",
                CCID: "תעודת זהות",
                CCType: "סוג כרטיס",
                CCNumber: "מספר הכרטיס",
                paymentMethod: "שיטת תשלום",
                CCExpiredMonth: "תקף עד לחודש",
                CCExpiredYear: "תקף עד שנת",
                CVC: "ספרות ביטחון",
                dailyEntrance: "כניסה אחרונה",
                role: "תפקיד",
                roleName: "שם התפקיד",
                screenName: "שם המסך",
                tableName: "שם הטבלה",
                fieldName: "שם השדה",
                editPermissions: "ערוך הרשאות",
                taskType: "סוג משימה",
                taskStatus: "סטטוס משימה",
                attempts: "מספר נסיונות",
                agentName: "שם סוכן",
                startTime: "מועד תחילת שיחה",
                endTime: "מועד סיום שיחה",
                duration: "משך שיחה (שניות)",
                RecordingURL: "כתובת הקלטה",
                initiationTime: "מועד הפעלת משימה",
                reminderCreationTime: "מועד יצירת תזכורת",
                reminderScheduledTime: "מועד מתוזמן לתזכורת",
                destinationPhone: "טלפון יעד",
                transactionStatus: "סטטוס",
                priority: "עדיפות",

            },
            pagination: {
                page: 'עמוד',
                of: 'מתוך'
            },
            confirm: "אישור",
            cancel: "ביטול",
        },
        telephones: {
            title: 'הוסף/ערוך טלפונים',
            add: 'הוסף טלפון',
            noPhones: 'לא הוספו טלפונים',
            phoneNumber: 'מספר טלפון',
            mainPhone: "(מספר טלפון ראשי)",
            isMainPhone: "טלפון ראשי",
            setMainPhone: "הפוך לטלפון ראשי"
        },
        taskTypesModal: {
            title: 'Add task types',
        },
        documentsSmartTable: {
            fields: {
                name: 'שם המסמך',
                type: 'סוג',
                uploadDate: 'תאריך העלאה',
                uploadedBy: 'הועלה ע״י',
                file: 'פעולות',
                id: "מזהה קובץ",
            }
        },
        smartForm: {
            modes: {
                view: 'צפה',
                edit: 'ערוך',
                new: 'צור'
            },
            save: 'שמור',
            cancel: 'בטל',
            trueValue: 'כן',
            falseValue: 'לא',
        },
        subject: {
            title: `היי [VAR],`,
            description: `איך אפשר לעזור לך היום?`,
            professionsList: `לרשימת נושאי השירות`,
            footer: {
                a: `איש המקצוע המומלץ ביותר מיד מתקשר אליך!`,
                b: `לפי דירוג לקוחות, מיקום, זמינות ואיכות.`
            },
            typeRequest: `לחץ והקלד את השירות המבוקש`
        },
        locationAndTiming: {
            location: `בחירת שם היישוב בו נדרש השירות`,
            timing: `מתי ניתן להתקשר אליך?`,
            locationError: `שם היישוב חסר או אינו תקין`,
            locationSpecific: 'הוסף איזור',
            continue: 'המשך',
            proposals: `בחר כמה הצעות תרצה לקבל`,
            multiple: `מספר הצעות`,
            single: `הצעה אחת`
        },
        details: {
            rotatingText: {
                a: `המערכת מאתרת את הספץ המתאים ביותר עבורך`,
                b: `המערכת מחפשת ספץ באזורך`,
                c: `בדיקת זמינות נותני שירות רלוונטיים`,
                d: `סינון לפי דירוג לקוחות קודמים`,
                e: `נמצא ספץ!`,
                f: `מעביר פרטים`,
                g: `נותן השירות יתקשר אליך במועד המבוקש`
            },
            media: {
                title: `בחר מדיה לתיאור הבעיה`,
                camera: `מצלמה`,
                takePicture: `צלם תמונה`,
                fromGalleryIOS: `בחר מהגלריה`,
                fromGalleryAndroid: `בחר תמונה מהגלריה`,
                takeVideo: `צלם סרטון`,
                fromGallery: `בחר סרטון מהגלריה`
            },
            description: {
                a: `כמעט סיימנו...`,
                b: ` לסיום בחר ב״מצא ספץ!״`,
                c: `איש המקצוע יתקשר במועד המבוקש.`,
                d: `לעריכה לחץ על השדה הרלוונטי.`,
            },
            cancel: `ביטול וחזרה להתחלה`,
            callback: `לתשומת ליבך, בהמשך נתקשר בשיחה חסויה לווידוא קבלת מענה מתאים.`,
            cancelCall: `ביטול פניה`,
            serviceCallCancelled: `פנייתך בוטלה`,
            cancelNotificationReceived: `איש המקצוע קיבל הודעת ביטול.`,
            onOurWay: `ספץ בדרך אליך`,
            backToHome: `חזרה לעמוד הראשי`,
            backHome: `חזור למסך הראשי`,
            error: `אירעה שגיאה.`,
            tryLater: `נסה שוב`,
            oops: 'אופס!',
            cannotOpenServiceCall: `                                ספץ לא יכול לפתוח את הקריאה בשבילך.
                                כבר קיימת קריאה קודמת פתוחה
                                בנושא שירות זה.`,
            contactUs: `צור קשר`,
            findSpetz: `מצא ספץ`,
            cancelIt: `בטל`,
            summary: {
                need: `אני צריך`,
                location: `ביישוב`,
                when: `שיצרו איתי קשר`,
                phone: `לטלפון`,
                accept: `אישור`
            },
            comment: `הלקוח הוסיף תיאור לבעיה ו/או תמונה: [VAR]\n[VAR]`,
            commentPlaceholder: `הוסף תיאור לבעיה`
        },
        history: {
            ranking: {
                "1": `יש מה לשפר`,
                "2": `כמעט טוב`,
                "3": `טוב`,
                "4": `טוב מאד`,
                "5": `מעולה`,
                "6": `מושלם`
            },
            parameters: {
                ranking: `דירוג: `,
                raters: `מספר מדרגים: `,
                noOfCalls: `מספר פניות שהופנו: `,
                seniority: `ותק: `,
            }
        },
        misc: {
            richContent: `מעל 400 נושאי שירות לבחירתך`,
        },

        working_hours: {
            monday_short: 'א׳',
            tuesday_short: 'ב׳',
            wednesday_short: 'ג׳',
            thursday_short: 'ד׳',
            friday_short: 'ה׳',
            saturday_short: 'ו׳',
            sunday_short: 'ש׳',
            twenty_four_hours: '24 שעות',
            label_1: 'שעות פעילות',
            label_2: 'ימים ושעות פעילות לקבלת שיחות מלקוחות בזמן אמת',
            label_3: 'בחר את ימי הפעילות הקבועים שלך',
            holidays: 'חגים ומועדים',
            all_day_of_week: 'החל על כל ימות השבוע',
            from_an_hour: 'משעה',
            until: 'עד',
            didSave: 'Areas successfully saved!', // TODO: TRANSLATE IN HEBREW
        },
        workAreas:{
            title: "איזורי פעילות לעבודה",
            pickAreas: "בחר את האיזורים בהם אתה נותן שירות",
            save: "לשמור",
            pickPoint: "בחר נק׳ על המפה",
            includeAreas: "כלול איזור",
            noArea: "ללא איזור",
        },
        rolesPermissions:{
            addRole: "הוסף תפקיד",
            deleteRole: "מחק תפקיד",
            areYouSure: "האם אתה בטוח שברצונך למחוק תפקיד זה?",
            didSave: 'אזורי הפעילות נשמרו',

        },
        activityTimes: {
            title: "שעות פעילות",
            title2: "ימים ושעות פעילות לקבלת שיחות מלקוחות בזמן אמת",
            twentyFourHours: "24 שעות",
            holidays:"חגים ומועדים",
            fromHour: "משעה",
            toHour: "עד שעה",
            setOnAllWeek:"החל על כל ימות השבוע",
            almostDone:  "כמעט סיימנו",
            sunday: "א׳",
            monday: "ב׳",
            tuesday: "ג׳",
            wednesday: "ד׳",
            thursday: "ה׳",
            friday: "ו׳",
            saturday: "ש",
            didSave: 'שעות הפעילות נשמרו',
            and: "וגם"

        },
        areas: {
            label_1: 'אזורי פעילות לעבודה',
            label_2: 'בחר את האזורים בהם אתה נותן שירות',
            switch_text_1: 'לפי אזור',
            switch_text_2: 'לפי רדיוס',
        },
        unavailability: {
            label_1: 'תקופת אי זמינות לעבודה ומענה',
            label_2: 'הזן תקופת אי זמינות לעבודה מתאריך ושעה עד לתאריך ושעה',
            from_date: 'מתאריך',
            from_time: 'שעה',
            to_date: 'עד לתאריך',
            to_time: 'שעה',
            save: "שמור",
            unavailable1: "הינך לא זמין כעת.",
            clickHere: "לחץ כאן",
            unavailable2: "על מנת להפוך לזמין שוב",
            error: "טווח התאריכים אינו תקין",
            pastDateRange: "לא ניתן לבחור תאריך עבר",
            overlapDates: 'קיימים תאריכים חופפים'
        },
        subjects: {
            label_1: 'תחומים',
            label_2: 'בחר את הנושאים בהם אתה נותן שירות',
            apply_to_all_service_area: 'חל על כל איזורי השירות שלך',
            edit_areas: 'ערוך אזורים',
        },
        working_areas: {
            label_1: 'אזורי פעילות לעבודה',
            label_2: 'בחר את האזורים בהם אתה נותן שירות',
            search_box_1: 'Enter place',
            search_box_2: 'Enter place',
            availability_1: 'כלול אזור ',
            availability_2: 'ללא אזור',
            google_maps_bottom_text: 'בחר נק׳ על מפה',
        },
        callCenter: {
            label_2: "משימות מוקד המשוייכות לנותן השרות"
        },
        fields: {
            title: "תחומים",
            chooseFields: "בחר את הנושאים בהם אתה נותן שירות",
            insertField: "לחץ והקלד את השירות המבוקש",
            continue: "המשך",

        },
        balance: {
            deletePM: "מחיקת אמצעי תשלום",
            deletePMMessage: "האם אתה בטוח שברצונך למחוק את אמצעי התשלום מהרשימה?",
            setDefaultPM: "קביעת אמצעי ברירת מחדל",
            setDefaultPMMessage: "האם אתה בטוח שברצונך לקבוע את אמצעי התשלום זה כאמצעי ברירת המחדל?",
            confirm: "אישור",
            cancel: "ביטול",
        }
    },
    en: {
        pages: {
            titles: {
                customers: 'Customers',
                service_calls: 'Service Calls',
                service_call: 'Service Call',
                service_calls_secondary: 'Manage and edit service calls, create new calls',
                callTitle: 'Call Details',
                providerTitle: 'Service Provider Details',
                service_providers: 'Service Providers',
                service_provider: 'Service Provider',
                service_providers_secondary: 'Manage, edit and create new service providers',
                message_templates_management: 'Templates Management',
                documents_list: 'Documents List',
                document: "Document",
                company: "Company",
                provider_phones: "Phone Numbers",
                reminders: "Reminders",
                transaction: "Transaction",
                invoices: "Invoices",
                balanceUpdates: "Balance Updates",
                paymentMethod: "Payment Method",
                paymentMethods: "Payment Methods",
                billing: "Transactions",
                users_management: "Users Management",
                branches_management: "Branches Management",
                stands_management: "Stands Management",
                services_management: "Services Management",
                companies_management: "Companies Management",
                clients_management: "Clients Management",
                appointments_management: "Appointments Management",
                users_management_secondary: "Manage and edit users, create new users",
                branches_management_secondary: "Manage and edit branches",
                stands_management_secondary: "Manage and edit stands",
                services_management_secondary: "Manage and edit services",
                companies_management_secondary: "Manage and edit Companies",
                clients_management_secondary: "Manage and edit clients",
                appointments_management_secondary: "Manage and edit appointments",
                user: "User",
                rolsepermissions: "Roles & Permissions",
                rolespermissions_secondary: "Manage and edit user roles and permissions",
                screens: "Screens Viewing Permissions",
                fields: "Fields Viewing Permissions",
                callCenterTasks: 'Call Center Tasks',
                callCenterTasks_label_2: 'Set scheduled reminders as tasks for the call center'

            },
            login: {
                username: 'User Name',
                email: 'Email Address',
                name: 'Name',
                password: 'Password',
                signIn: 'Log In',
                rememberMe: 'Remember Me',
                forgotPassword: 'Forgot Password',
            },
            rolespermissions:{
                currentRole: "Current Role:",
                chooseRole: "Choose Role"
            }
        },
        models: {
            user: 'Customer',
            serviceCall:"Service Call",
            serviceProvider:"Service Provider"
        },
        sidebar: {
            menu: 'Menu',
            customers: 'Customers',
            service_calls: 'Service Calls',
            service_providers: 'Service Providers',
            message_templates_management: 'Templates Management',
            users_management: "Users Management",
            branches_management: "Branches Management",
            stands_management: "Stands Management",
            services_management: "Services Management",
            companies_management: "Companies Management",
            appointments_management: "Appointments Management",
            roles_and_permissions: "Roles & Permissions",
            branches_stats: "דוח נרשמים"
        },
        documents:{
            label_2: "Service provider documents"
        },
        lastCalls:{
            label_2: "Service provider last calls"
        },
        user: {
            tabs: {
                details: 'Details',
                status: 'Status',
                proposals: 'Actions',
                actions: 'Operator Tasks',
                log: 'System Log',
                list: 'Dispatch List',
                balance: 'Billing',
                contact: 'Contact Details',
                finance: 'Finances',
                internet: 'Internet',
                last_requests: 'Last Calls',
                log1: 'Events Log',
                payment: 'Pay By Credit Card',
                provider_details: 'Provider Details',
                reminders: 'Reminders',
                requests: 'Requests',
                subjects: 'Service Types',
                tasks: 'Call Center Tasks',
                workhours: 'Activity Times',
                areas: 'Activity Areas',
                unavailability: 'Availability',
                working_areas: 'Working areas',
                messages: 'Messages',
                documents: 'Documents',
                callCenterTasks: 'Call Center Tasks',
                callCenterTasks_label_2: 'קבע תזכורות מתוזמנות עבר מוקד השרות'
            }
        },
        service_provider: {
            save_credit: 'Save Credit Card Details',
            charge_credit: 'Charge Credit Card',
            charge_by_consum: 'Charge By Consumption',
            sendMessage: "Send Message",
            sendMessage2: "Send Message",
            messageType: "Message Type",
            messageTemplate: "Message Template",
            newMessageTemplate: "New Message",
            or: "Or",
            send: 'Send',
            view: "View"
        },
        reminders: {
            label_2: "Service provider reminders"
        },
        callCenter: {
            label_2: "Service provider related call center tasks"
        },
        serviceCall: {
            modal: {
                title: 'Close Service Call',
                message: 'Are you sure you want to close this service call?'
            }
        },
        smartTable: {
            noData: 'No data found',
            search: 'Free Search',
            displayFields: 'Select Fields To Display',
            addButton: 'Add',
            exportButton: 'Export',
            editView: 'Edit',
            edit: 'Edit',
            view: 'View',

            fields: {
                actions: 'Actions',
                password: "Password",
                id: 'ID',
                date: 'Date',
                callType: 'Call Type',
                leadType: 'Lead Type',
                customerName: 'Customer Name',
                subject: 'Subject',
                area: 'Area',
                district: 'District',
                field: 'Field',
                source: 'Source',
                name: 'Name',
                email: 'Email',
                phone: 'Phone',
                cars: 'Cars',
                editView: 'Edit/View',
                extraPhone: 'Extra Phone',
                description: 'Description',
                desiredDate: 'Required Service Date',
                providerAssigned: 'Service Provider Assigned',
                expiryDate: 'Expiry Date',
                showInSelected: 'Show in Selected Calls',
                reachTime: 'Provider Arrival Time',
                lastActivity: 'Last Activity',
                openingDate: 'Creation Date',
                supervisorComment: 'Supervisor Comment',
                lastUpdated: 'Last Update',
                updatedBy: 'Updated By',
                callTime: 'Call Time',
                timeElapsed: 'Time Elapsed (Sec)',
                callResult: 'Call Result',
                businessName: 'Company Name',
                businessId: 'Reg Number',
                destination: 'Destination',
                charge: 'Charge',
                isPressed: 'Press Indicator',
                sent: 'Sent',
                areaName: 'Area Name',
                chargeType: 'Charge Type',
                sendType: 'Delivery Type',
                contact: 'Contact Name',
                assignmentDate: 'Assignment Date',
                assignmentReason: 'Assignment Reason',
                active: 'Active',
                autoCharge: 'Auto Charge',
                acceptsLeadByPhone: 'Accepts Leads By Phone',

                AgreeToTerms: 'Agreed To Terms',

                paymentType: 'Payment Method',
                lastCharge: 'Last Charge',
                currentBalance: 'Balance',
                maxCall: 'Max Call',
                available: 'Available',
                payed: 'Paying Customer',
                virtualCredit: 'Virtual Credit',
                functioning: 'Functioning',
                perry: 'Perry',
                status: 'Status',
                creationDate: 'Created',
                completionDate: 'Completed',
                agent: 'Agent',
                answerTime: 'Answer Time',
                finishTime: 'Finish Time',
                campaign: 'Campaign',
                user: 'User',
                hasFailed: 'Failure',
                hasSucceeded: 'Success',
                acepayStatus: 'Acepay Status',
                lastCheck: 'Last Checkup',
                passedTo: 'Passed To',
                reminderDate: 'Reminder Date',
                reminderTime: 'Reminder Time',
                closedEvent: 'Closed Event',
                closeCall: 'Close Service Call',
                comment: 'Comments',
                prioritize: 'High Priority',
                phoneCall: 'Phone Call',
                callNumber: 'Callback Number',
                requestType: 'Request Type',
                serviceProviderName: 'Provider Name',
                requestedAmount: 'Requested Amount',
                requestStatus: 'Request Status',
                agentComment: 'Agents Comments',
                dealAssignment: 'Retroactive Deals Assignment Since',
                approvedAmount: 'Approved Amount',
                type: 'Type',
                amount: 'Amount (£)',
                oldBalance: 'Old Balance (£)',
                newBalance: 'Current Balance (£)',
                confirmationNum: 'Confirmation Number',
                receipt: 'Receipt',
                comments: 'Comments',
                response: 'Response',
                eventType: 'Event Type',
                time: 'Time',
                operational: 'Operational',
                messageType: 'Message Type',
                messageTitle: 'Message Title',
                messageName: 'Message Name',
                messageBody: 'Message Body',
                phonePrefix: 'Prefix',
                phoneNumber: 'Number',
                isMainPhone: 'Is Main Number?',
                city: 'City',
                street: 'Street',
                houseNumber: 'House Number',
                apartment: 'Apartment No.',
                postalCode: 'Postal Code',
                zipCode: 'Post Code',
                providerAgent: 'Assigned Agent',
                address: 'Address',
                firstName: "First Name",
                lastName: "Last Name",
                CCID: "ID Number",
                CCType: "Credit Card Type",
                CCNumber: "Credit Card Number",
                paymentMethod: "Payment Method",

                CCExpiredMonth: "Expiry Month",
                CCExpiredYear: "Expiry Year",
                CVC: "CVC Number",
                dailyEntrance: "Last Entrance",
                role: "Role",
                roleName: "Role Name",
                screenName: "Screen Name",
                tableName: "Choose Table",
                fieldName: "FieldName",
                editPermissions: "Permissions",
                creationTime: "Creation Time",
                scheduledTime: "Scheduled Time",
                reminderType: "Reminder Type",
                reminderStatus: "Reminder Status",
                providerId: "Provider Id",
                notes: "Comments",
                taskType: "Task Type",
                taskStatus: "Task Status",
                attempts: "Attempts",
                agentName: "Agent Name",
                startTime: "Start Time",
                endTime: "End Time",
                duration: "Duration (Sec)",
                RecordingURL: "Recording URL",
                initiationTime: "Initiation Time",
                reminderCreationTime: "Reminder Creation Time",
                reminderScheduledTime: "Reminder Scheduled Time",
                destinationPhone: "Destination Phone",
                transactionStatus: "Status",
                transactionType: "Transaction Type",
                priority: "Priority"
            },
            pagination: {
                page: 'Page',
                of: 'of'
            }
        },
        telephones: {
            title: 'Add/Edit Telephones',
            add: 'Add Telephone',
            noPhones: 'There are no telephones added',
            phoneNumber: 'Phone Number',
            mainPhone: "(Main)",
            isMainPhone: "Main Phone",
            setMainPhone: "Set As Main Phone"

        },
        taskTypesModal: {
            title: 'Add task types',
        },
        documentsSmartTable: {
            fields: {
                name: 'Name',
                type: 'Type',
                uploadDate: 'Upload Date',
                uploadedBy: 'Uploaded By',
                file: 'Upload File',
                id: "ID",

            }
        },
        smartForm: {
            modes: {
                view: 'View',
                edit: 'Edit',
                new: 'New'
            },
            save: 'Save',
            cancel: 'Cancel',
            trueValue: 'Yes',
            falseValue: 'No',
        },

        subject: {
            title: `Hello [VAR],`,
            description: `How can we help you today?`,
            professionsList: `Open for list of services`,
            footer: {
                a: `A top professional will be calling you right now!`,
                b: `Based on customer rating, location, availability and expertise.`
            },
            typeRequest: `Type here the requested service`
        },
        locationAndTiming: {
            location: `Choose your location`,
            timing: `When can they call you?`,
            locationError: `Location field is missing`,
            locationSpecific: 'Please add your borough',
            continue: 'Continue',
            proposals: `בחר כמה הצעות תרצה לקבל`,
            multiple: `מספר הצעות`,
            single: `הצעה אחת`
        },

        rolesPermissions:{
            addRole: "Add Role",
            deleteRole: "Delete Role",
            areYouSure: "Are you sure you wish to delete this role?"
        },
        details: {
            rotatingText: {
                a: `The application is locating the right service provider for you`,
                b: `The system is checking availability`,
                c: `The system is selecting the best rated provider`,
                d: `We’ve done it!`,
                e: `You will get a call now`,
                f: `You will get a call now`,
                g: `נותן השירות יתקשר אליך במועד המבוקש`
            },
            media: {
                title: `Choose media to describe the problem`,
                camera: `Camera`,
                takePicture: `Take a Picture`,
                fromGalleryIOS: `From Gallery`,
                fromGalleryAndroid: `Picture from Gallery`,
                takeVideo: `Take a Video`,
                fromGallery: `Video from Gallery`
            },
            description: {
                a: `You’re almost there...`,
                b: `To finish, click on: “Spetz-it!”`,
                c: `The service provider will call you \nat the requested time.`,
                d: `To edit, click on the relevant field.`,
            },
            cancel: `Cancel and go back`,
            callback: `Please note: you will receive from us very shortly a personal call to make sure you have received the right provider.`,
            cancelCall: `Cancel request`,
            serviceCallCancelled: `Service cancelled`,
            cancelNotificationReceived: `איש המקצוע קיבל הודעת ביטול.`,
            onOurWay: `Your service provider is on the way`,
            backToHome: `חזרה לעמוד הראשי`,
            backHome: `חזור למסך הראשי`,
            error: `Error occurred`,
            tryLater: `try again`,
            oops: 'אופס!',
            cannotOpenServiceCall: `ספץ לא יכול לפתוח את הקריאה בשבילך. כבר קיימת קריאה קודמת פתוחה בנושא שירות זה.`,
            contactUs: `צור קשר`,
            findSpetz: `Spetz-it`,
            cancelIt: `Cancel`,
            summary: {
                need: `Service`,
                location: `Location`,
                when: `Time to call`,
                phone: `Tel:`,
                accept: `Ok`
            },
            comment: `הלקוח הוסיף תיאור לבעיה ו/או תמונה: [VAR]\n[VAR]`,
            commentPlaceholder: `Describe the problem`
        },
        history: {
            ranking: {
                "1": `Improving`,
                "2": `Almost good`,
                "3": `Good`,
                "4": `Very Good`,
                "5": `Excellent`,
                "6": `Perfect`
            },
            parameters: {
                ranking: `Ranking: `,
                raters: `No. of Raters: `,
                noOfCalls: `No. Of Calls: `,
                seniority: `Seniority: `,
            }
        },
        misc: {
            richContent: `more than 400 services available`
        },
        working_hours: { /// TODO: TRANSLATION IN ENGLISH
            monday_short: 'A',
            tuesday_short: 'B',
            wednesday_short: 'C',
            thursday_short: 'D',
            friday_short: 'E',
            saturday_short: 'F',
            sunday_short: 'G',
            twenty_four_hours: '24 hours',
            label_1: 'Activity Times',
            label_2: 'Days and operating hours for real-time customer calls',
            label_3: 'Choose your regular business days',
            holidays: 'Holidays',
            all_day_of_week: 'All day of the week',
            from_an_hour: 'From',
            until: 'To',
            didSave: 'Areas successfully saved!',
        },
        workAreas:{
            title: "Activity Areas",
            pickAreas: "Pick your activity areas",
            save: "Save",
            pickPoint: "Choose a point",
            includeAreas: "Include Area",
            noArea: "Exclude Area",
            didSave: 'Activity areas saved!',
            selectServiceTypesFirst: 'Please select Service Types first',
            serviceTypes: 'Service Types'
        },
        activityTimes: {
            title: "Activity Times",
            title2: "Set up your availability times for service requests",
            twentyFourHours: "24 Hours",
            holidays:"Holidays",
            fromHour: "From",
            toHour: "To",
            setOnAllWeek:"Set on all week days",
            almostDone:  "Almost Done!",
            sunday: "S",
            monday: "M",
            tuesday: "T",
            wednesday: "W",
            thursday: "T",
            friday: "F",
            saturday: "S",
            didSave: 'Activity hours saved!',
            and: "And"
        },
        areas: {
            label_1: 'Activity Areas',
            label_2: 'Select areas',
            switch_text_1: 'by radius',
            switch_text_2: 'by region',
        },
        unavailability: {
            label_1: 'Unavailability',
            label_2: 'Set a temporary unavailability period',
            from_date: 'From: ',
            from_time: ' ',
            to_date: 'To: ',
            to_time: ' ',
            save: "Save",
            unavailable1: "You’re currently defined as unavailable. ",
            clickHere: "Click here ",
            unavailable2: "to become available again.",
            error: "The date range is invalid",
            pastDateRange: "The date range is in the past",
            overlapDates: 'There are overlap dates'
        },
        subjects: {
            label_1: 'Subjects',
            label_2: 'Areas of expertise',
            apply_to_all_service_area: 'applies to all service areas',
            edit_areas: 'Edit areas'
        },
        working_areas: {
            label_1: 'Areas of work',
            label_2: 'Choose your areas of work',
            search_box_1: 'Enter working place',
            search_box_2: 'Enter non working place',
            availability_1: 'Working place',
            availability_2: 'Non working place',
            google_maps_bottom_text: 'Select a point',
        },
        fields: {
            title: "Service Fields",
            chooseFields: "Please pick your service fields",
            insertField: "Press and type a service field name",
            continue: "Continue",

        },
        balance: {
            deletePM: "Delete Payment Method",
            deletePMMessage: "Are you sure you wish to delete the selected payment method from the list?",
            setDefaultPM: "Set Default Payment Method",
            setDefaultPMMessage: "Are you sure you wish to set the selected payment method as the default method?",
            confirm: "Confirm",
            cancel: "Cancel",
        }
    },
};

const getValueByKey = function (obj, key) {
    let base = obj;
    let keys = key.split('.');

    for (let k of keys) {
        if (base[k])
            base = base[k];
        else base = k;
    }

    return base;
};

const getVariableAttachedText = function (variables, text) {
    for (let variable of variables) {
        if (text.indexOf('[VAR]') > -1) {
            text = text.replace('[VAR]', variable);
        }
    }
    return text;
};

const localize = function (lang, key, variables) {

    if (!key) {
        throw 'Translations: Missing key';
    }

    if (!lang) {
        lang = Translations.getCurrentLanguage().name;
        if (!lang) {
            throw 'Translations: Missing language';
        }
    }

    let localText = getValueByKey(localization[lang || 'he'], key);

    if (!variables || variables.length === 0) {
        return localText;
    }

    return getVariableAttachedText(variables, localText);
};

const localized = (lang) => function (params) { return localize.call(this, lang, params); }

export { localize, localized }
