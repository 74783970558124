const generalStyle = {
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between',
        border: '1px solid #c8c8c8',
        boxShadow: "none",
        fontWeight: '500',
        padding: "5px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    }),
    container: () => ({
        width: '100%',
        height: '40px',
        marginTop: '4px',
    }),
    menuList: (provided, state) => {
        const customStyle = {
            width: '100%',
            border: '2px solid #ececec',
            borderRadius: '2px',
            boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
            backgroundColor: ' #ececec',
            justifyContent: 'center',
            '@media only screen and (max-width: 736px)': {
                width: '334px'
            }
        }
        return { ...provided, ...customStyle, };
    },
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const customStyle = {
            transition: 'opacity 300ms',
            color: '#979797',
            fontSize: '16px',
            textAlign: 'center',
            paddingRight: '26px',
        };

        return { ...provided, opacity, ...customStyle, };
    },
    menu: () => ({
        width: '100%',
        position: 'relative',
        zIndex: 99999,
        fontWeight: '500',
        '@media only screen and (max-width: 736px)': {
            width: '85%'
        }
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    option: () => ({
        backgroundColor: '#ececec',
        '&:hover': {
            backgroundColor: '#E4E4E4',
        },
        width: '100%',
        textAlign: 'center',
        fontSize: '15px',
        color: '#979797',
        cursor: 'pointer',
    }),
    placeholder: (provided, state) => {
        const customStyle = {
            paddingRight: ' 15px',
        };

        return { ...provided, ...customStyle, };
    },
}

export {
    generalStyle,
}