import React from "react";
import {connect} from "react-redux";
import {localize, localized} from "../../services/translation/localization";
import {required, email} from '../../services/validation'

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";

class ClientsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        const {enums} = this.props;
        this.tableConfig = {
            model: `users`,
            modelDisplayName: localize(null, 'pages.titles.client'),
            excelFileName: "דוח לקוחות",
            actions: ['view', 'edit'],
            endpoints: {
                create: "users/create",
                edit: "users"
            },
            handlers: {
                "PRE_CREATE": this.preCreate,
                "PRE_SAVE": this.preUpdate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false,
                    showFilter: false
                },
                {
                    accessor: "fullname",
                    alias: localize(null, "pages.login.name"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                // {
                //     accessor: "Password",
                //     alias: localize(null, "pages.login.password"),
                //     type: "text",
                //     value: (data, mode, user) => {
                //         return mode === 'new' ? Math.random().toString(36).slice(-8) : data.Password;
                //     },
                //     displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                //     allowTableView: false,
                //     show: true,
                //     filterable: false,
                //     validators: [required]
                // },
                // {
                //     accessor: "role",
                //     alias: localize(null, "smartTable.fields.role"),
                //     type: "select",
                //     minWidth: 60,
                //     displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                //     allowTableView: true,
                //     show: true,
                //     filterable: false,
                //     optionsKey: "UserRoles",
                //     options: enums.permissions.map((e) => {
                //         return {
                //             value: e.value,
                //             label: e.display,
                //         }
                //     }),
                //     validators: [required]
                // },
                {
                    accessor: "status",
                    alias: localize(null, "smartTable.fields.status"),
                    type: "select",
                    options: enums.userStatus.map((e) => {
                        return {
                            value: e.value,
                            label: e.display,
                        }
                    }),
                    displayModes: [SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "createdAt",
                    alias: localize(null, "smartTable.fields.testDate"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                // {
                //     accessor: "cars[0].testDate",
                //     alias: localize(null, "smartTable.fields.testDate"),
                //     type: "dateTime",
                //     displayModes: [SmartFormMode.view],
                //     allowTableView: true,
                //     show: true,
                //     filterable: false
                // },
                {
                    accessor: "fromPilot",
                    alias: localize(null, "smartTable.fields.fromPilot"),
                    type: "boolean",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: true
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false
                },
                {
                    accessor: "lastLoginTime",
                    alias: localize(null, "smartTable.fields.lastLoginTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false
                },
                {
                    accessor: "insurance",
                    alias: localize(null, "smartTable.fields.insuranceMonth"),
                    type: "select",
                    options: this.getMonths(),
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "branch.displayName",
                    alias: localize(null, "smartTable.fields.branch"),
                    type: "text",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "stand.displayName",
                    alias: localize(null, "smartTable.fields.stand"),
                    type: "text",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "email",
                    alias: localize(null, "smartTable.fields.email"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    validators: [email],
                    searchable: true
                },
                {
                    accessor: "phone",
                    alias: localize(null, "smartTable.fields.phone"),
                    type: "text",
                    minWidth: 100,
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    validators: [required],
                    searchable: true
                },
                {
                    accessor: "carsAsString",
                    alias: localize(null, "smartTable.fields.cars"),
                    type: "text",
                    minWidth: 300,
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false,
                    validators: [required]
                }

            ],
            restOptions: {
                filter: {role: "client", company: null, status: ["created", "pending", "active", "blocked"]},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }
    }

    getMonths = ()=>{
        let res =[];
        for (let i=1;i<=12;i++){
            res.push({
                label: `${i}`,
                value: i
            });
        }
        return res;
    }

    preCreate = async (doc) => {
        doc = Object.keys(doc)
            .filter(field => this.tableConfig.fields.find(f => f.accessor === field && f.displayModes.includes(SmartFormMode.new)))
            .reduce((obj, key) => {
                obj[key] = doc[key];
                return obj;
            }, {});
        // doc.permissions = [doc.role];
        return doc;
    }

    preUpdate = async (doc) => {
        doc = Object.keys(doc)
            .filter(field => this.tableConfig.fields.find(f => f.accessor === field && f.displayModes.includes(SmartFormMode.edit)))
            .reduce((obj, key) => {
                obj[key] = doc[key];
                return obj;
            }, {});
        // doc.permissions = [doc.role];
        return doc;
    }

    componentDidMount() {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.clients_management'));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.clients_management'));
        }
    }


    render() {
        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.clients_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {this.tableConfig && <SmartWrapper filterable={true} allowAdd={false} allowExport={true} viewButtonCallback={this.handleViewUser}
                                                  tableConfig={this.tableConfig}/>}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    enums: state.app.enums
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsManagement);
