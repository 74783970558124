import React, { PureComponent, useState } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from "moment";
import AddIcon from 'mdi-react/AddIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import {localize} from "../../../../../../../../services/translation/localization";
import {Field, FieldArray} from "redux-form";

const WorkingHoursForm = ({ initialValues }) => {
    const defaultHours = {from: '00:00', to: '23:59'};
    const [workingHours, setWorkingHours] = useState(
        {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            ...initialValues.normalWorkingHours
        },
    );

    const days = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']

    const handleChange = ({value, onChange, day, index, fromOrTo}) => {
        onChange(moment(value).format('HH:mm'))
        const updateHours = {...workingHours};
        updateHours[day][index][fromOrTo] = moment(value).format('HH:mm');
        setWorkingHours(updateHours);
    }

    const addTimeRange = ({fields, day}) => {
        fields.push(fields.length === 0 ? defaultHours : {...defaultHours, from: fields.get(fields.length - 1).to});
        const updateHours = {...workingHours};
        updateHours[day].push(updateHours[day].length === 0 ? defaultHours : {...defaultHours, from: updateHours[day][updateHours[day].length - 1].to});
        setWorkingHours(updateHours);
    }

    const deleteTimeRange = ({fields, day, index}) => {
        fields.remove(index);
        let updateHours = {...workingHours};
        updateHours[day].splice(index, 1);
        setWorkingHours(updateHours);
    }

    const getAsDate = (hour) => {
        return moment(hour, 'HH:mm').toDate();
    }

    const getMinDate = (index, fromOrTo, workingHours) => {
        if (fromOrTo === 'from') {
            return getAsDate(index === 0 ? '00:00' : workingHours[index - 1].to);
        } else {
            return getAsDate(workingHours[index].from);
        }
    }

    const getMaxDate = (index, fromOrTo, workingHours) => {
        if (fromOrTo === 'from') {
            return getAsDate(workingHours[index].to);
        } else {
            return getAsDate(index === workingHours.length - 1 ? '23:59' : workingHours[index + 1].from);
        }
    }

    const HoursPicker = ({ input: { onChange, value }, day, index, fromOrTo, workingHours }) => (
        <DatePicker
            className="form__form-group-datepicker"
            selected={getAsDate(value)}
            onChange={value => handleChange({value, onChange, day, index, fromOrTo})}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            dropDownMode="select"
            showTimeSelect
            showTimeSelectOnly
            timeCaption="שעה"
            timeIntervals={15}
            minTime={getMinDate(index, fromOrTo, workingHours)}
            maxTime={getMaxDate(index, fromOrTo, workingHours)}
        />
    )

    const RangeHours = ({ fields, day }) => (
        <>
            {fields.map((timeRange, index) => (
                <div className="hours-wrapper" key={index}>
                    <div className="range-button delete" onClick={() => deleteTimeRange({fields, day, index})}><DeleteIcon/></div>
                    <span>מ-</span>
                    <Field
                        name={`${timeRange}.from`}
                        component={HoursPicker}
                        day={day}
                        index={index}
                        fromOrTo={'from'}
                        workingHours={fields.getAll()}
                        validate={[]}
                    />
                    <span>עד-</span>
                    <Field
                        name={`${timeRange}.to`}
                        component={HoursPicker}
                        day={day}
                        index={index}
                        fromOrTo={'to'}
                        workingHours={fields.getAll()}
                        validate={[]}
                    />
                </div>
            ))}
            <div className="range-button add" onClick={() => addTimeRange({fields, day})}><AddIcon/></div>
        </>
    )

    return (
        <div className="working-hours">
            {Object.entries(workingHours).map(([day, hours]) => (
                <div name={day} className="day-wrapper" key={day}>
                    <div className="day-title">{days[day]}</div>
                    <FieldArray
                        name={`${day}`}
                        className="hours-wrapper"
                        component={RangeHours}
                        day={day}
                    />
                </div>
            ))}
            <input type="hidden" value={workingHours}/>
        </div>
    );
};

WorkingHoursForm.propTypes = {

};

export default WorkingHoursForm;
