import React, {PureComponent, useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from "moment";
import AddIcon from 'mdi-react/AddIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import {localize} from "../../../../../../../../services/translation/localization";
import {Field, FieldArray, FormSection} from "redux-form";
import SmartService from "../../../../SmartWrapperService";
import InputField from "../InputField/InputField";
import CheckBoxField from "../CheckBoxField/CheckBoxField";
import MultiSelect from "../MultiSelect/MultiSelect";
import Select from "../Select/Select";

const ProvidedServicesForm = ({fields, value, initialValues, options, formFields}) => {
    const [allServices, setAllServices] = useState([])
    const getAllServices = async () => {
        let {data} = await SmartService.get(`services`, {});
        setAllServices(data);
    }

    useEffect(() => {
        getAllServices();
    }, []);

    const mapFieldsToComponents = {
        text: {component: InputField, type: "text"},
        number: {component: InputField, type: "number"},
        select: {component: Select, type: "select"},
        multiSelect: {component: MultiSelect, type: "multiSelect"},
    }

    // const [providedServices, setProvidedServices] = useState(value ? [...value] : []);

    const addService = ({service}) => {
        const serviceData = {
            service: service._id,
            additionalData: {...service}
        }
        fields.push(serviceData);
        // setProvidedServices([...providedServices, serviceData]);
    }

    const deleteService = ({service}) => {
        const providedServices = fields.getAll();
        const index = providedServices.findIndex(s => s.service === service._id);
        fields.remove(index);
        let updatedServices = [...providedServices];
        updatedServices.splice(index, 1);
        // setProvidedServices(updatedServices);
    }

    const handleChange = ({service, field, value}) => {
        const providedServices = fields.getAll();
        const index = providedServices.findIndex(s => s.service === service._id);
        fields.splice(index, 1, {
            ...providedServices[index],
            additionalData: {...providedServices[index].additionalData, [field]: value}
        })
    }

    const AdditionalField = ({service, name, component, type, additionalProps = {}}) => (
        <div className="form__form-group">
            <span
                className="form__form-group-label">{localize(null, `pages.services.${name}`)}</span>
            <div className="form__form-group-field">
                <Field
                    name={name}
                    component={component}
                    placeholder={localize(null, `pages.services.${name}`)}
                    props={{
                        value: service.additionalData[name],
                        handleChange: (value) => handleChange({service, field: name, value: value}),
                        options,
                        ...additionalProps
                    }}
                    type={type}
                    validate={[]}
                />
            </div>
        </div>
    )

    const AdditionalFields = ({service, name, index, fields}) => {
        return (
            // service.additionalData ?
            <FormSection name={`${name}.additionalData`} className={"hours-wrapper"}>
                <div className="range-button delete" onClick={() => deleteService({service})}><DeleteIcon/></div>
                {/*<AdditionalField service={service} name={`cost`} component={InputField}/>*/}
                {/*<AdditionalField service={service} name={`appointmentDuration`} component={InputField}/>*/}
                {/*<AdditionalField service={service} name={`paymentOptions`} component={MultiSelect} type={"multiSelect"}/>*/}
                {formFields.map((field, index) => {
                    const fieldData = mapFieldsToComponents[field.fieldType];
                    return <AdditionalField key={index} service={service} name={field.fieldName} {...fieldData} additionalProps={field.fieldProps} />
                })}
            </FormSection>
            // : <div className="range-button add" onClick={() => addService({service})}><AddIcon/></div>
        )
    }

    return (
        <div className="working-hours">
            {allServices.map((service, index) => {
                let providedService;
                let serviceIndex = -1;
                if (fields.length > 0) {
                    const providedServices = fields.getAll();
                    serviceIndex = providedServices.findIndex(s => typeof s.service === 'object' ? s.service._id === service._id : s.service === service._id);
                    if (serviceIndex > -1) {
                        providedService = fields.get(serviceIndex)
                    }
                }
                service = {...service, additionalData: providedService ? providedService.additionalData : null};
                return (
                    <div className="day-wrapper" key={index}>
                        <div className="day-title">{service.label}</div>
                        {/*<FieldArray*/}
                        {/*    name={`${service._id}`}*/}
                        {/*    className="hours-wrapper"*/}
                        {/*    component={AdditionalFields}*/}
                        {/*    service={service}*/}
                        {/*/>*/}
                        {/*<AdditionalFields service={service}/>*/}
                        {serviceIndex > -1 ? fields.map((name, index, fields) => {
                            return index === serviceIndex ? <AdditionalFields service={service} name={name}
                                                  index={index} fields={fields}/> : null
                            }) :
                                <div className="range-button add" onClick={() => addService({service})}><AddIcon/></div>
                        }
                    </div>
                )
            })}
            {/*<input type="hidden" value={providedServices}/>*/}
        </div>
    );
};

ProvidedServicesForm.propTypes = {};

export default ProvidedServicesForm;
