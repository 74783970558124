import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

const useStyles = makeStyles({
    root: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
    },
});

export default function MultiSelectTreeView (props) {
    const { input: { onChange, value }, services, paymentOptions, ...rest } = props;
    // const dataTwo = [
    //     {
    //         label: 'טסט',
    //         value: 1,
    //         expanded: true,
    //         children: [
    //             {
    //                 label: 'כרטיס אשראי',
    //                 value: 'creditCard',
    //             },
    //             {
    //                 label: 'מזומן',
    //                 value: 'cash',
    //             },
    //         ],
    //     },
    //     {
    //         label: 'כיוון פרונט',
    //         value: 2,
    //         expanded: true,
    //         children: [
    //             {
    //                 label: 'כרטיס אשראי',
    //                 value: 'creditCard',
    //             },
    //             {
    //                 label: 'מזומן',
    //                 value: 'cash',
    //             },
    //         ],
    //     }
    // ]

    const dataTwo = services.map(s => {
        return {
            label: s.label,
            value: s.id,
            expanded: true,
            children: [...paymentOptions].map(p => ({
                label: p.label,
                value: p.id
            }))
        }
    })

    if (value && value.length > 0) {
        dataTwo.forEach(d => {
            const node = value.find(item => item.service === d.value);
            if (node) {
                if (d.children.length === node.paymentOptions.length) {
                    d.checked = true;
                }
                d.children.forEach(child => child.checked = node.paymentOptions.includes(child.value))
            }
        })
    }
    // const [nodes, setNodes] = useState(dataTwo);

    const assignObjectPaths = (obj, stack, parent) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === "object") {
                node.path = stack ? `${stack}.${k}` : k;
                if (parent && node.label) {
                    // node.label = `${parent.label} - ${node.label}`;
                    node.parent = parent.value;
                }
                assignObjectPaths(node, node.path, node.label ? node : obj);
            }
        });
    };

    assignObjectPaths(dataTwo);

    const updateTree = (obj, currentNode) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === "object") {
                if (node.path === currentNode.path) {
                    node.checked = currentNode.checked;
                }
                updateTree(node, currentNode);
            }
        });
    };

    const parseTree = (selectedNodes) => {
        return selectedNodes.reduce((agg, node) => {
            if (node._depth === 0) {
                if (node.checked) {
                    const children = dataTwo.find(d => d.path === node.path).children;
                    agg.push({service: node.value, paymentOptions: children.map(child => child.value)})
                }
            } else {
                let parent = agg.find(d => d.service === node.parent);
                if (parent) {
                    parent.paymentOptions.push(node.value);
                } else {
                    agg.push({service: node.parent, paymentOptions: [node.value]})
                }
            }
            return agg;
        }, [])
    }

    const onTreeChange = (currentNode, selectedNodes) => {
        console.log('onChange::', currentNode, selectedNodes)
        // let updatedNodes = nodes.map(node => {
        //     if (node.value === currentNode.value) {
        //         node.checked = currentNode.checked;
        //     }
        //     return node;
        // });
        updateTree(dataTwo, currentNode);

        onChange(parseTree(selectedNodes))
        // setNodes(dataTwo);
    }
    const onAction = (node, action) => {
        console.log('onAction::', action, node)
    }
    const onNodeToggle = currentNode => {
        console.log('onNodeToggle::', currentNode)
    }


    return (
        // <TreeView
        //     className={classes.root}
        //     defaultCollapseIcon={<ExpandMoreIcon />}
        //     defaultExpandIcon={<ChevronRightIcon />}
        //     multiSelect
        //     onNodeSelect={((event, value) => setSelectedNodes([...selectedNodes, ...value]))}
        // >
        //     <TreeItem nodeId="1" label="Applications">
        //         <TreeItem nodeId="2" label="Calendar" />
        //         <TreeItem nodeId="3" label="Chrome" />
        //         <TreeItem nodeId="4" label="Webstorm" />
        //     </TreeItem>
        //     <TreeItem nodeId="5" label="Documents">
        //         <TreeItem nodeId="6" label="Material-UI">
        //             <TreeItem nodeId="7" label="src">
        //                 <TreeItem nodeId="8" label="index.js" />
        //                 <TreeItem nodeId="9" label="tree-view.js" />
        //             </TreeItem>
        //         </TreeItem>
        //     </TreeItem>
        // </TreeView>

        <DropdownTreeSelect lassName="bootstrap-demo" data={dataTwo} onChange={onTreeChange} onAction={onAction} onNodeToggle={onNodeToggle}
                            inlineSearchInput showPartiallySelected keepOpenOnSelect keepTreeOnSearch texts={{placeholder: " "}}/>
    );
}
