import React from 'react'
import moment, {invalid} from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import he from 'date-fns/locale/he';

registerLocale('he', he);
const DatePickerField = ({showTimeSelect, disabled, dateFormat, timeParams = {}, includeDates, minDate, maxDate, onBlur, getAvailableDays, input, type, label, placeholder, labelIcon, required, containerClass = '', meta: {touched, error}}) => {
    const errorClass = touched && error ? 'error-color' : '';
    const containerClasses = ['input-group', containerClass, errorClass];

    const {minTime, maxTime, includeTimes, timeIntervals} = timeParams;

    const isWorkingDay = date => {
        // Filter out only Saturday
        // const day = date.getDay();
        // return day !== 6;

        // Filter out based on branch config
        return Array.isArray(includeDates) ? includeDates.map(d => d.getTime()).includes(date.getTime()) : true;
    };

    const CustomInput = (props) => {
        return (
            <input
                className={"input-field datepicker"}
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder={placeholder}
            />
        )
    }

    return (
        <div className={containerClasses.join(' ')}>
            <div className="label-input">
                <div className="label-wrapper">
                    {labelIcon && <img src={labelIcon} alt=""/>}
                    <label>{required ? '*' : ''}{label}</label>
                </div>
                <DatePicker
                    customInput={<CustomInput />}
                    locale='he'
                    dateFormat={dateFormat || "dd/MM/yyyy | HH:mm"}
                    selected={input.value ? Date.parse(input.value) : null}
                    onChange={input.onChange}
                    disabled={disabled}
                    className="input-field datepicker"
                    // showMonthDropdown
                    // showYearDropdown
                    openToDate={minTime}
                    // includeDates={includeDates}
                    minDate={minDate}
                    maxDate={maxDate}
                    filterDate={isWorkingDay}
                    onMonthChange={getAvailableDays ? (event) => getAvailableDays(new Date(event)) : () => {}}
                    onCalendarOpen={getAvailableDays ? () => getAvailableDays(input.date ? Date.parse(input.value) : new Date()) : () => {}}
                    showTimeSelect={showTimeSelect}
                    minTime={minTime}
                    maxTime={maxTime}
                    includeTimes={includeTimes}
                    timeIntervals={1 || timeIntervals}
                    timeCaption="שעה"
                    dropdownMode="select"
                    placeholderText={placeholder}
                    // onBlur={input.onBlur}
                    // onFocus={e => e.target.blur()}
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true,
                        },
                    }}
                />
            </div>
            {
                touched && error && <div className='input-error-message'>{error}</div>
            }
        </div>
    )
};
export default DatePickerField;
