import action from "../middleware";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

const setCurrentUser = (currentUser) => {
    return action(async dispatch => {
        dispatch({
            type: SET_CURRENT_USER,
            payload: currentUser
        });
    });
};

export {
    setCurrentUser
}
