import React, {Component} from "react";
import {Button, ButtonToolbar, Card, CardBody, Col} from "reactstrap";
import SmartFormMode from "./components/smartForm/SmartFormMode";
import SmartTable from "./components/smartTable/SmartTable";
import SmartForm from "./components/smartForm/SmartForm";
import pick from 'lodash/pick';
import {localize} from "../../../../services/translation/localization";
import Permissions from '../../../../services/permissions'

import {showError} from '../../../../redux/actions/messageActions'

import {Modal} from 'reactstrap';
import './styles/app.scss'
import SmartService from './SmartWrapperService';
import connect from "react-redux/es/connect/connect";


const scrollToBottom = () => {
    setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, 300)
};

class SmartWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            smartFormMode: null,
            selectedRowData: null,
            shouldReload: false,
        };

        this.handleShowView = this.handleShowView.bind(this);
        this.handleShowNew = this.handleShowNew.bind(this);
        this.handleShowEdit = this.handleShowEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleFormCancel = this.handleFormCancel.bind(this);

    }

    async componentDidMount() {
        await this.getResource();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.refreshSwitch !== this.props.refreshSwitch) {
            await this.getResource();
            this.setState({shouldReload: !this.state.shouldReload})
        }
    }

    async getResource() {
        let {tableConfig, mockData, user} = this.props;
        if (!tableConfig)
            return;
        try {
            tableConfig = Permissions.enforcePermissions(tableConfig);

            const {model, restOptions} = tableConfig;
            let options = {};

            if (restOptions.pagination) {
                options.paging = {
                    pageSize: restOptions.pageSize || 10,
                    page: 0
                }
            }
            if (restOptions.filter) {
                options.filter = restOptions.filter;
            }

            let response = await SmartService.get(`${tableConfig.model}`, options);
            let handlers = tableConfig.handlers && tableConfig.handlers.POST_FETCH ? [tableConfig.handlers.POST_FETCH] : [];

            if (!response || response.message == "Generic Error") {
                alert("Error: server did not return data")
            } else if (handlers.length > 0) { //execute post fetch
                response.data = response.data.map(async doc => {
                    doc = await handlers.reduce((p, handler) => {
                        return p.then(() => {
                            return handler(doc, user, tableConfig);
                        });
                    }, Promise.resolve());
                })

            }
            let pages = restOptions.pageSize ? response.total / restOptions.pageSize : null;
            pages = Math.ceil(pages);
            this.setState({data: response.data, pages});
        } catch (err) {
            this.props.showError(err.message);
        }
    }

    handleShowView(entity) {
        const {children, ...selectedRowData} = entity;

        if (this.props.rowItemClicked) {
            this.props.rowItemClicked(selectedRowData);
            return;
        }

        else if (this.props.viewButtonCallback) {
            this.props.viewButtonCallback(selectedRowData);

        }
        else if (!this.props.disableView) {
            this.setState({selectedRowData, smartFormMode: SmartFormMode.view, smartFormModeName: "view"});
        }
    };

    handleShowNew() {
        this.setState({selectedRowData: null, smartFormMode: SmartFormMode.new, smartFormModeName: "new"});
        // scrollToBottom();
    };

    handleShowEdit(entity) {
        const {children, ...selectedRowData} = entity;
        this.setState({selectedRowData, smartFormMode: SmartFormMode.edit, smartFormModeName: "edit"});

        // scrollToBottom();
    };

    async handleSave(form) {
        const {tableConfig, user} = this.props;
        const {selectedRowData, smartFormMode} = this.state;
        let id = selectedRowData && selectedRowData[tableConfig.IDAccessor];
        //Clear null items from doc
        let doc = Object.entries(form).filter(([key, value]) => typeof value !== 'undefined' &&  value !== null).reduce((agg, cur) => {
            agg[cur[0]] = cur[1];
            return agg;
        }, {});

        let handlers = tableConfig.handlers ? (smartFormMode == SmartFormMode.new ? tableConfig.handlers.PRE_CREATE
            : tableConfig.handlers.PRE_SAVE) : [];
        if (handlers) { //execute pre save/create handlers
            if (!Array.isArray(handlers)) handlers = [handlers];
            doc = await handlers.reduce((p, handler) => {
                return p.then(() => {
                    return handler(doc, smartFormMode, user, tableConfig);
                });
            }, Promise.resolve(doc));
        }
        await this.sendRequest(id, doc);
    }

    handleExportTable = async (filters, fileName = "Excel Report") => {
        let {tableConfig} = this.props;
        if (!tableConfig)
            return;
        try {
            // let response = await SmartService.get(`utils/exportToExcel`, filters);
            let response = await SmartService.get(`${tableConfig.model}/exportToExcel`, filters);
            const blob = new Blob([new Uint8Array(response.data)]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.xlsx`;
            a.click();
        } catch (err) {
            this.props.showError(err.message);
        }
    }

    sendRequest = async (id, doc) => {
        let {tableConfig} = this.props;
        try {
            let response;
            if (id) {//is this an existing document?
                response = await SmartService.patch(`${tableConfig.endpoints['edit'] || tableConfig.model}/${id}`, doc);
            }
            else { //it's a new document
                response = await SmartService.post(`${tableConfig.endpoints['create'] || tableConfig.model}`, doc);
            }
            if (response.error) {
                throw response.error;
            }
            await this.setState({smartFormMode: null, shouldReload: !this.state.shouldReload});

        }
        catch (err) {
            console.log(err);
            console.log('server error');
            this.props.showError(err.message);
            // this.setState({smartFormMode: null});
        }
    }

    handleFormCancel() {
        this.setState({selectedRowData: null, smartFormMode: null})
    };

    render() {
        const {data, smartFormMode, smartFormModeName} = this.state;
        const {tableConfig, colSize} = this.props;

        let initialValues = this.state.selectedRowData;
        // initialize form only with allowed fields to edit
        // if (smartFormMode === SmartFormMode.edit && initialValues) {
        //     const allowedFields = Object.values(tableConfig.fields)
        //         .filter(f => f.)
        //         .map(f => f.accessor);
        //
        //     initialValues = pick(initialValues, allowedFields);
        // }

        return tableConfig && data ? (
            <Col md={colSize ? colSize : 12} className="resource">
                <Card>
                    <CardBody>
                        <div>
                            <SmartTable
                                actions={this.props.actions}
                                ref={ref => this.smartTable = ref}
                                viewEntity={this.handleShowView}
                                newEntity={this.handleShowNew}
                                editEntity={this.handleShowEdit}
                                exportTable={this.handleExportTable}
                                tableConfig={tableConfig}
                                filterable={this.props.filterable}
                                handleDelete={this.props.handleDelete}
                                reverseAdd={this.props.reverseAdd}
                                defaultPageSize={this.props.defaultPageSize ? this.props.defaultPageSize : 10}
                                data={data}
                                handlers={this.props.tableConfig.handlers}
                                disableColumnSelector={this.props.disableColumnSelector}
                                disableFreeSearch={this.props.disableFreeSearch}
                                allowAdd={this.props.allowAdd}
                                allowExport={this.props.allowExport}
                                enableSelectedRowHighlight={this.props.enableSelectedRowHighlight}
                                shouldReload={this.state.shouldReload}
                                serverMode={true}
                            />
                            <Modal contentClassName="sf-modal" isOpen={!!smartFormMode} toggle={this.handleFormCancel}>
                                <SmartForm
                                    initialValues={initialValues}
                                    mode={smartFormMode}
                                    fields={tableConfig.fields}
                                    title={localize(null, "smartForm.modes." + smartFormModeName) + " " + tableConfig.modelDisplayName}

                                    onCancel={this.handleFormCancel}
                                    onSubmit={this.handleSave}/>
                            </Modal>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        ) : null
    }
}


const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = {showError};

export default connect(mapStateToProps, mapDispatchToProps)(SmartWrapper);

