import React from 'react';
import {Modal} from "reactstrap";
import TextLocalize from "../shared/components/TextLocalize/TextLocalize";
import {localize} from "../services/translation/localization";
// import Enums from '../services/enums';
import AddEditAppointmentForm from "./AddEditAppointmentForm";
import {normalizeCar} from "../services/validation";
import {createAppointment, updateAppointment} from "../services/appointment";
import {updateCar} from "../services/car";

const AppointmentModal = ({isOpen, appointment, onClose}) => {

    const loadUserData = () => {
        const {_id, user, car, branch, service, appointmentDate} = appointment;
        return {
            _id,
            user: {...user, label: user.fullname, value: user._id},
            car: {...car, label: normalizeCar(car.number), value: car._id},
            branch: {...branch, label: branch.displayName, value: branch._id},
            service: {...service, label: service.label, value: service._id},
            appointmentDate: new Date(appointmentDate)
        }
    }

    const handleSave = async (formValues) => {
        const {_id, user, car, branch, service, appointmentDate, address, group, engine, tires, isPaid, arrived, appointmentStartingDate} = formValues;
        console.log(formValues)
        const appointmentData = {
            user: user.value,
            car: car.value,
            branch: branch.value,
            service: service.value,
            appointmentDate: appointmentDate,
            // address: service.name === 'test' ? address.value : null,
            status: 'approved',
            isPaid,
            arrived,
            appointmentStartingDate
        }
        if (service.name === 'buy') {
            if (group && engine && tires) {
                await updateCar(car, {group, engine, tires})
            }
        }
        if (_id) {
            await updateAppointment(_id, appointmentData)
        } else {
            await createAppointment(appointmentData)
        }
        onClose()
    }

    return (
        <Modal contentClassName="sf-modal" isOpen={isOpen}>
            <div className="task-types-modal">
                <h3><TextLocalize text={appointment ? 'pages.titles.editAppointmentModal' : 'pages.titles.addAppointmentModal'}/></h3>
                <div className="task-types">
                    <AddEditAppointmentForm initialValues={appointment ? loadUserData() : {}} onClose={onClose} onSubmit={handleSave} />
                </div>
            </div>
        </Modal>
    )
}

export default AppointmentModal
