import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ThemeProps} from '../../shared/prop-types/ReducerProps';
import {Redirect, withRouter} from "react-router-dom";
import {clearMessage} from '../../redux/actions/messageActions';
import {setLanguage} from '../../redux/actions/appActions'
import Translations from '../../services/translation/translation'
import {Button, Container, Modal} from "reactstrap";
import '../../scss/component/modal.scss';
import SmartWrapperService from "../../shared/components/SmartWrapper/src/SmartWrapperService";

const closeButtonStyle = {
    background: 'transparent',
    border: 0,
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 1)'
};

class MainWrapper extends Component {
    static propTypes = {
        theme: ThemeProps.isRequired,
        children: PropTypes.element.isRequired,
    };

    constructor(props) {
        super(props);
        props.setLanguage(Translations.getLanguage('he'));
        SmartWrapperService.setLanguage("he");
        this.state = {};
    }

    async componentDidMount() {
        try {
            const lang = localStorage.getItem("language");
            if (lang === "he" || lang === "en") {
                this.props.setLanguage(Translations.getLanguage(lang));
                SmartWrapperService.setLanguage(lang);
            }
            else {
                this.props.setLanguage(Translations.getLanguage('he'));
                SmartWrapperService.setLanguage("he");
            }
            // const user = await this.props.getStoredUser();
            // if (user) {
            //     this.props.history.push('/');
            // } else {
            //     this.props.history.push('/login');
            // }
            // await this.props.getData();

            // //TODO - role needs to come from server
            // user.Role=1; //1 == admin, 2==agent

            // if (!user || user.error) {
            //     this.props.history.push('/login');
            //     return;
            // }
        }
        catch {
            alert("Error: Enums service is not available")
        }
    }

    render() {

        const {theme, children, language, user} = this.props;

        if (!language) return null;
        return (
            <div className={theme.className}>
                <div className={`wrapper ${language.name === 'he' ? 'rtl' : 'ltr'}`}>
                    {children}
                </div>
                {this.props.message.show ?

                    <Modal
                        isOpen={this.props.message.show}
                        className={`standard-modal`}
                    >
                        <div className="modal__header">

                            <h4 className="bold-text  modal__title">{this.props.message.title || this.props.message.type}</h4>
                            <button className="lnr lnr-cross modal__close-btn"
                                    style={closeButtonStyle} type="button" onClick={this.props.clearMessage}/>
                        </div>
                        <div className="standard-modal-body">
                            <div className={'pause-modal'}>
                                <div className={'description'}>
                                    {`${this.props.message.content}`}
                                </div>
                                <div className={'pause-buttons'}>
                                    <Button onClick={this.props.clearMessage}>אישור</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    : ''}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
    message: state.message

});


export default withRouter(connect(state => ({
    theme: state.theme,
    user: state.auth.user,
    data: state.app.data,
    language: state.app.language,
    message: state.message
}), {
    clearMessage,
    setLanguage
})(MainWrapper));
