import React from "react";
import {connect} from "react-redux";
import {localize, localized} from "../../services/translation/localization";

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';
import moment from "moment";
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";
import config from "../../config";

class StandsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async onCreate(doc) {

    }

    componentDidMount() {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.stands_management'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.stands_management'));
        }
    }

    viewMembershipForm(doc) {
        window.open(`${config.website.url}/#/${doc.branch._id}/${doc._id}/join`);
    }

    render() {
        const {data} = this.props;

        const tableConfig = {
            model: `stands`,
            modelDisplayName: localize(null, 'pages.titles.stand'),
            actions: ['view', 'edit', {callback: this.viewMembershipForm, icon: 'groupAdd', title: 'מועדון לקוחות'}],
            endpoints: {
                create: "stands/create",
                edit: "stands"
            },
            handlers: {
                // "PRE_CREATE": this.onCreate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: false,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "displayName",
                    alias: localize(null, "pages.stands.name"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "branch",
                    alias: localize(null, "pages.stands.branchName"),
                    type: "select",
                    options: data.branches.map((e) => {
                        return {
                            value: e.id,
                            label: e.displayName,
                        }
                    }),
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    showFilter: true,
                    searchable: false
                },
                {
                    accessor: "currentUser.fullname",
                    alias: localize(null, "pages.stands.currentUser"),
                    type: "text",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "currentCar.number",
                    alias: localize(null, "pages.stands.currentCar"),
                    type: "text",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "createdAt",
                    alias: localize(null, "smartTable.fields.created"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                }
            ],
            restOptions: {
                // filter: {role: "CLIENT"},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }

        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.stands_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {tableConfig && <SmartWrapper filterable={false} allowAdd={false} allowExport={true} viewButtonCallback={this.handleViewStand}
                                                  tableConfig={tableConfig}/>}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    data: state.app.data
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(StandsManagement);
