import moment from "moment";
import {localize} from './translation/localization'
import http from "./http";

class UtilService {

    objectHasValue = (obj, value) => Object.values(obj).indexOf(value) > -1;

    getUrlParams(restOptions) {
        if (restOptions) {
            const {
                filters, projection, search, populate, paging, sort, dateRange, languageId, handlers,
            } = restOptions;
            return `?${filters ? `filters=${JSON.stringify(filters)}&` : ''}`
                + `${projection ? `project=${JSON.stringify(projection)}&` : ''}`
                + `${search ? `search=${search}&` : ''}`
                + `${populate ? `populate=${JSON.stringify(populate)}&` : ''}`
                + `${paging ? `page=${Number(paging.page) + 1}&pageSize=${paging.pageSize}&` : ''}`
                + `${sort ? `sort=${sort.field}&order=${sort.order}&` : ''}`
                + `${languageId ? `languageId=${languageId}` : ''}`
                + `${dateRange ? `dateRange=${JSON.stringify(dateRange)}&` : ''}`
                + `${handlers ? `handlers=${JSON.stringify(handlers)}&` : ''}`;
        }
        return '';
    }

    dataToSelectOptions(dataObject) {
        return Object.values(dataObject).map(gender => ({label: gender.displayName, value: gender._id}))
    }

    getFieldByPath = (object, path) => {
        var a = path.split(/[\[\]\.]/).filter(i => i);
        var o = object;
        for (var i = 0; i < a.length - 1; i++) {
            var n = a[i];
            if (n in o) {
                o = o[n];
            } else {
                o[n] = {};
                o = o[n];
            }
        }
        return o[a[a.length - 1]];
    }

    getFieldValueByType(field, value) {
        switch (field.type) {
            case 'select':
            case 'enum':
                value = (
                    field.options && field.options.find(
                        option => option.value === value
                    ) || {}
                ).label;
                break;
            case 'multiSelect':
                value = value
                    ? value.map(
                        v =>
                            (field.options && field.options.find(option => option.value === v) || {})
                                .label
                    )
                    : [];
                value = value.join(", ");
                break;
            case 'date':
                value = value ? moment(value).format("DD/MM/YYYY") : null;
                break;
            case 'dateTime':
                value = value ? moment(value).format("DD/MM/YYYY HH:mm") : null;
                break;
            case 'text':
                break;
            case 'number':
                break;
            case 'boolean':
                value = localize(null, value ? 'smartForm.trueValue' : 'smartForm.falseValue');
                break;

        }
        return value;
    }

   getDataFromServer = async () => {
        // return await http.get('api/data');
        const branches = await http.get('branches', {$filter: {field: 'status', text: 'active'}});
        const services = await http.get('services');
        return {branches: branches.data, services: services.data};
    }

    dateParse = (value) => value ? moment(value).valueOf() : null;

    enumToSelectOptions = (enumData) => enumData.map(ed => ({label: ed.display, value: ed.value}))
    selectFormat = (options) => ((stored) => options.filter(option => option.value === stored));
    selectParse = (item) => item.value;
}

export default new UtilService();
