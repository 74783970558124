import json from './languages';

class Translation {
  supportedLanguages() {
    return json.languages;
  }

  getLanguage(name) {
    return json.languages.find(lang => lang.name === name);
  }

  getCurrentLanguage() {
    return this.language;
  }

  setLanguage(name) {

    this.language = json.languages.find(lang => lang.name === name);
    document.body.setAttribute('dir', this.language.dir)
  }

}
export default new Translation();
