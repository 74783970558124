import Http from './http'

export const getCarBrandAutocomplete = (searchToken, brands) => {
    return brands.filter(brand => brand.display.includes(searchToken)).map(b => ({label: b.display, value: b.display, models: b.models}));
};
export const getCarModelAutocomplete = (searchToken, maker, brands) => {
    // const maker = brands.find(b => b.label === brand);
    return Object.values(maker.models).filter(model => model.includes(searchToken)).map(m => ({label: m, value: m}))
};

export const createNewCar = async (carDetails) => {
    return Http.post('cars/create', carDetails);
}

export const updateCar = async (carId, carDetails) => {
    return Http.put(`cars/${carId}`, carDetails);
}
