import React from "react";
import {connect} from "react-redux";

// services
import {localize, localized} from "../../services/translation/localization";
import EnumsService from "../../services/enums";

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';

// components
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";
import {email, required} from "../../services/validation";


class BranchesManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskTypesModalBranch: null,
        };

    }

    async onCreate(doc) {
        if (doc.normalWorkingHours) {
            if (typeof doc.normalWorkingHours === 'object') {
                doc.normalWorkingHours = Object.values(doc.normalWorkingHours);
            }
            doc.normalWorkingHours = doc.normalWorkingHours.reduce((agg, cur, index) => {
                if(cur.length > 0) {
                    agg[index] = cur;
                }
                return agg;
            }, {})
        }
        return doc;
    }

    componentDidMount() {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.branches_management'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.branches_management'));
        }
    }

    render() {
        const {enums, services, user} = this.props;
        const roles = EnumsService.getEnumAsObject('permissions');
        // const paymentOptions = EnumsService.getEnumAsObject('paymentOptions');

        const tableConfig = {
            model: `branches`,
            modelDisplayName: localize(null, 'pages.titles.branch'),
            actions: ['view', user.role === roles.admin.value ? 'edit' : ''
                /*, {callback: (data) => this.setState({branch: data}), element: taskTypesAction}*/],
            endpoints: {
                create: "branches/create",
                edit: "branches"
            },
            handlers: {
                "PRE_CREATE": this.onCreate,
                "PRE_SAVE": this.onCreate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    showFilter: false
                },
                {
                    accessor: "displayName",
                    alias: localize(null, "pages.branches.name"),
                    type: "text",
                    displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "email",
                    alias: localize(null, "smartTable.fields.email"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: false,
                    filterable: false,
                    validators: [required, email]
                },
                {
                    accessor: "status",
                    alias: localize(null, "smartTable.fields.status"),
                    type: "select",
                    options: enums.branchStatus.map((e) => {
                        return {
                            value: e.value,
                            label: e.display,
                        }
                    }),
                    displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                // {
                //     accessor: "servicesAndPaymentOptions",
                //     alias: localize(null, "smartTable.fields.services"),
                //     type: "treeSelect",
                //     services: services.map(service => ({label: service.label, id: service._id})),
                //     paymentOptions: enums.paymentOptions.map(e => ({label: e.display, id: e.value})),
                //     displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                //     allowTableView: false,
                //     show: true,
                //     filterable: false
                // },
                // {
                //     accessor: "services",
                //     alias: localize(null, "smartTable.fields.services"),
                //     type: "multiSelect",
                //     options: services.map(service => ({label: service.label, value: service._id})),
                //     displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                //     allowTableView: true,
                //     show: true,
                //     filterable: false
                // },
                // {
                //     accessor: "paymentOptions",
                //     alias: localize(null, "smartTable.fields.paymentOptions"),
                //     type: "multiSelect",
                //     options: enums.paymentOptions.map(e => ({label: e.display, value: e.value})),
                //     displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                //     allowTableView: true,
                //     show: false,
                //     filterable: false
                // },
                {
                    accessor: "vacations",
                    alias: localize(null, "smartTable.fields.vacations"),
                    type: "multiDatesPicker",
                    displayModes: [SmartFormMode.new, SmartFormMode.edit],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    // fieldStyles: ["input-field-wide"]
                },
                {
                    accessor: "providedServices",
                    alias: localize(null, "smartTable.fields.services"),
                    type: "providedServices",
                    formFieldType: 'fieldArray',
                    formFields: [
                        {
                            fieldName: 'paymentOptions',
                            fieldType: 'multiSelect',
                            fieldProps: {options: enums.paymentOptions.map(e => ({label: e.display, value: e.value}))}
                        },
                        // {fieldName: 'cost', fieldType: 'number'},
                        // {fieldName: 'appointmentDuration', fieldType: 'number'}
                    ],
                    options: enums.paymentOptions.map(e => ({label: e.display, value: e.value})),
                    displayModes: [SmartFormMode.new, SmartFormMode.edit],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    fieldStyles: ["input-field-wide"]
                },
                {
                    accessor: "normalWorkingHours",
                    alias: localize(null, "smartTable.fields.workingHours"),
                    type: "workingHours",
                    formFieldType: 'formSection',
                    displayModes: [SmartFormMode.new, SmartFormMode.edit],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    fieldStyles: ["input-field-wide"]
                },
                {
                    accessor: "createdAt",
                    alias: localize(null, "smartTable.fields.created"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "officeGuyItemID",
                    alias: localize(null, "smartTable.fields.officeGuyItemID"),
                    type: "number",
                    displayModes: [SmartFormMode.new, SmartFormMode.edit],
                    allowTableView: false,
                    show: false,
                    filterable: false
                }
            ],
            restOptions: {
                // filter: {role: "CLIENT"},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }

        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.branches_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {tableConfig && <SmartWrapper filterable={false}
                                                  allowAdd={user.role === roles.admin.value}
                                                  allowExport={true}
                                                  viewButtonCallback={this.handleViewBranch}
                                                  tableConfig={tableConfig}/>}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    enums: state.app.enums,
    services: state.app.data.services,
    user: state.auth.user,
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchesManagement);
