import enums from "../../services/enums";
import utilService from "../../services/util";
import action from "../middleware";
import Translations from "../../services/translation/translation";

export const SET_TITLE = 'SET_TITLE';
export const SET_ENUMS = 'SET_ENUMS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_DATA = 'GET_DATA';
export const SET_TABLE_CONFIG = "SET_TABLE_CONFIG";

export function setTitle(title) {
  return {
    type: SET_TITLE,
    payload: title
  };
}

export function setEnums() {
  return action(async dispatch => {
    await enums.init();
    dispatch({
      type: SET_ENUMS,
      payload: enums.getAllEnums()
    });
  })
}

export function setLanguage(lang) {
  Translations.setLanguage(lang.name);
  return action(async dispatch => {
    dispatch({
      type: SET_LANGUAGE,
      payload: lang
    });
  });
}

export function getData() {
  return action(async dispatch => {
    let data = await utilService.getDataFromServer();
    dispatch({
      type: GET_DATA,
      payload: data
    });
  });
}

export function setTableConfig(tableConfig) {
  return action(async dispatch => {
    await dispatch({
      type: SET_TABLE_CONFIG,
      payload: tableConfig
    });
  });
};
