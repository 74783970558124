const baseURL = 'https://api.myspetz.com';

export function xWWWFormFormat(obj) {
    let objArray = Object.entries(obj)
    return objArray.reduce((acc, item) => acc + '&' + item[0] + '=' + item[1], '').slice(1)
}

export async function connect (path, options = {}, noJSON) {

    let connectionPath;
    if (path.indexOf('http') > -1) {
        connectionPath = path;
    } else {
        connectionPath = `${baseURL + `/` + path}`
    }

    let res = await fetch(connectionPath, connectionOptions(options))

    if (noJSON) return res;

    return res.json()
}

export function connectionOptions({ body, method, headersToAdd, type }) {

    let headers = new Headers()
    headers.append("Content-Type", "application/json");

    if (headersToAdd) {
        headersToAdd.forEach((header) => (headers.append(header[0], header[1])))
    }

    if (!type) type = 'GET';

    let options = {};

    if (type === 'POST') {
        options = {
            method: method ? method : 'POST',
            body: JSON.stringify(body),
            headers
        }
    } else if (type === 'PATCH') {
        options = {
            method: method ? method : 'PATCH',
            body: JSON.stringify(body),
            headers
        }
    } else if (type === 'DELETE') {
        options = {
            method: 'DELETE'
        }
    } else if (type === 'GET') {
        options = {
            method: 'GET'
        }
    }

    return options
}

export default {
    connect,
    options: connectionOptions,
    xWWWFormFormat
}
