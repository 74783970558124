import Storage from "../../services/storage";
import action from "../middleware";
import http from "../../services/http";
import { SET_ERROR } from "./messageActions";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

const AUTH_ENDPOINT = "users/authenticateAdmin";
const GET_USER = "users/current";

const getStoredUser = () => {
  return action(async dispatch => {
    const token = await Storage.get('token');
    // let user = Storage.get("user");
    if (token) {
      let res = await http.get(GET_USER, {token});
      if (res.error) {
        dispatch({
          type: SET_ERROR,
          message: res.error.message
        });
        return null;
      }
      http.addHeader("Authorization", `Bearer ${token}`);

      dispatch({
        type: LOGIN_USER,
        payload: { user: res }
      });
      return res;
    } else {
      return null;
    }
  });
}

const loginUser = (userName, password) => {
  return action(async dispatch => {
    http.clearHeaders();

    let res = await http.post(AUTH_ENDPOINT, {
      email: userName,
      password: password
    });
    if (res.error) {
      dispatch({
        type: SET_ERROR,
        message: res.error.message
      });
      return;
    }

    let user = { ...res, token: res.token };

    // Storage.set("user", user);
    await Storage.set("token", user.token);
    http.addHeader("Authorization", `Bearer ${user.token}`);

    dispatch({
      type: LOGIN_USER,
      payload: { user: user }
    });
    return user;
  });
}

const logoutUser = () => {
  return action(async dispatch => {
    http.clearHeaders();
    Storage.set("token", null);
    dispatch({type: LOGOUT_USER});
  })
};

const resetPassword = (email) => {
  return action(async dispatch => {
    return http.post(`users/resetPassword`, {email});
  });
}

const updatePassword = (oldPassword, newPassword) => {
  return action(async dispatch => {
    return http.post(`users/updatePassword`, {oldPassword, newPassword});
  });
}

const getAllUsers = () => {
  return action(async dispatch => {
    return http.get(`users`);
  });
}

export {
  getStoredUser,
  loginUser,
  logoutUser,
  resetPassword,
  updatePassword,
  getAllUsers
}
