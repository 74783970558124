import React from 'react'

const InputField = ({input, style, type, label, placeholder, labelIcon, required, disabled, dataOg, containerClass = '', meta: {touched, error}}) => {
    const errorClass = touched && error ? 'error-color' : '';
    const hiddenClass = type === 'hidden' ? 'hidden' : '';
    const containerClasses = ['input-group', containerClass, errorClass, hiddenClass];
    return (
        <div className={containerClasses.join(' ')}>
            <div className="label-input">
                {label &&
                <div className="label-wrapper">
                    {labelIcon && <img src={labelIcon} alt=""/>}
                    <label htmlFor={input.name}>{required ? '*' : ''}{label}</label>
                </div>
                }
                <input className='input-field' {...input} data-og={dataOg} style={style} type={type} placeholder={placeholder} disabled={disabled}/>
            </div>
            {touched && error && <div className='input-error-message'>{error}</div>}
        </div>
    )
};
export default InputField;
