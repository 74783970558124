import {
  SET_TITLE,
  SET_ENUMS,
  SET_LANGUAGE,
  GET_DATA,
  SET_TABLE_CONFIG
} from '../actions/appActions';

const initialState = {
  title: '',
  language: 'he',
  data: null,
  tableConfig: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TITLE:
      return { ...state, title: action.payload };
     case SET_ENUMS:
      return { ...state, enums: action.payload };
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case GET_DATA:
      return { ...state, data: action.payload };
    case SET_TABLE_CONFIG:
      return { ...state, tableConfig: action.payload };
    default:
      return state;
  }
}
