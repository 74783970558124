import React from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {localize, localized} from "../../services/translation/localization";
import {required, email} from '../../services/validation'

// services
import {updateUser} from '../../services/user'

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';
import moment from "moment";
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";
import {FaTasks} from "react-icons/fa";
import {getCompaniesFromServer} from "../../services/companies";
import ConfirmationModal from "../../shared/Modal/ConfirmationModal";
import {updateAppointment} from "../../services/appointment";

class WorkersCommitteesManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskTypesModalUser: null,
        };
    }

    preCreate = async (doc) => {
        doc = Object.keys(doc)
            .filter(field => this.tableConfig.fields.find(f => f.accessor === field && f.displayModes.includes(SmartFormMode.new)))
            .reduce((obj, key) => {
                obj[key] = doc[key];
                return obj;
            }, {});
        // doc.permissions = [doc.role];
        return doc;
    }

    preUpdate = async (doc) => {
        doc = Object.keys(doc)
            .filter(field => this.state.tableConfig.fields.find(f => f.accessor === field && f.displayModes.includes(SmartFormMode.edit)))
            .reduce((obj, key) => {
                obj[key] = doc[key];
                return obj;
            }, {});
        // doc.permissions = [doc.role];
        return doc;
    }

    async componentDidMount() {
        let {language, enums} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.clients_management'));
        const companies = await getCompaniesFromServer();
        const tableConfig = {
            model: `users`,
            modelDisplayName: localize(null, 'pages.titles.client'),
            excelFileName: "דוח וועדי עובדים",
            actions: ['view', 'edit',
                {
                    callback: (data) => this.handleApproveClient(data),
                    icon: 'check',
                    title: 'אשר לקוח',
                    hidden: (data) => data.status === 'active',
                },
                {
                    callback: (data) => this.setState({blockModal: true, client: data}),
                    icon: 'close',
                    title: 'חסום לקוח',
                    hidden: (data) => data.status !== 'active',
                }
            ],
            endpoints: {
                create: "users/create",
                edit: "users"
            },
            handlers: {
                "PRE_CREATE": this.preCreate,
                "PRE_SAVE": this.preUpdate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false
                },
                {
                    accessor: "fullname",
                    alias: localize(null, "pages.login.name"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    displayFilter: true,
                    searchable: true
                },
                {
                    accessor: "company",
                    alias: localize(null, "smartTable.fields.companyName"),
                    type: "select",
                    options: companies.data.map((e) => {
                        return {
                            value: e._id,
                            label: e.name,
                        }
                    }),
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: false
                },
                {
                    accessor: "workerNumber",
                    alias: localize(null, "smartTable.fields.workerNumber"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    validators: [],
                    searchable: true
                },
                {
                    accessor: "status",
                    alias: localize(null, "smartTable.fields.status"),
                    type: "select",
                    options: enums.userStatus.map((e) => {
                        return {
                            value: e.value,
                            label: e.display,
                        }
                    }),
                    displayModes: [SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                },
                {
                    accessor: "createdAt",
                    alias: localize(null, "smartTable.fields.created"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "fromPilot",
                    alias: localize(null, "smartTable.fields.fromPilot"),
                    type: "boolean",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: true
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false
                },
                {
                    accessor: "lastLoginTime",
                    alias: localize(null, "smartTable.fields.lastLoginTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "email",
                    alias: localize(null, "smartTable.fields.email"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    validators: [required, email],
                    searchable: true
                },
                {
                    accessor: "phone",
                    alias: localize(null, "smartTable.fields.phone"),
                    type: "text",
                    minWidth: 100,
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    validators: [required],
                    searchable: true
                },
                {
                    accessor: "carsAsString",
                    alias: localize(null, "smartTable.fields.cars"),
                    type: "text",
                    minWidth: 300,
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false,
                    validators: [required]
                }

            ],
            restOptions: {
                filter: {role: "client", company: {$ne: null}},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }
        this.setState({tableConfig})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.clients_management'));
        }
    }

    closeBlockModal = () => {
        this.setState({blockModal: false, appointment: null})
    }

    handleBlockClient = async () => {
        let { client } = this.state;
        await updateUser(client._id, {status: 'blocked'})
        this.closeBlockModal();
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    handleApproveClient = async (client) => {
        await updateUser(client._id, {status: 'active'})
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    render() {
        const { tableConfig, refreshSwitch, blockModal } = this.state;
        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.clients_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {tableConfig && <SmartWrapper filterable={true} allowAdd={false} allowExport={true}
                                                  refreshSwitch={refreshSwitch}
                                                  viewButtonCallback={this.handleViewUser}
                                                  tableConfig={tableConfig}/>}
                </Row>
                <ConfirmationModal
                    isOpen={blockModal}
                    onConfirm={this.handleBlockClient}
                    onCancel={this.closeBlockModal}
                    title={localize(null, "pages.users.blockModal.title")}
                    description={<p>{localize(null, "pages.users.blockModal.message")}</p>}
                />
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    enums: state.app.enums
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkersCommitteesManagement);
