import http from "./http";

class Enums {

    constructor(){
        this.enums=null;

    }
    async init() {
        this.enums = await http.get(`Enums`);
        let self = this;
        Object.entries(this.enums).forEach(([eName, vals])=> {
            let map = self[eName] = {};
            vals.forEach(({id, name})=>{
                map[name] = id;
            });
        });
    }

    getAllEnums() {
        return this.enums;
    }

    getEnum(key) {
        return this.enums[key];
    }

    getEnumAsObject(key) {
        return this.getEnum(key).reduce((obj, cur) => {
            obj[cur.value] = cur;
            return obj;
        }, {})
    }
}
export default new Enums();
