import http from './http'

export const getAvailableAppointmentsFromServer = async ({date, branch, service}) => {
    return http.get(`appointments/available?date=${date}&branch=${branch}&service=${service}`);
}

export const getWorkingDaysFromServer = async ({date, branch, service}) => {
    let workingDays = await http.get(`appointments/getWorkingDays?date=${date}&branch=${branch}&service=${service}`);
    return workingDays.map(d => (new Date(d)));
}

export const createAppointment = async (appointmentDetails) => {
    return http.post(`appointments/create`, appointmentDetails);
}

export const updateAppointment = async (_id, appointmentDetails) => {
    return http.put(`appointments/${_id}`, appointmentDetails);
}

export const getMyAppointments = async () => {
    return http.get(`appointments/myAppointments`)
}

export const getAppointmentPrice = async (service, car) => {
    return http.get(`appointments/getPrice?service=${service}&car=${car}`)
}

export const getUserAutocomplete = async (text, fields) => {
    const filters = `$search=${fields.map(field => {
        return `{"field": "${field}", "text": "${text}"}`
    }).join(' OR ')}`;
    // const filters = generateOptions(fields.map(field => ({field, text})))
    const { data } = await http.get(`users?${filters}`)
    return data.map(d => ({...d, label: d.fullname, value: d._id}));
}
