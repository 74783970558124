import Translations from "./components/translation/translation";
import Localizations from "./components/translation/localization";

class SmartTableService {

    constructor() {
        this.provider = null;
    }

    setProvider(provider) {
        this.provider = provider
    }

    get(query, options) {
        if (!this.provider) {
            throw Error('No Provider for SmartTableNetworkService');
        }
        return this.provider.get(query, options || {})
    }

    post(query, data) {
        if (!this.provider) {
            throw Error('No Provider for SmartTableNetworkService');
        }
        return this.provider.post(query, data)
    }

    delete(query) {
        if (!this.provider) {
            throw Error('No Provider for SmartTableNetworkService');
        }
        return this.provider.delete(query)
    }

    patch(query, data) {
        if (!this.provider) {
            throw Error('No Provider for SmartTableNetworkService');
        }
        return this.provider.patch(query, data)
    }

    put(query) {
        if (!this.provider) {
            throw Error('No Provider for SmartTableNetworkService');
        }
        return this.provider.put(query)
    }

    setLanguage(lang) {
        Translations.setLanguage(lang || 'he');
    }

    setTranslations(translations) {
        this.translations = translations
    }

    getTranslations() {
        return this.translations
    }
}

export default new SmartTableService();
