    // eslint-disable-next-line import/no-unresolved
import AppConfig from '../config';
// import {setSessionIdToStorage, getSessionIdFromStorage} from '../store/user/actions';

class HttpRequest {
    defaults = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    options = {
        mock: {
            sleep: 0,
        },
    };

    url;

    endpoint;

    callback;

    $state = 'uninitialized';

    extractRequestOptions(options) {
        if (!options) {
            options = {saveSession: true};
        }
        const {mock, saveSession = true} = options;
        this.options = {mock, saveSession};
        delete options.mock;
        delete options.saveSession;
    }

    constructor(obj) {
        const {
            method, url, headers, endpoint, options, data, callback,
        } = obj;

        this.extractRequestOptions(options);

        this.url = `${url}/${endpoint}${this.serializedOptions(options, endpoint)}`;
        this.endpoint = endpoint;
        this.settings = {
            method,
            credentials: this.options.saveSession ? 'include' : null,
            headers: {...headers, ...this.defaults.headers},
            body: data ? JSON.stringify(data) : null,
        };
        this.callback = callback || null;

        this.$state = 'initialized';
    }

    save() {
    }

    async sleep(timeout, toResolve) {
        return await new Promise((resolve) => {
            setTimeout(() => {
                resolve(toResolve || 0);
            }, timeout || 0);
        });
    }

    async process() {
        this.$state = 'pending';
        try {
            // this.settings.headers['x-session-id'] = await getSessionIdFromStorage();

            if (this.options.mock) {
                return await this.sleep(this.options.mock.sleep, this.options.mock.resolve);
            }

            console.log(`http.process: request: ${this.url}`);

            const resp = await window.fetch(this.url, this.settings);
            const results = await this.evaluateResponse(resp, this.options);
            this.$state = 'resolved';

            this.results = results;
            return this.results;
        } catch (e) {
            console.log(`http.process: ERROR! ${e}`);
            this.$state = 'rejected';
            throw e;
        }
    }

    async evaluateResponse(response, options) {
        if (options.saveSession) {
            // let session = response.headers.get('set-cookie');
            // await setSessionIdToStorage({id: session})
        }
        // const contentLength = parseInt(response.headers.get("content-length")); // temporary solution while logout r body is empty
        const results = { // temporary solution while logout r body is empty
            results: {
                message: 'OK',
            },
        };

        if (response.ok) {
            try {
                return await response.json();
            } catch (e) {
                return results;
            }
            // return contentLength ? await response.json() : results; // temporary solution while logout r body is empty
        } else {
            let  error = await response.json();
            //let error = {message: 'Generic Error'};
            // try {
            //     error = await response.json();
            // } catch (e) {}
            const res = {
                error: {
                    message: error.message || 'Generic Error',
                },
            };

            return res;
            // throw new Error(res.message || 'שגיאה כללית');
            // throw new Error(`Error Communicating With Server.
            // Error Code:  ${res.code || response.code}
            // Error Message: ${res.message || 'Generic Error'}`);
        }
    }

    serializedOptions(obj, endpoint) {
        /* obj = {filters, project, search, populate, paging, sort} */
        if (!obj || Object.keys(obj).length === 0) return '';

        return `${endpoint.indexOf('?') > -1 ? '&' : '?'}${Object.entries(obj).map((e, i) => {
            switch (e[0]) {
                case 'paging':
                    return `$count=true&limit=${e[1].pageSize}&page=${Number(e[1].page)}`;
                case 'search':
                    let fields = e[1].fields;
                    let searchText = e[1].text;
                    let stringTypes = ['text', 'date'];
                    fields = fields.filter(f => !(isNaN(f.searchText) && f.type === 'number'))
                    let filters = fields.map(f => {
                            switch (f.type) {
                                case 'text' :
                                    return (`{"field": "${f.name}", "text": "${f.searchText}"}`);
                                case 'number':
                                    return (`{"field": "${f.name}", "text": "${f.searchText}"}`);
                                    // return (`(startswith(cast(${f.name}, 'Edm.String'),'${f.searchText}'))`)
                                case 'enum' :
                                case 'select' :
                                    return (`{"field": "${f.name}", "text": ${JSON.stringify(f.searchText)}}`);
                                case 'dateTime':
                                    return (`{"field": "${f.name}", "text": { "$gte": "${f.start}", "$lte": "${f.end}"}} `)
                                case 'boolean': 
                                    return f.searchText && (`{"field": "${f.name}", "text": ${f.searchText === "Yes"}}`);
                                default:
                                    return (`{"field": "${f.name}", "text": ${JSON.stringify(f.searchText)}}`);

                            }
                        }
                    ).filter(x => x).join(e[1].AND ? ' AND ' : ' OR ');
                    return `$search=${filters}`;
                case 'sort':
                    return `$orderby=${e[1]}`
                case 'filter':
                    return `$filter=${Object.entries(e[1]).map(([name, searchText]) => {
                        return `{"field": "${name}", "text": ${JSON.stringify(searchText)}}`
                    }).join(' AND ')}`;
                default:
                    return `${e[0]}=${typeof e[1] === 'string' ? e[1] : JSON.stringify(e[1])}`;
            }
        }).join('&')}&format=json`;
    }

    showSpinner() {
        return this.$state === 'pending';
    }
}

class HTTPConnection {
    config = {
        modes: {single: 'single', sequence: 'sequence', parallel: 'parallel'},
        set(options) {
            for (const option in Object.keys(options)) {
                if (option in this) {
                    this[option] = options[option];
                }
            }
        },
    };

    mode = this.config.modes.single;

    locals = {};

    requests = [];

    resolved = [];

    constructor(url) {
        this.url = url || AppConfig.server.url;
    }

    load(endpoint, data) {
        this.locals[endpoint] = data;
        return this;
    }

    clear() {
        this.requests = [];
        this.resolved = [];
        this.resolved = [];
    }

    addHeader(name, value) {
        if (!this.addedHeaders) {
            this.addedHeaders = {};
        }

        this.addedHeaders[name] = value;
    }

    clearHeaders() {
        delete this.addedHeaders;
    }

    setMode(mode) {
        this.mode = mode;
    }

    get sequence() {
        this.setMode(this.config.modes.sequence);
        return this;
    }

    get parallel() {
        this.setMode(this.config.modes.parallel);
        return this;
    }

    async runOnce() {
        const request = this.requests.pop();

        this.resolved.push(await request.process());

        if (request.callback) request.callback.call(this, this.resolved, this.locals);

        return this.resolved.pop();
    }

    async runParallel() {
        const callbacks = [];
        this.resolved = await Promise.all(this.requests.map((request) => {
            if (request.callback) callbacks.push(request.callback);
            return request.process();
        }));

        for (const cb of callbacks) {
            cb.call(this, this.resolved, this.locals);
        }
    }

    async runSequence() {
        for (const request of this.requests) {
            this.resolved.push(await request.process());

            if (request.callback) request.callback.call(this, this.resolved, this.locals);
        }
    }

    async run() {
        const {single, parallel, sequence} = this.config.modes;

        let results;

        switch (this.mode) {
            case single:
                results = JSON.parse(JSON.stringify(this.resolved));
                break;
            case sequence:
                await this.runSequence();
                results = JSON.parse(JSON.stringify(this.resolved));
                break;
            case parallel:
                await this.runParallel();
                results = JSON.parse(JSON.stringify(this.resolved));
                break;
        }

        this.clear();

        return results;
    }

    request(request) {
        if (!request.url) request.url = this.url;
        if (!request.method) request.method = 'GET';
        if (this.addedHeaders)
            request.headers = {...this.addedHeaders};
        const httpRequest = new HttpRequest(request);
        this.requests.push(httpRequest);
        return httpRequest;
    }

    next(request) {
        const {modes} = this.config;

        if (request) this.request(request);

        if (this.mode === modes.single && this.requests.length >= 1) {
            return this.runOnce();
        }

        return this;
    }
}


class HTTPClient extends HTTPConnection {
    static instance;

    get getInstance() {
        if (!this.instance) this.instance = this;
        return this.instance;
    }

    constructor(url) {
        super(url);
        return this.getInstance;
    }

    setUrl = (url) => this.url = url

    get(endpoint, options, callback) {
        return this.next({endpoint, options, callback});
    }

    post(endpoint, data, options, callback) {
        return this.next({
            method: 'POST', endpoint, options, data, callback,
        });
    }

    patch(endpoint, data, options, callback) {
        return this.next({
            method: 'PUT', endpoint, options, data, callback,
        });
    }

    delete(endpoint, data, options, callback) {
        return this.next({
            method: 'DELETE', endpoint, options, data, callback,
        });
    }

    put(endpoint, data, options, callback) {
        return this.next({
            method: 'PUT', endpoint, options, data, callback,
        });
    }
}

export default new HTTPClient().getInstance;
// const main = () => new HTTPClient().getInstance;
const connection = url => new HTTPClient(url);


export {
    // main,
    connection,
};

// let response = await HTTPClient
//     .sequence
//     .load('user', {t: ' - test'})
//     .get('user')
//     .get('uploadFile', {}, (resolved, locals) => {
//         resolved[2] = {timestamp: new Date() + locals.user.t, ...resolved[3]}
//     })
//     .get('data')
//     .run();
// return response
