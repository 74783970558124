import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';

import SmartWrapperService from './shared/components/SmartWrapper/src/SmartWrapperService';
import http from "./services/http";
import store from "./containers/App/store";
import {Provider} from "react-redux";

SmartWrapperService.setProvider(http);

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);
