import React from 'react';
import { connect } from 'react-redux';
import LogInForm from './components/LogInForm';
import engLogo from '../../shared/img/logo/logo.svg'
import logo from '../../shared/img/logo/logo.svg'
import styles from './login.scss'
import {withRouter} from "react-router-dom";

import {loginUser} from '../../redux/actions/authActions'
import {getData} from '../../redux/actions/appActions'
import {showMessage, showError} from '../../redux/actions/messageActions'

import Permissions from '../../services/permissions'
import Translation from '../../services/translation/translation'

class LogIn extends React.Component {
  constructor(props) {
    super(props);
  }

  handleLoginSubmit = async ({userName, password}) => {
    let user = await this.props.loginUser(userName, password);

    if (user) {
      if (user.role !== 'client') {
        await this.props.getData();
        // await Permissions.init(user.id, user.Role);
        this.props.history.push('/');
      } else {
        this.props.showError('כניסה למערכת', 'אין לך הרשאות בכדי להיכנס למערכת')
      }
    }
  };

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <img src={Translation.getCurrentLanguage().name === "en" ? engLogo : logo} alt="logo"/>
            </div>
            <LogInForm onSubmit={this.handleLoginSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
});

const mapDispatchToProps = {
  loginUser,
  getData,
  showMessage,
  showError
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
