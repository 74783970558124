import React from "react";
import {connect} from "react-redux";
import {localize, localized} from "../../services/translation/localization";
import config from '../../config'

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';
import moment from "moment";
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import Enums from "../../services/enums";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";

class CompaniesManagement extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            taskTypesModalService: null,
        };

    }

    async onCreate (doc) {
        doc["DailyEntrance"] = moment(doc["DailyEntrance"]).toISOString();
        doc.IsActive = true;
        return doc;

    }

    componentDidMount () {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.companies_management'));
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.companies_management'));
        }
    }

    viewMembershipForm (doc) {
        window.open(`${config.club.url}/#/members/${doc.subDomain}`);
    }

    render () {
        const {enums} = this.props;
        const tableConfig = {
            model: `companies`,
            modelDisplayName: localize(null, 'pages.titles.company'),
            actions: ['view', 'edit', {
                callback: this.viewMembershipForm,
                icon: 'groupAdd',
                title: 'מועדון לקוחות וועד עובדים'
            }],
            endpoints: {
                create: "companies/create",
                edit: "companies"
            },
            handlers: {
                // "PRE_CREATE": this.onCreate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "text",
                    displayModes: [SmartFormMode.view],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    showFilter: false,
                },
                {
                    accessor: "name",
                    alias: localize(null, "smartTable.fields.companyName"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "description",
                    alias: localize(null, "smartTable.fields.description"),
                    type: "textarea",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "logo",
                    alias: localize(null, "smartTable.fields.logo"),
                    type: "text",
                    isImage: true,
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    searchable: false,
                    fieldStyles: ["input-field-wide"]
                },
                {
                    accessor: "autoActivate",
                    alias: localize(null, "smartTable.fields.autoActivate"),
                    type: "boolean",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "subDomain",
                    alias: localize(null, "smartTable.fields.subDomain"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                // {
                //     accessor: "packages",
                //     alias: localize(null, "smartTable.fields.packages"),
                //     type: "multiselect",
                //     // options: services.map(service => ({label: service.label, value: service._id})),
                //     displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                //     allowTableView: false,
                //     show: false,
                //     filterable: false
                // },
                {
                    accessor: "benefits",
                    alias: localize(null, "smartTable.fields.services"),
                    type: "providedServices",
                    formFieldType: 'fieldArray',
                    formFields: [
                        {
                            fieldName: 'discountType',
                            fieldType: 'select',
                            fieldProps: {options: enums.discountTypes.map(e => ({label: e.display, value: e.value}))}
                        },
                        {fieldName: 'discount', fieldType: 'number'},
                        {fieldName: 'advertisement', fieldType: 'text'}
                    ],
                    displayModes: [SmartFormMode.new, SmartFormMode.edit],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    fieldStyles: ["input-field-wide"]
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                }
            ],
            restOptions: {
                // filter: {role: "CLIENT"},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }

        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.companies_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {tableConfig && <SmartWrapper filterable={false} allowAdd={true} allowExport={false}
                                                  tableConfig={tableConfig}/>}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    enums: state.app.enums,
    language: state.app.language
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesManagement);
