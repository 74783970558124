import PropTypes from "prop-types";
import React, { Component } from "react";
import FieldParameter from "../../../../../../FieldParameter/FieldParameter";

class renderField extends Component {
    constructor() {
        super();
        this.state = {
            value: "",
            showParameter: false,

        }
        this.inputNode = React.createRef();
    }

     componentDidMount = () => {
        this.setState({value: this.props.value || ''})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value!=this.props.value)
            this.setState({value: this.props.value});
    }

    componentWillUnmount = async () =>{
       await this.setState({value: ''});
    }

    onChange = (value) => {
        this.setState({value: value})
    }

    render() {
        const {
            input,
            placeholder,
            type,
            parameterOptions,
            meta: { touched, error },
            className,
            disabled,
            isImage
        } = this.props;
        return (
            <div className="form__form-group-input-wrap">
                <input
                    {...input}
                    disabled = {disabled}
                    onChange={(e) => {
                        this.onChange(e.target.value)
                        input.onChange(e.target.value)
                    }}
                    placeholder={placeholder}
                    type={type}
                    ref={this.inputNode}
                    value={this.state.value}
                    className={className}
                />
                {isImage && <img src={this.state.value} className={"image-field"}/>}
                {
                    parameterOptions ? this.state.showParameter ?
                        <FieldParameter
                            options={parameterOptions}
                            editField={this.inputNode}
                            addValue={(e) => this.setState({ value: e })}
                        />
                        :
                        <div className="button-container">
                            <button
                                onClick={() => this.setState({showParameter: !this.state.showParameter})}
                                className="action-icon-plus"
                            >
                            </button>
                        </div>
                        : null
                }
                {
                    touched && error &&
                    <span className="form__form-group-error">
                        {error}
                    </span>
                }
            </div>
        );
    }
}

renderField.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    input: {name:"", value: ""}
};

export default renderField;
