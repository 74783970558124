import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import http from "../../services/http";
import styled from 'styled-components'
// services
import {localize, localized} from "../../services/translation/localization";
import EnumsService from "../../services/enums";

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';

// components
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";
import {email, required} from "../../services/validation";
import ReactTable, {useTable, ReactTableDefaults} from "react-table";
import TextLocalize from "../../shared/components/SmartWrapper/src/components/TextLocalize/TextLocalize";
import DatePicker from "react-datepicker";
import moment from "moment";
import he from 'date-fns/locale/he';

const BranchesReport = ({setTitle}) => {

    const [fromDate, setFromDate] = useState(moment().add(-1, 'M').toDate());
    const [toDate, setToDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);


    setTitle('דוח נרשמים לפי סניף');

    useEffect(() => {
        async function fetchData() {
            const {data} = await http.get(`branches/stats?fromDate=${moment(fromDate).format('YYYY-MM-DD')}&toDate=${moment(toDate).format('YYYY-MM-DD')}`);
            setTotal(data.reduce((sum, item) => sum + item.total, 0))
            setData(data);
        }

        fetchData();

    }, [fromDate, toDate]);


    const columns = React.useMemo(
        () => [

            {
                Header: 'שם סניף',
                accessor: 'branch',
            },
            {
                Header: 'שם עמדה',
                accessor: 'stand',
            },
            {
                Header: 'מספר נרשמים',
                accessor: 'total',
            }
        ],
        []
    )


    return (
        <Container className="stats-report resource-management smart-table-wrapper reports">
            <p className="page-desc">דוח נרשמים - פילוג לתקופה לפי סניפים</p>

            <div className={"filters"}>
                <div className={"title"}>מתאריך:</div>
                <DatePicker
                    popperClassName="month-popup"
                    locale='he'
                   // popperPlacement= "bottom-start"
                    popperPlacement="bottom-start"

                    className="form__form-group-datepicker"

                    dateFormat="d/M/yyyy"
                    onChange={(date) => setFromDate(date)}
                    selected={fromDate}
                />

                <div className={"title"}>עד תאריך:</div>
                <DatePicker
                    locale='he'
                    popperClassName="month-popup"
                    popperPlacement="bottom-start"

                    // className="form__form-group-datepicker"
                    dateFormat="d/M/yyyy"
                    onChange={(date) => setToDate(date)}
                    selected={toDate}
                />
            </div>
            <Styles>
                <Table columns={columns} data={data} total={total}/>
            </ Styles>
        </Container>
    )
}


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 0px solid black;

    tr {
      //:last-child {
      //  td {
      //    border-bottom: 0;
      //  }
      //}
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-top: 1px solid black;
      border-bottom: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


function Table({columns, data, total}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <table {...getTableProps()} className={"table"}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}

            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
            <tfoot>
            <tr>
                <th>סה״כ נרשמים</th>
                <th></th>
                <th>{total}</th>
            </tr>
            </tfoot>
        </table>
    )
}


const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchesReport);
