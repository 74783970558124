import React, {useState} from 'react'
import {change, Field, reduxForm, formValueSelector} from 'redux-form'
import StyleButton from './StyleButton';

// Validators
import {required, checkAppointmentHour, normalizeCar} from '../services/validation'
import {getAvailableAppointmentsFromServer, getWorkingDaysFromServer, getUserAutocomplete} from "../services/appointment";
import Util from "../services/util";

import InputField from './InputFields/InputField'
import CheckBox from './InputFields/CheckBox'
import CustomSelect from "./InputFields/Select";
import Autocomplete from "./InputFields/Autocomplete";
import DatePicker from "./InputFields/DatePicker";
import dropDownButton from "../assets/component/down-arrow-icon.svg"
import "../scss/component/addEditAppointmentForm.scss"

import {connect} from "react-redux";
import {localize} from "../services/translation/localization";
import {ButtonToolbar} from "reactstrap";
import {getPlacesAutocomplete} from "../services/locations";
import AppConfig from "../config";
import moment from "moment";
// import action from "../../../website/src/store/action";

const asyncValidate = async (formValues, dispatch, props) => {
    const response = await props.getAvailableAppointments({
        appointmentDate: formValues.appointmentDate,
        branch: formValues.branch.value,
        service: props.service._id
    });
    const error = checkAppointmentHour(formValues.appointmentDate, response.includeTimes);
    if (error) {
        throw {appointmentDate: error}
    }
}

let AddEditAppointmentForm = (props) => {
    const {handleSubmit, error, invalid, updateField, onClose, services, branches, cars, formValues, availableHours = {}, chosenCar, enums, minDate} = props;
    const userCars = cars.map(car => ({...car, label: normalizeCar(car.number), value: car._id}));
    const [availableAppointments, setAvailableAppointments] = useState(availableHours);
    const [workingDays, setWorkingDays] = useState([])

    const isFutureAppointment = formValues && formValues.appointmentDate ? moment().isBefore(formValues.appointmentDate) : true;

    if (AppConfig.isPilot) {
        enums.carTypes = enums.carTypes.filter(type => type.showInPilot)
    }

    const getAvailableHours = async (type, data, formValues, setAvailableAppointments, minDate) => {
        const branch = type === 'branch' ? data.value : formValues.branch ? formValues.branch.value : null;
        const service = type === 'service' ? data.value : formValues.service ? formValues.service.value : null;
        const date = type === 'date' ? data : formValues ? formValues.appointmentDate : minDate;
        // const availableHours = await props.getAvailableAppointments({date: date || minDate, branch: branch.value, service: service.value});
        // setWorkingDays(workingDays);
        if (branch && service && date) {
            const response = await getAvailableAppointmentsFromServer({date: date || minDate, branch, service});
            setAvailableAppointments(response);
        }
        // setAvailableAppointments(availableHours);
    }

    const getAvailableWorkingDays = async (date) => {
        const workingDays = await getWorkingDaysFromServer({date, branch: formValues.branch.value, service: formValues.service.value});
        setWorkingDays(workingDays);
    }

    const isWorkingHour = () => {
        return checkAppointmentHour(formValues.date, availableHours.includeTimes);
        // if (error) {
        //     throw {date: error}
        // }
    }

    return (
        <form className="form membershipForm addEditAppointmentForm" onSubmit={handleSubmit}>
            {/*
            TODO: uncomment to allow "Test from home"
            <div className={"full-width"}>
                <Field
                    name="address"
                    placeholder="כתובת"
                    label=""
                    isSearchable=true
                    showDropdownIndicator={true}
                    loadOptions={searchToken => getPlacesAutocomplete(searchToken, 'IL')}
                    noOptionsMessage={() => 'חפש כתובת'}
                    loadingMessage={() => 'מחפש...'}
                    validate={[required]}
                    component={Autocomplete}/>
            </div>*/}
            <div className="together">
                {!isFutureAppointment && <Field
                    name="_id"
                    placeholder="מספר מזהה"
                    label=""
                    hidden={true}
                    type={"hidden"}
                    component={InputField}/>}
                {isFutureAppointment && <Field
                    name="service"
                    placeholder="בחר שירות"
                    label=""
                    options={services}
                    onChange={(value) => getAvailableHours('service', value, formValues, setAvailableAppointments, minDate)}
                    onCustomChange={() => updateField('addEditAppointmentForm', 'appointmentDate', null)}
                    // format={Util.selectFormat(branches)}
                    // parse={Util.selectParse}
                    component={CustomSelect}
                    validate={[required]}
                />}

                {isFutureAppointment && <Field
                    name="branch"
                    placeholder="סניף"
                    label=""
                    options={branches}
                    onChange={(value) => getAvailableHours('branch', value, formValues, setAvailableAppointments, minDate)}
                    onCustomChange={() => updateField('addEditAppointmentForm', 'appointmentDate', null)}
                    // format={Util.selectFormat(branches)}
                    // parse={Util.selectParse}
                    component={CustomSelect}
                    validate={[required]}
                />}

                {isFutureAppointment && <Field name={`appointmentDate`}
                       component={DatePicker}
                       disabled={!formValues || !formValues.service || !formValues.branch}
                       minDate={minDate}
                       timeParams={availableAppointments}
                       includeDates={workingDays}
                       getAvailableDays={getAvailableWorkingDays}
                       // asyncValidate={() => asyncValidate({...formValues}, null, {formValues})}
                       onChange={(value) => getAvailableHours('date', value, formValues, setAvailableAppointments, minDate)}
                       validate={[required]}
                       label=""
                       placeholder="מועד שירות"
                       props={{showTimeSelect: true}}
                />}
            </div>
            {isFutureAppointment && <div className="together">
                <Field name="user"
                    // type="tel"
                    // component={InputField}
                    // normalize={normalizeCar}
                    //    options={userCars}
                    //    format={Util.selectFormat(userCars)}
                    //    parse={Util.selectParse}
                       loadOptions={searchToken => getUserAutocomplete(searchToken, ['fullname', 'phone', 'email'])}
                       onCustomChange={() => updateField('addEditAppointmentForm', 'car', null)}
                       component={Autocomplete}
                       validate={[required]}
                       label=""
                       placeholder="לקוח"
                />
                {/*{formValues.service && formValues.service.name === 'test' &&*/}
                {/*    <Field*/}
                {/*        name="address"*/}
                {/*        placeholder="כתובת"*/}
                {/*        label=""*/}
                {/*        showDropdownIndicator={true}*/}
                {/*        loadOptions={searchToken => getPlacesAutocomplete(searchToken, 'IL')}*/}
                {/*        noOptionsMessage={() => 'חפש כתובת'}*/}
                {/*        loadingMessage={() => 'מחפש...'}*/}
                {/*        validate={[required]}*/}
                {/*        component={Autocomplete}/>*/}
                {/*}*/}
                <Field name="car"
                    // type="tel"
                    // component={InputField}
                    // normalize={normalizeCar}
                       options={userCars}
                       // format={Util.selectFormat(userCars)}
                       // parse={Util.selectParse}
                       component={CustomSelect}
                       isDisabled={!formValues || !formValues.user}
                       validate={[required]}
                       label=""
                       placeholder="מס׳ הרכב"
                />
            </div>}
            {isFutureAppointment && <div className="together">
                {formValues.service && formValues.service.name === 'buy' && formValues.car && !formValues.car.group &&
                <Field name={`group`}
                       options={Util.enumToSelectOptions(enums.carGroups)}
                       format={Util.selectFormat(Util.enumToSelectOptions(enums.carGroups))}
                       parse={Util.selectParse}
                       component={CustomSelect}
                       validate={[required]}
                       placeholder="קבוצת הרכב"
                       label=""
                       props={{style: {dropdownIndicator: <img src={dropDownButton}/>}}}
                />
                }
                {formValues.service && formValues.service.name === 'buy' && formValues.car && !formValues.car.tires &&
                <Field name={`tires`}
                       options={Util.enumToSelectOptions(enums.tiresTypes)}
                       format={Util.selectFormat(Util.enumToSelectOptions(enums.tiresTypes))}
                       parse={Util.selectParse}
                       component={CustomSelect}
                       validate={[required]}
                       label=""
                       placeholder="גלגלים"
                />
                }
                {formValues.service && formValues.service.name === 'buy' && formValues.car && !formValues.car.engine &&
                <Field name={`engine`}
                       options={Util.enumToSelectOptions(enums.engineTypes)}
                       format={Util.selectFormat(Util.enumToSelectOptions(enums.engineTypes))}
                       parse={Util.selectParse}
                       component={CustomSelect}
                       validate={[required]}
                       placeholder="סוג מנוע"
                       label=""
                />
                }
                {formValues.service && formValues.service.name === 'test' && formValues.car && !formValues.car.type &&
                <Field name={`type`}
                       options={Util.enumToSelectOptions(enums.carTypes)}
                       format={Util.selectFormat(Util.enumToSelectOptions(enums.carTypes))}
                       parse={Util.selectParse}
                       component={CustomSelect}
                       validate={[required]}
                       placeholder="סוג הרכב"
                       label=""
                       props={{style: {dropdownIndicator: <img src={dropDownButton}/>}}}
                />
                }
            </div>}
            {!isFutureAppointment && <div className="together">
                <Field
                    name="isPaid"
                    placeholder="האם בוצע תשלום"
                    label="האם בוצע תשלום"
                    type={"boolean"}
                    component={CheckBox}/>
                <Field
                    name="arrived"
                    placeholder="האם הופיע לתור"
                    label="האם הופיע לתור"
                    type={"boolean"}
                    component={CheckBox}/>
                <Field name={`appointmentStartingDate`}
                       component={DatePicker}
                       // disabled={}
                       // minDate={minDate}
                       // timeParams={availableAppointments}
                       // includeDates={workingDays}
                       // getAvailableDays={getAvailableWorkingDays}
                    // asyncValidate={() => asyncValidate({...formValues}, null, {formValues})}
                    //    onChange={(value) => getAvailableHours('date', value, formValues, setAvailableAppointments, minDate)}
                    //    validate={[required]}
                       label=""
                       placeholder="מועד שירות"
                       props={{showTimeSelect: true}}
                />
            </div>}
            {error && <div className="form-error">{error}</div>}
            <div className={"buttons-wrapper"}>
                <ButtonToolbar className="tm-buttons">
                    <button type="button" onClick={onClose}>
                        {localize(null, 'smartForm.cancel')}
                    </button>
                    <button type="submit">
                        {localize(null, 'smartForm.save')}
                    </button>
                </ButtonToolbar>
                {/*<StyleButton label={'ביטול'}
                             color="#0067a5"
                    // isDisabled={props.disableGoBack}
                             isOutlined={true}
                             callback={goBack}
                             customStyle={{
                                 margin: "10px 5px",
                                 alignSelf: 'flex-end',
                                 // fontSize: "22px",
                                 borderWidth: "1px",
                                 minWidth: "45%"
                             }}
                />
                <StyleButton label='המשך'
                             onClick={handleSubmit}
                             isDisabled={false}
                             customStyle={{
                                 margin: "10px 5px",
                                 alignSelf: 'flex-end',
                                 // fontSize: "22px",
                                 backgroundColor: "#0067a5",
                                 minWidth: "45%"
                             }}
                />*/}
            </div>
        </form>
    )
};

const mapDispatchToProps = dispatch => ({
    updateField: (form, field, newValue) => dispatch(change(form, field, newValue))
});

const selector = formValueSelector('addEditAppointmentForm');

const AddEditAppointmentFormRedux = reduxForm({
    form: 'addEditAppointmentForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    // enableReinitialize: true,
    // asyncValidate,
    // asyncChangeFields: ['appointmentDate']
})(AddEditAppointmentForm);


const AddEditAppointmentFormDecorated = connect((state, props) => {
    const car = selector(state, 'car');
    const user = selector(state, 'user');
    // const chosenCar = car ? user.cars.find(c => c._id === car) : null;
    const tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1) // add 1 day to today
    let { initialValues } = props;
    let userCars = user ? user.cars : initialValues.user ? initialValues.user.cars : [];

    return {
        minDate: tomorrow,
        formValues: state.form.addEditAppointmentForm ? state.form.addEditAppointmentForm.values : {},
        // availableHours: state.appointments.availableAppointments,
        // user: state.user,
        cars: userCars,
        branches: state.app.data.branches.map(branch => ({...branch, label: branch.displayName, value: branch._id})),
        services: state.app.data.services.map(service => ({...service, label: service.label, value: service._id})),
        enums: state.app.enums,
        // chosenCar,
        // initialValues: initialValues,
    };
}, mapDispatchToProps)(AddEditAppointmentFormRedux);

export default AddEditAppointmentFormDecorated;
