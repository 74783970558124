/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class CheckBoxField extends Component {
    static propTypes = {
        handleChange: PropTypes.func,
        name: PropTypes.string.isRequired,
        // value: PropTypes.oneOfType([
        //     PropTypes.string,
        //     PropTypes.bool,
        // ]).isRequired,
        label: PropTypes.string,
        value: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        color: PropTypes.string,
    };

    static defaultProps = {
        label: '',
        defaultChecked: false,
        disabled: false,
        className: '',
        color: '',
    };

    constructor() {
        super();
        this.state = {
            value: false
        }
    }

    componentDidMount = () => {
        this.setState({value: this.props.value || false})
    }

    onChange = (value) => {
        this.setState({value: value})
    }

    render() {
        const {
            disabled, className, name, label, color, onChange
        } = this.props;
        const CheckboxClass = classNames({
            'checkbox-btn': true,
            disabled,
        });

        return (
            <label
                className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`}
            >
                <input
                    className="checkbox-btn__checkbox"
                    type="checkbox"
                    name={name}
                    onChange={(e) => {
                        this.onChange(e.target.checked)
                        onChange(e.target.checked)
                    }}
                    checked={this.state.value}
                    disabled={disabled}
                />
                <span
                    className="checkbox-btn__checkbox-custom"
                    style={color ? { background: color, borderColor: color } : {}}
                >
          <CheckIcon />
        </span>
                {className === 'button'
                    ? (
                        <span className="checkbox-btn__label-svg">
              <CheckIcon className="checkbox-btn__label-check" />
              <CloseIcon className="checkbox-btn__label-uncheck" />
            </span>
                    ) : ''}
                <span className="checkbox-btn__label">
          {label}
        </span>
            </label>
        );
    }
}

const renderCheckBoxField = (props) => {
    const {
        input, label, disabled, className, color, value, handleChange
    } = props;
    return (
        <CheckBoxField
            {...input}
            label={label}
            handleChange={handleChange}
            value={value}
            disabled={disabled}
            className={className}
            color={color}
        />
    );
};

renderCheckBoxField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]),
    }).isRequired,
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
};

renderCheckBoxField.defaultProps = {
    label: '',
    defaultChecked: false,
    disabled: false,
    className: '',
    color: '',
};

export default renderCheckBoxField;
