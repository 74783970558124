import React, {Component} from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";

import UsersIcon from '../../../shared/img/side-bar/users.svg'
import Permissions from '../../../services/permissions'
import EnumsService from '../../../services/enums'
import {localize} from "../../../services/translation/localization";
import {connect} from "react-redux";
import {IconContext} from 'react-icons';
import {FaCarCrash, FaTools, FaArchway, FaCalendarCheck, FaUserAlt, FaUserSecret, FaChartBar, FaBriefcase, FaUserTag, FaBoxOpen, FaPercentage} from 'react-icons/fa';


class SidebarContent extends Component {
    static propTypes = {
        changeToDark: PropTypes.func.isRequired,
        changeToLight: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired
    };

    constructor (props) {
        super(props)
        this.state = {}
    }

    hideSidebar = () => {
        const {onClick} = this.props;
        onClick();
    };

    componentDidMount () {
        this.getSidebarItems();
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.language != this.props.language) {
            this.getSidebarItems()
        }
    }


    getSidebarItems = () => {
        const {user, roles} = this.props;
        this.setState({
            sidebarItems: {
                generalItems: [
                    {
                        screenID: 1,
                        title: localize(null, 'sidebar.users_management'),
                        route: "/users_management",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaUserSecret/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value
                    },
                    {
                        screenID: 2,
                        title: localize(null, user.role === roles.admin.value ? 'sidebar.branches_management' : 'sidebar.branch_management'),
                        route: "/branches_management",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaTools/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value || user.role === roles.employee.value
                    },
                    {
                        screenID: 3,
                        title: localize(null, 'sidebar.stands_management'),
                        route: "/stands_management",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaArchway/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value
                    },
                    {
                        screenID: 4,
                        title: localize(null, 'sidebar.services_management'),
                        route: "/services_management",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaCarCrash/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value
                    },
                    {
                        screenID: 5,
                        title: localize(null, 'sidebar.clients_management'),
                        route: "/clients_management",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaUserAlt/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value
                    },
                    {
                        screenID: 6,
                        title: localize(null, 'sidebar.appointments_management'),
                        route: "/appointments_management",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaCalendarCheck/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value || user.role === roles.employee.value
                    },
                    {
                        screenID: 11,
                        title: localize(null, 'sidebar.branches_stats'),
                        route: "/branches_report",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaChartBar/>
                        </IconContext.Provider>,
                        visible: true //user.role === roles.admin.value || user.role === roles.employee.value
                    },
                ],
                workersCommittees: [
                    {
                        screenID: 7,
                        title: localize(null, 'sidebar.companies_management'),
                        route: "/workers_committees/companies",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaBriefcase/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value
                    },
                    {
                        screenID: 8,
                        title: localize(null, 'sidebar.workers_committees'),
                        route: "/workers_committees/clients",
                        image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                            <FaUserTag/>
                        </IconContext.Provider>,
                        visible: user.role === roles.admin.value
                    },
                    // {
                    //     screenID: 9,
                    //     title: localize(null, 'sidebar.coupons_packages_management'),
                    //     route: "/workers_committees/packages",
                    //     image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                    //         <FaBoxOpen/>
                    //     </IconContext.Provider>,
                    //     visible: user.role === roles.admin.value
                    // },
                    // {
                    //     screenID: 10,
                    //     title: localize(null, 'sidebar.coupons_management'),
                    //     route: "/workers_committees/coupons",
                    //     image: <IconContext.Provider value={{color: "#326c91", className: "sidebar__link-image"}}>
                    //         <FaPercentage/>
                    //     </IconContext.Provider>,
                    //     visible: user.role === roles.admin.value
                    // }
                ]
            }
        })
    }

    getSidebarItem = (screen, index) => {
        let screens = Permissions.getUserScreenPermissions();
        let item = screen;
        for (let i = 0; i < screens.length; i++) {
            if (screens[i].ScreenID == screen.screenID)
                item = screen;
        }
        return (
            item && item.visible ?
                <SidebarLink
                    key={index}
                    title={item.title}
                    route={item.route}
                    image={item.image}
                    onClick={this.hideSidebar}
                /> : null)
    }

    render () {

        let {sidebarItems} = this.state;
        return (
            sidebarItems ?
                <div className="sidebar__content">
                    <ul className="sidebar__block">
                        <SidebarLink
                            title={localize(null, 'sidebar.menu')}
                            icon="menu" route="#"
                            onClick={this.props.changeSidebarVisibility}
                        />
                        {sidebarItems.generalItems.map((screen, index) => {
                            return this.getSidebarItem(screen, index);
                        })}
                        <hr />
                        {sidebarItems.workersCommittees.map((screen, index) => {
                            return this.getSidebarItem(screen, index);
                        })}
                    </ul>
                </div> : null
        );
    }
}


const mapStateToProps = state => ({
    language: state.app.language,
    user: state.auth.user,
    roles: EnumsService.getEnumAsObject('permissions')
});


export default connect(mapStateToProps)(SidebarContent);
