import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { localize, localized } from "../../services/translation/localization";
import MessageTemplatesList from "./MessageTemplatesList/index";
import SmartFormMode from '../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode'
import Permissions from '../../services/permissions'

// actions
import { setTitle, setTableConfig } from '../../redux/actions/appActions';
import MessageTemplate from './MessageTemplate';
import {required} from "../../services/validation";
import Enums from "../../services/enums";

class MessageTemplatesManagement extends React.Component {

    async componentDidMount() {

        let { language } = this.props;
        this.props.setTitle(localize(language.name, 'pages.titles.message_templates_management'));

        this.getTableConfig = (lang) => {
            let localize = localized(lang)
            let config =  {
                model: "MessageTemplates",
                modelDisplayName: localize('pages.titles.message_templates_management'),
                endpoints: {
                    create: "MessageTemplates",
                    edit: "MessageTemplates"
                },
                IDAccessor: "ID",
                fields: [
                    {

                        accessor: "ID",
                        alias: localize("smartTable.fields.id"),
                        type: "number",
                        displayModes: [SmartFormMode.view],
                        allowTableView: true,
                        show: true,
                        filterable: false,
                    },
                    {

                        accessor: "Type",
                        setAccessor: "Type",
                        alias: localize("smartTable.fields.messageType"),
                        type: "enum",
                        displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                        allowTableView: true,
                        show: true,
                        filterable: false,
                        optionsKey: "MessageTypes",
                        options: Enums.getEnum("MessageTypes") ? Enums.getEnum("MessageTypes").map((function (e) {
                            return {value :e.id,
                                label: e.displayName,
                            }
                        })): null,
                    },
                    {
                        accessor: "Name",
                        alias: localize("smartTable.fields.messageName"),
                        type: "text",
                        displayModes: [SmartFormMode.edit, SmartFormMode.view,SmartFormMode.new],
                        allowTableView: true,
                        show: true,
                        filterable: false,
                        validators: [required],
                        fieldStyles: ["input-field-wide"],
                    },
                    {
                        accessor: "Title",
                        alias: localize("smartTable.fields.messageTitle"),
                        type: "textarea",

                        displayModes: [SmartFormMode.edit, SmartFormMode.view,SmartFormMode.new],
                        allowTableView: true,
                        show: true,
                        filterable: false,
                        validators: [],
                        parameterOptions: [
                            {
                                value: 'service_provider_name',
                                label: 'Service provider name',
                            },
                            {
                                value: 'service_provider_business_name',
                                label: 'Service provider business name',
                            },
                        ],
                        fieldStyles: ["input-field-wide"],
                    },
                    {
                        accessor: "Content",
                        alias: localize("smartTable.fields.messageBody"),
                        type: "textarea",
                        displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                        allowTableView: true,
                        show: true,
                        filterable: false,
                        validators: [],
                        parameterOptions: [
                            {
                                value: 'service_provider_name',
                                label: 'Service provider name',
                            },
                            {
                                value: 'service_provider_business_name',
                                label: 'Service provider business name',
                            },
                        ],
                        fieldStyles: ["input-field-wide"],
                    },
                    {
                        accessor: "editView",
                        alias: localize("smartTable.fields.editView"),
                        type: "actions",
                        allowTableView: true,
                        show: true,
                        filterable: false
                    },
                ],
                searchFields: ["messageType", "messageTitle", "messageBody"],
                restOptions: {
                    pagination: true,
                    pageSize: 10,
                    defaultSort: {
                        field: "ID",
                        order: "desc"
                    }
                },
                allowCreation: true
            }
            return Permissions.enforcePermissions(config);
        }
        await this.props.setTableConfig(this.getTableConfig(this.props.language.name))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let { language } = this.props;

            this.props.setTitle(localized(language.name)('pages.titles.message_templates_management'));
            this.props.setTableConfig(this.getTableConfig(this.props.language.name))
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path="/message_templates_management" component={MessageTemplatesList} />
                <Route path="/message_templates_management/:id" component={MessageTemplate} />
            </Switch>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    user: state.auth.user,
    language: state.app.language,
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTemplatesManagement);
