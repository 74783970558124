import Translations from './translation'

const localization = {
    he: {
        pages: {
            titles: {
                customers: 'לקוחות',
                companies: "חברות",
            },
            login: {
                username: 'שם משתמש',
                name: 'שם',
                password: 'סיסמא',
                signIn: 'התחברות',
                rememberMe: 'זכור אותי',
                forgotPassword: 'שכחתי סיסמא',
            }

        },
        models: {
            user: 'לקוח'
        },
        sidebar: {
            menu: 'תפריט',
            customers: 'לקוחות'
        },
        user: {
            tabs: {
                details: 'פרטים אישיים',
                status: 'מצב קיים',
                proposals: 'הצעות'
            }
        },
        smartTable: {
            search: 'חיפוש חופשי',
            displayFields: 'בחר שדות להצגה',
            addButton: 'הוסף',
            exportButton: 'ייצא',
            edit: 'עריכה',
            view: 'צפייה',
            fields: {
                firstName: 'שם פרטי',
                lastName: 'שם משפחה',
                fullName: 'שם',
                email: 'אימייל',
                phone: 'טלפון',
                role: 'תפקיד',
                gender: 'מין',
                status: 'סטטוס',
                services: 'שירותים',
                city: 'עיר',
                street: 'רחוב',
                houseNumber: 'מספר בית',
                apartment: 'דירה',
                postalCode: 'תא דואר',
                zipCode: 'מיקוד',
                income: 'הכנסה',
                profession: 'מקצוע',
                isMarried: 'נשוי',
                fromPilot: 'לקוח פיילוט',
                testDate: 'תאריך טסט אחרון',
                companyName: 'שם החברה',
                workerNumber: 'מספר עובד',
                logo: 'לוגו',
                description: 'תיאור',
                autoActivate: 'אישור לקוח אוטומטית',
                subDomain: 'נתיב ב-URL',
                packages: 'חבילות',
                birthDate: 'תאריך לידה',
                created: 'תאריך יצירה',
                lastLoginTime: 'תאריך התחברות אחרונה',
                insuranceMonth: 'חודש פקיעת ביטוח',
                branch: 'סניף',
                stand: 'עמדה',
                lastUpdateTime: 'תאריך עדכון אחרון',
                editView: 'עריכה/צפייה',
            },
            pagination: {
                page: 'עמוד',
                of: 'מתוך'
            }
        },
        smartForm: {
            modes: {
                view: 'צפה',
                edit: 'ערוך',
                new: 'צור'
            },
            save: 'שמור',
            cancel: 'בטל',
            trueValue: 'כן',
            falseValue: 'לא',
        },
        subject: {
            title: `היי [VAR],`,
            description: `איך אפשר לעזור לך היום?`,
            professionsList: `לרשימת נושאי השירות`,
            footer: {
                a: `איש המקצוע המומלץ ביותר מיד מתקשר אליך!`,
                b: `לפי דירוג לקוחות, מיקום, זמינות ואיכות.`
            },
            typeRequest: `לחץ והקלד את השירות המבוקש`
        },
        locationAndTiming: {
            location: `בחירת שם היישוב בו נדרש השירות`,
            timing: `מתי ניתן להתקשר אליך?`,
            locationError: `שם היישוב חסר או אינו תקין`,
            locationSpecific: 'הוסף איזור',
            continue: 'המשך',
            proposals: `בחר כמה הצעות תרצה לקבל`,
            multiple: `מספר הצעות`,
            single: `הצעה אחת`
        },
        details: {
            rotatingText: {
                a: `המערכת מאתרת את הספץ המתאים ביותר עבורך`,
                b: `המערכת מחפשת ספץ באזורך`,
                c: `בדיקת זמינות נותני שירות רלוונטיים`,
                d: `סינון לפי דירוג לקוחות קודמים`,
                e: `נמצא ספץ!`,
                f: `מעביר פרטים`,
                g: `נותן השירות יתקשר אליך במועד המבוקש`
            },
            media: {
                title: `בחר מדיה לתיאור הבעיה`,
                camera: `מצלמה`,
                takePicture: `צלם תמונה`,
                fromGalleryIOS: `בחר מהגלריה`,
                fromGalleryAndroid: `בחר תמונה מהגלריה`,
                takeVideo: `צלם סרטון`,
                fromGallery: `בחר סרטון מהגלריה`
            },
            description: {
                a: `כמעט סיימנו...`,
                b: ` לסיום בחר ב״מצא ספץ!״`,
                c: `איש המקצוע יתקשר במועד המבוקש.`,
                d: `לעריכה לחץ על השדה הרלוונטי.`,
            },
            cancel: `ביטול וחזרה להתחלה`,
            callback: `לתשומת ליבך, בהמשך נתקשר בשיחה חסויה לווידוא קבלת מענה מתאים.`,
            cancelCall: `ביטול פניה`,
            serviceCallCancelled: `פנייתך בוטלה`,
            cancelNotificationReceived: `איש המקצוע קיבל הודעת ביטול.`,
            onOurWay: `ספץ בדרך אליך`,
            backToHome: `חזרה לעמוד הראשי`,
            backHome: `חזור למסך הראשי`,
            error: `אירעה שגיאה.`,
            tryLater: `נסה שוב`,
            oops: 'אופס!',
            cannotOpenServiceCall: `                                ספץ לא יכול לפתוח את הקריאה בשבילך.
                                כבר קיימת קריאה קודמת פתוחה
                                בנושא שירות זה.`,
            contactUs: `צור קשר`,
            findSpetz: `מצא ספץ`,
            cancelIt: `בטל`,
            summary: {
                need: `אני צריך`,
                location: `ביישוב`,
                when: `שיצרו איתי קשר`,
                phone: `לטלפון`,
                accept: `אישור`
            },
            comment: `הלקוח הוסיף תיאור לבעיה ו/או תמונה: [VAR]\n[VAR]`,
            commentPlaceholder: `הוסף תיאור לבעיה`
        },
        history: {
            ranking: {
                "1": `יש מה לשפר`,
                "2": `כמעט טוב`,
                "3": `טוב`,
                "4": `טוב מאד`,
                "5": `מעולה`,
                "6": `מושלם`
            },
            parameters: {
                ranking: `דירוג: `,
                raters: `מספר מדרגים: `,
                noOfCalls: `מספר פניות שהופנו: `,
                seniority: `ותק: `,
            }
        },
        misc: {
            richContent: `מעל 400 נושאי שירות לבחירתך`
        }
    },
    en: {
        pages: {
            titles: {
                customers: 'Customers',
                companies: "Companies",
            }
        },
        models: {
            user: 'Customer'
        },
        sidebar: {
            menu: 'Menu',
            customers: 'Customers'
        },
        user: {
            tabs: {
                details: 'Details',
                status: 'Status',
                proposals: 'Proposals'
            }
        },
        smartTable: {
            search: 'Free Search',
            displayFields: 'Select Fields To Display',
            addButton: 'Add',
            exportButton: 'Export',
            editView: 'Edit',
            view: 'View',
            fields: {
                Name: 'Name',
                Email: 'Email',
                Phone: 'Phone',
                'Edit/View': 'Edit/View',
            },
            pagination: {
                page: 'Page',
                of: 'of'
            }
        },
        smartForm: {
            modes: {
                view: 'View',
                edit: 'Edit',
                new: 'New'
            },
            save: 'Save',
            cancel: 'Cancel',
        },
        subject: {
            title: `Hello [VAR],`,
            description: `How can we help you today?`,
            professionsList: `Open for list of services`,
            footer: {
                a: `A top professional will be calling you right now!`,
                b: `Based on customer rating, location, availability and expertise.`
            },
            typeRequest: `Type here the requested service`
        },
        locationAndTiming: {
            location: `Choose your location`,
            timing: `When can they call you?`,
            locationError: `Location field is missing`,
            locationSpecific: 'Please add your borough',
            continue: 'Continue',
            proposals: `בחר כמה הצעות תרצה לקבל`,
            multiple: `מספר הצעות`,
            single: `הצעה אחת`
        },
        details: {
            rotatingText: {
                a: `The application is locating the right service provider for you`,
                b: `The system is checking availability`,
                c: `The system is selecting the best rated provider`,
                d: `We’ve done it!`,
                e: `You will get a call now`,
                f: `You will get a call now`,
                g: `נותן השירות יתקשר אליך במועד המבוקש`
            },
            media: {
                title: `Choose media to describe the problem`,
                camera: `Camera`,
                takePicture: `Take a Picture`,
                fromGalleryIOS: `From Gallery`,
                fromGalleryAndroid: `Picture from Gallery`,
                takeVideo: `Take a Video`,
                fromGallery: `Video from Gallery`
            },
            description: {
                a: `You’re almost there...`,
                b: `To finish, click on: “Spetz-it!”`,
                c: `The service provider will call you \nat the requested time.`,
                d: `To edit, click on the relevant field.`,
            },
            cancel: `Cancel and go back`,
            callback: `Please note: you will receive from us very shortly a personal call to make sure you have received the right provider.`,
            cancelCall: `Cancel request`,
            serviceCallCancelled: `Service cancelled`,
            cancelNotificationReceived: `איש המקצוע קיבל הודעת ביטול.`,
            onOurWay: `Your service provider is on the way`,
            backToHome: `חזרה לעמוד הראשי`,
            backHome: `חזור למסך הראשי`,
            error: `Error occurred`,
            tryLater: `try again`,
            oops: 'אופס!',
            cannotOpenServiceCall: `ספץ לא יכול לפתוח את הקריאה בשבילך. כבר קיימת קריאה קודמת פתוחה בנושא שירות זה.`,
            contactUs: `צור קשר`,
            findSpetz: `Spetz-it`,
            cancelIt: `Cancel`,
            summary: {
                need: `Service`,
                location: `Location`,
                when: `Time to call`,
                phone: `Tel:`,
                accept: `Ok`
            },
            comment: `הלקוח הוסיף תיאור לבעיה ו/או תמונה: [VAR]\n[VAR]`,
            commentPlaceholder: `Describe the problem`
        },
        history: {
            ranking: {
                "1": `Improving`,
                "2": `Almost good`,
                "3": `Good`,
                "4": `Very Good`,
                "5": `Excellent`,
                "6": `Perfect`
            },
            parameters: {
                ranking: `Ranking: `,
                raters: `No. of Raters: `,
                noOfCalls: `No. Of Calls: `,
                seniority: `Seniority: `,
            }
        },
        misc: {
            richContent: `more than 400 services available`
        }
    }
};

const getValueByKey = function (obj, key) {
    let base = obj;
    let keys = key.split('.');

    for (let k of keys) {
        if (base[k])
            base = base[k];
        else base = k;
    }

    return base;
};

const getVariableAttachedText = function (variables, text) {
    for (let variable of variables) {
        if (text.indexOf('[VAR]') > -1) {
            text = text.replace('[VAR]', variable);
        }
    }
    return text;
};

const localize = function (lang, key, variables) {

    if (!key) {
        throw 'Translations: Missing key';
    }


    if (!lang) {

        lang = Translations.getCurrentLanguage().name;
        if (!lang) {
            throw 'Translations: Missing language';
        }
    }

    let localText = getValueByKey(localization[lang || 'he'], key);

    if (!variables || variables.length === 0) {
        return localText;
    }

    return getVariableAttachedText(variables, localText);
};

export default localize;
