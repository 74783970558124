import Layout from "../Layout";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import MessageTemplatesManagement from "../MessageTemplatesManagement";
import React from "react";
import {connect} from "react-redux";
import UsersManagement from "../UsersManagement";
import BranchesManagement from "../BranchesManagment";
import ServicesManagement from "../ServicesManagement";
import StandsManagement from "../StandsManagement";
import ClientsManagement from "../ClientsManagement";
import AppointmentsManagement from "../AppointmentsManagement";
import BranchesReport from "../BranchesReport"
import CompaniesManagement from "../CompaniesManagement";
import WorkersCommitteesManagement from "../WorkersCommitteesManagement";
import {getData} from "../../redux/actions/appActions";

class MainLayoutPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        // await this.props.getData();
    }

    componentWillUnmount() {
    }

    render() {
        const {user} = this.props;
        return (
            <div>
                <Layout />
                <div className="container__wrap">
                    {user && user.status === 'active' ?
                    <Switch>
                        <Route path="/message_templates_management" component={MessageTemplatesManagement} />
                        <Route path="/users_management" component={UsersManagement} />
                        <Route path="/branches_management" component={BranchesManagement} />
                        <Route path="/stands_management" component={StandsManagement} />
                        <Route path="/services_management" component={ServicesManagement} />
                        <Route path="/clients_management" component={ClientsManagement} />
                        <Route path="/appointments_management" component={AppointmentsManagement} />
                        <Route path="/branches_report" component={BranchesReport} />

                        <Route path="/workers_committees/companies" component={CompaniesManagement} />
                        <Route path="/workers_committees/clients" component={WorkersCommitteesManagement} />
                        <Route path="/workers_committees/packages" component={WorkersCommitteesManagement} />
                        <Route path="/workers_committees/coupons" component={WorkersCommitteesManagement} />
                        <Redirect to={{pathname: `/branches_management`, state: {from: this.props.location}}} />}
                        {/*<Route path="/" component={UsersManagement} />*/}
                    </Switch>
                    : <Redirect to={{pathname: `/login`, state: {from: this.props.location}}} />}
                </div>
            </div>
        )
    }
};


export default withRouter(connect(state => ({
    user: state.auth.user,
    data: state.app.data
}), {
    getData
})(MainLayoutPages));
