import React, {PureComponent, useState} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";

import InputAdornment from "@material-ui/core/InputAdornment";
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import userIcon from '../../../shared/img/user.svg';
import keyIcon from '../../../shared/img/suche.svg';

import CheckBox from '../../../shared/components/form/CheckBoxField/CheckBoxField';

import { localize } from '../../../services/translation/localization';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    }
}));

const renderTextField = ({
                             input, type, label, placeholder = '', className = '', icon, meta: { touched, error }, children, select,
                         }) => {
    let classes = useStyles();
    const [passwordVisible, setPasswordVisible] = useState(false);

    if (className) {
        classes = classes.formControl + ' ' + className
    }
    return (
        <FormControl className={classes}>
            {label && <InputLabel htmlFor="input-with-icon-adornment">{label}</InputLabel>}
            <Input
                value={input.value}
                type={passwordVisible ? 'text' : type}
                placeholder={placeholder}
                onChange={input.onChange}
                error={touched && error}
                id="input-with-icon-adornment"
                startAdornment={
                    <InputAdornment position="start">
                        {icon && <img src={icon} width="17" height="16" alt=""/>}
                    </InputAdornment>
                }
                endAdornment={ type === 'password' &&
                <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={() => setPasswordVisible(!passwordVisible)}>
                        {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
                    </IconButton>
                </InputAdornment>
                }
            />
        </FormControl>
    )
};

renderTextField.propTypes = {
    input: PropTypes.shape().isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    select: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
    label: '',
    meta: null,
    select: false,
    children: [],
};

class UpdatePasswordForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor() {
        super();
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <form className="material-form" onSubmit={handleSubmit}>
                <Field component={renderTextField} label={localize(null, 'pages.login.oldPassword')} className="mt-5" placeholder={localize(null, 'pages.login.oldPassword')} icon={keyIcon} name="oldPassword" type="password"/>
                <Field component={renderTextField} label={localize(null, 'pages.login.newPassword')} className="mt-4" placeholder={localize(null, 'pages.login.newPassword')} icon={keyIcon} name="newPassword" type="password"/>
                <Field component={renderTextField} label={localize(null, 'pages.login.newPassword2')} className="mt-4" placeholder={localize(null, 'pages.login.newPassword2')} icon={keyIcon} name="newPassword2" type="password"/>
                <button className="account__btn">{localize(null, 'pages.login.update')}</button>
                <div className="account-remember">
                    <Field name={localize(null, 'pages.login.rememberMe')} label={localize(null, 'pages.login.rememberMe')} component={CheckBox}/>
                    <Link to={'#'}>{localize(null, 'pages.login.forgotPassword')}</Link>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'reset_password_form',
})(UpdatePasswordForm);
