import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguageSwitch from './TopbarLanguageSwitch';
import TextLocalize from '../../../shared/components/TextLocalize/TextLocalize';
import logo from "../../../shared/img/logo/logo.svg";
import userIcon from "../../../shared/img/top-bar/user.svg";
import logoutIcon from "../../../shared/img/top-bar/logout.svg";
import settingsIcon from "../../../shared/img/top-bar/settings.svg";
import {FaPhone} from "react-icons/fa";

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { title, user, changeMobileSidebarVisibility, changeSidebarVisibility, logout, language, setLanguage } = this.props;
    return (
        <div className="topbar">
          <div className="topbar__wrapper">
            <div className="topbar__left">
              {/*<TopbarSidebarButton*/}
              {/*  changeMobileSidebarVisibility={changeMobileSidebarVisibility}*/}
              {/*  changeSidebarVisibility={changeSidebarVisibility}*/}
              {/*/>*/}
              <Link className="topbar__title" to="/"> 
                <div className="topbar-logo">
                  <img src={logo} alt="logo" />
                </div>
              </Link>
            </div>
            <div className={"topbar__center"}><TextLocalize text={title} /></div>
            <div className="topbar__right">
              {/*<Link className="topbar__link" to={'/service_calls'}>*/}
              {/*  <img className="icon" src={userIcon} alt=""/>*/}
              {/*</Link>*/}
              <Link className="topbar__link" onClick={logout} to={'/login'}>
                <img className="icon" src={logoutIcon} alt=""/>
              </Link>
              {/*<Link className="topbar__link" to={'/service_calls'}>*/}
              {/*  <img className="icon" src={settingsIcon} alt=""/>*/}
              {/*</Link>*/}
              {/*<TopbarLanguageSwitch language={language} setLanguage={setLanguage} />*/}
              {/*<TopbarProfile user={user} />*/}
            </div>
          </div>
        </div>
    );
  }
}

export default Topbar;
