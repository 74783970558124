import React from "react";
import {connect} from "react-redux";
import {localize, localized} from "../../services/translation/localization";

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';
import moment from "moment";
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import Enums from "../../services/enums";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";

class ServicesManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async onCreate(doc) {

    }

    componentDidMount() {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.services_management'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.services_management'));
        }
    }


    render() {
        const {enums} = this.props;

        const tableConfig = {
            model: `services`,
            modelDisplayName: localize(null, 'pages.titles.service'),
            actions: ['view', 'edit'],
            endpoints: {
                create: "services/create",
                edit: "services"
            },
            handlers: {
                // "PRE_CREATE": this.onCreate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false,
                    showFilter: false,
                },
                {
                    accessor: "label",
                    alias: localize(null, "pages.services.name"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "cost",
                    alias: localize(null, "pages.services.cost"),
                    type: "number",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "appointmentDuration",
                    alias: localize(null, "pages.services.appointmentDuration"),
                    type: "number",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "notes",
                    alias: localize(null, "pages.services.notes"),
                    type: "textarea",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "advertisement",
                    alias: localize(null, "pages.services.advertisement"),
                    type: "textarea",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true
                },
                {
                    accessor: "paymentOptions",
                    alias: localize(null, "smartTable.fields.paymentOptions"),
                    type: "multiSelect",
                    options: enums.paymentOptions.map(e => ({label: e.display, value: e.value})),
                    displayModes: [SmartFormMode.new, SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false
                },
                // {
                //     accessor: "allowOnlinePayment",
                //     alias: localize(null, "pages.services.allowOnlinePayment"),
                //     type: "boolean",
                //     displayModes: [SmartFormMode.edit, SmartFormMode.view],
                //     allowTableView: true,
                //     show: true,
                //     filterable: false
                // },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                }
            ],
            restOptions: {
                // filter: {role: "CLIENT"},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }

        const { taskTypesModalService } = this.state;
        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.services_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {tableConfig && <SmartWrapper filterable={false} allowAdd={false} allowExport={true} viewButtonCallback={this.handleViewService}
                                                  tableConfig={tableConfig}/>}
                </Row>
                {/*{taskTypesModalService && <TaskTypes service={taskTypesModalService} onClose={() => this.setState({taskTypesModalService: null})}/>}*/}
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    enums: state.app.enums,
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesManagement);
