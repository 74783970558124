import http from "./http";
import SmartFormMode from "../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";

class Permissions {

    constructor() {
        this.rolesFieldsPermissions=[]
        this.userScreensPermissions=[]
        this.userFieldsPermissions=[]
        this.roles=[]
    }

    async init(userID, userRole) {
        //Get User Permissions
        let userPermissions = await http.get(`api/User/permissions?userid=${userID}`);

        //Set User Screen Permissions
        if (userPermissions.screens) {
            this.userScreensPermissions = userPermissions.screens.filter(s => s.RoleID == userRole)

            //Sort and Set User Fields Permissions
            let fieldPermissions = userPermissions.fields.filter(s => s.RoleID == userRole);
            let sortedFieldPermissions = {};
            fieldPermissions.map((obj) => {
                if (!sortedFieldPermissions[obj.TableName])
                    sortedFieldPermissions[obj.TableName] = {};
                if (!sortedFieldPermissions[obj.TableName][obj.FieldName])
                    sortedFieldPermissions[obj.TableName][obj.FieldName] = {};
                sortedFieldPermissions[obj.TableName][obj.FieldName]["IsView"] = obj.IsView;
                sortedFieldPermissions[obj.TableName][obj.FieldName]["IsEdit"] = obj.IsEdit;
            })
            this.userFieldsPermissions = (sortedFieldPermissions);
        }
        //Get Roles
        this.setRoles();

        //Get Screen Permissions for all roles
        //Sort and set Screen Permissions for all roles
        this.fetchRolesScreenPermissions()

        //Sort and set Fields permissions for all roles
        this.fetchRolesFieldsPermissions()
    }


    getUserScreenPermissions() {
        return this.userScreensPermissions;
    }

    getUserFieldPermissions() {
        return this.userFieldsPermissions;
    }

    getTablePermissions(tableName){
        return this.userFieldsPermissions[tableName];
    }

    enforcePermissions(tableConfig){
        const fieldPermissions = this.getTablePermissions(tableConfig.model) || [];

        tableConfig.fields.map((item, index) => {
            if (!item.displayModes)
                item.displayModes = [];
            let perm = fieldPermissions[item.accessor];
            if (perm) {
                if (!perm.IsView) {
                    item.displayModes = item.displayModes.filter(d => d !== SmartFormMode.view);
                    item.allowTableView = false;
                    item.show = false;
                }
                if (!perm.IsEdit){
                    item.displayModes =  item.displayModes.filter(d=>d !== SmartFormMode.edit);
                }
            }
        });
        return tableConfig;
    }

    async setRoles(){
        let roles= await http.get(`UserRoles`);
        this.roles=roles.value;
    }

    getRoles() {
        return this.roles;
    }

    getAllRolesScreenPermissions(){
        return this.rolesScreensPermissions
    }

    async fetchRolesScreenPermissions(){
        let rolesScreensPermissions = await http.get('RolesAndScreens')
        let rolesScreensPermissionsSorted={};
        rolesScreensPermissions.value.map((obj)=>{
            if (!rolesScreensPermissionsSorted[obj.RoleID])
                rolesScreensPermissionsSorted[obj.RoleID]=[];
            rolesScreensPermissionsSorted[obj.RoleID].push(obj);
        })
        this.rolesScreensPermissions=rolesScreensPermissionsSorted;
    }

    async fetchRolesFieldsPermissions(){
        let rolesFieldsPermissions=await http.get("FieldPermissionsAndRoles");
        rolesFieldsPermissions=rolesFieldsPermissions.value;
        let rolesFieldsPermissionsSorted = {};
        rolesFieldsPermissions.map((obj)=>{
            if (!rolesFieldsPermissionsSorted[obj.RoleID])
                rolesFieldsPermissionsSorted[obj.RoleID]=[];
            rolesFieldsPermissionsSorted[obj.RoleID].push(obj)
        })

        this.rolesFieldsPermissions=rolesFieldsPermissionsSorted
    }

    getRoleScreenPermissions(roleID){
        return this.rolesScreensPermissions[roleID];
    }

    getRoleFieldPermissions(roleID){
        return this.rolesFieldsPermissions[roleID];
    }



}

export default new Permissions();
