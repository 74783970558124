import PropTypes from "prop-types";
import React, { Component } from "react";
import FieldParameter from "../../../../../../FieldParameter/FieldParameter";

class renderField extends Component {
    constructor() {
        super();
        this.state = {
            value: null
        }
        this.textareaNode = React.createRef();
    }

    closeParameter= ()=>{
        this.setState({showParameter: false})
    }

    render() {
        const {
            input,
            placeholder,
            fieldStyles,
            parameterOptions,
            meta: { touched, error },
            className,
            disabled
        } = this.props;
        return (
            <div className={`form__form-group-input-wrap`}>
                <textarea
                    {...input}
                    disabled={disabled}
                    onChange={(e) => {
                        input.onChange(e.target.value)
                    }}
                    className={className}
                    ref={this.textareaNode}
                    placeholder={placeholder}
                />
                {/*{*/}
                    {/*parameterOptions*/}
                        {/*? this.state.showParameter?*/}
                        {/*<FieldParameter*/}
                            {/*options={parameterOptions}*/}
                            {/*editField={this.textareaNode}*/}
                            {/*addValue={(e) => this.setState({ value: e })}*/}
                            {/*closeParameter={this.closeParameter}*/}
                        {/*/> :*/}
                            {/*<button*/}
                                {/*onClick={() => this.setState({showParameter: true})}*/}
                                {/*className="action-icon-plus"*/}
                            {/*>*/}
                            {/*</button>*/}
                        {/*: null*/}
                {/*}*/}
                {
                    touched && error &&
                    <span className="form__form-group-error">
                        {error}
                    </span>
                }
            </div>
        );
    }
}

renderField.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
};

export default renderField;
