import {
  LOGIN_USER,
  LOGOUT_USER
} from '../actions/authActions';

const initialState = {
  pinCode: null,
  user: null,
};

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case LOGIN_USER:
      return { ...state, user: payload.user };
    case LOGOUT_USER:
      return { ...state, user: payload };
    default:
      return state;
  }
}
