import React from "react";
import {connect} from "react-redux";
import {localize, localized} from "../../services/translation/localization";
import {required, email} from '../../services/validation'

// actions
import {setTitle, setTableConfig} from '../../redux/actions/appActions';
import moment from "moment";
import {Col, Container, Row} from "reactstrap";
import {SmartWrapper} from "../../shared/components/SmartWrapper/src";
import SmartFormMode from "../../shared/components/SmartWrapper/src/components/smartForm/SmartFormMode";
import {FaLockOpen} from "react-icons/fa";
import TaskTypes from "./TaskTypesModal";
import http from "../../services/http";

const ResetPassword = ({row, callback}) => (
    <div key={row._id} onClick={(event) => {
        event.stopPropagation();
        callback(row);
    }}>
        <FaLockOpen style={{cursor: 'pointer'}} color={'#C1C7E6'} size={20}/>
    </div>
);

class UsersManagement extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};
        const {enums, branches} = this.props;
        this.tableConfig = {
            model: `users`,
            modelDisplayName: localize(null, 'pages.titles.user'),
            excelFileName: "דוח משתמשים",
            actions: ['view', 'edit',
                // {callback: (data) => this.resetPassword({user: data}), element: ResetPassword},
                {
                    callback: (data) => this.setState({task: data, showCancelModal: true}),
                    className: "custom-action",
                    title: localize(null, "pages.users.resetPassword"),
                    icon: "lock_open"
                }],
            endpoints: {
                create: "users/create",
                edit: "users"
            },
            handlers: {
                "PRE_CREATE": this.preCreate,
                "PRE_SAVE": this.preUpdate,
            },
            IDAccessor: "_id",
            allowEdit: true,
            allowCreation: true,
            fields: [
                {
                    accessor: "_id",
                    alias: localize(null, "smartTable.fields.id"),
                    type: "number",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: false,
                    filterable: false,
                    showFilter: false,
                    searchable: false
                },
                {
                    accessor: "fullname",
                    alias: localize(null, "pages.login.name"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true,
                    validators: [required]
                },
                {
                    accessor: "phone",
                    alias: localize(null, "smartTable.fields.phone"),
                    type: "text",
                    // minWidth: 100,
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true,
                    validators: [required]
                },
                {
                    accessor: "email",
                    alias: localize(null, "smartTable.fields.email"),
                    type: "text",
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    searchable: true,
                    validators: [required, email]
                },
                {
                    accessor: "password",
                    alias: localize(null, "pages.login.password"),
                    type: "text",
                    // value: (data, mode, user) => {
                    //     return mode === 'new' ? Math.random().toString(36).slice(-8) : data.Password;
                    // },
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: false,
                    show: true,
                    filterable: false,
                    validators: []
                },
                {
                    accessor: "branch",
                    alias: localize(null, "smartTable.fields.branch"),
                    type: "select",
                    // minWidth: 150,
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    options: branches.map((e) => {
                        return {
                            value: e._id,
                            label: e.displayName,
                        }
                    }),
                    validators: []
                },
                {
                    accessor: "role",
                    alias: localize(null, "smartTable.fields.role"),
                    type: "select",
                    // minWidth: 100,
                    displayModes: [SmartFormMode.edit, SmartFormMode.view, SmartFormMode.new],
                    allowTableView: true,
                    show: true,
                    filterable: false,
                    optionsKey: "UserRoles",
                    options: enums.permissions.map((e) => {
                        return {
                            value: e.value,
                            label: e.display,
                        }
                    }),
                    validators: [required]
                },
                {
                    accessor: "status",
                    alias: localize(null, "smartTable.fields.status"),
                    type: "select",
                    // minWidth: 100,
                    options: enums.userStatus.map((e) => {
                        return {
                            value: e.value,
                            label: e.display,
                        }
                    }),
                    displayModes: [SmartFormMode.edit, SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "createdAt",
                    alias: localize(null, "smartTable.fields.created"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "updatedAt",
                    alias: localize(null, "smartTable.fields.lastUpdateTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                },
                {
                    accessor: "lastLoginTime",
                    alias: localize(null, "smartTable.fields.lastLoginTime"),
                    type: "dateTime",
                    displayModes: [SmartFormMode.view],
                    allowTableView: true,
                    show: true,
                    filterable: false
                }
            ],
            restOptions: {
                filter: {role: ["admin", "employee"]},
                pageSize: 10,
                pagination: true,
                defaultSort: {
                    field: "createdAt",
                    order: "desc"
                }
            },
        }
    }

    preCreate = async (doc) => {
        doc = Object.keys(doc)
            .filter(field => this.tableConfig.fields.find(f => f.accessor === field && f.displayModes.includes(SmartFormMode.new)))
            .reduce((obj, key) => {
                obj[key] = doc[key];
                return obj;
            }, {});
        doc.permissions = [doc.role];
        return doc;
    }

    preUpdate = async (doc) => {
        doc = Object.keys(doc)
            .filter(field => this.tableConfig.fields.find(f => f.accessor === field && f.displayModes.includes(SmartFormMode.edit)))
            .reduce((obj, key) => {
                obj[key] = doc[key];
                return obj;
            }, {});
        doc.permissions = [doc.role];
        return doc;
    }

    componentDidMount () {
        let {language} = this.props;
        this.props.setTitle(localized(language.name)('pages.titles.users_management'));

    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.language.name !== this.props.language.name) {
            let {language} = this.props;
            this.props.setTitle(localized(language.name)('pages.titles.users_management'));
        }
    }

    resetPassword = async ({user}) => {
        await http.post('users/resetPassword', {email: user.email});
    }

    render () {
        return (
            <Container className="resource-management">
                <Row>
                    <Col md={12}>
                        <p className="page-desc"> {localize(null, "pages.titles.users_management_secondary")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {this.tableConfig && <SmartWrapper filterable={false} allowAdd={true} allowExport={true}
                                                       viewButtonCallback={this.handleViewUser}
                                                       tableConfig={this.tableConfig}/>}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    tableConfig: state.app.tableConfig,
    language: state.app.language,
    enums: state.app.enums,
    branches: state.app.data.branches
});

const mapDispatchToProps = {
    setTitle,
    setTableConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersManagement);
