import React, { Component } from 'react';
import Select from "react-select";

//style
import { generalStyle } from './FieldParameterStyle';

const FieldParameter = ({ options, editField, closeParameter }) => {
    const insertAtCursor = (myField, myValue) => {
        // MOZILLA and others
        if (myField.selectionStart || myField.selectionStart == '0') {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;
            myField.value = myField.value.substring(0, startPos)
                + myValue
                + myField.value.substring(endPos, myField.value.length);
        } else {
            myField.value += myValue;
        }
        return myField.value;
    }

    const onSelectChange = (e) => {
        if (editField) insertAtCursor(editField.current, e.value)
        closeParameter();
    }
    return (
        <>
            <Select
                placeholder={'Add custom parameter'}
                options={options}
                onChange={onSelectChange}
                styles={{ ...generalStyle }}
            />
        </>
    );
}

export default FieldParameter;