export default {
    "languages": [
        {
            "name": "en",
            "displayName": "English",
            "dir": "ltr"
        },
        {
            "name": "he",
            "displayName": "עברית",
            "dir": "rtl"
        }
    ]
}