import _ from "lodash";
import moment from 'moment';
import SmartService from "../../SmartWrapperService";

class SmartTableService {
    requestData = async (tableState, tableConfig, filters) => {
        const {model, restOptions} = tableConfig;

        let options = {
            filter: {},
            paging: {},
            sort: {}
        };

        if (restOptions.pagination) {
            options.paging = {
                pageSize: 10,
                page: tableState.page || 0
            }
        }

        if (restOptions.filter) {
            options.filter = {...restOptions.filter};
        }

        if (filters && filters.filter   ) {
            options.filter = filters.filter.fields.reduce((filters, f) => {
                let filter;
                switch (f.type) {
                    case 'text' :
                        filter = {name: f.name, searchText: f.searchText};
                        break;
                    case 'number':
                        filter = {name: f.name, searchText: Number(f.searchText)};
                        break;
                    case 'enum' :
                    case 'select' :
                        filter = {name: f.name, searchText: f.searchText};
                        break;
                    case 'dateTime':
                        filter = {name: f.name, searchText: {$gte: f.start, $lte: f.end}};
                        break;
                    case 'boolean':
                        filter = {name: f.name, searchText: f.searchText === "Yes"};
                        break;
                    default:
                        filter = {name: f.name, searchText: f.searchText};
                        break;
                }
                filters[filter.name] = filter.searchText;
                return filters;
            }, options.filter);
        }

        if (tableState.sorted && tableState.sorted.length) {
            options.sort = `${tableState.sorted[0].id} ${tableState.sorted[0].desc === false ? 'desc' : 'asc'}`
        } else if (restOptions.defaultSort) {
            options.sort = `${restOptions.defaultSort.field} ${restOptions.defaultSort.order}`
        }

        if (filters && filters.search && filters.search.text) {
            options = {
                filter: options.filter,
                search: {
                    fields: filters.search.fields,
                    text: filters.search.text,
                    sort: filters.search.sort,
                    AND: false
                },
                paging: {
                    pageSize: 10,
                    page: tableState.page || 0
                },
                sort: options.sort
            };
        }

        if (tableState)
            return await SmartService.get(model, options)
    }

    /// Example for request
    requestMoreData = async (tableState, data, tableConfig, filters) => {
        const {filtered, sorted, page, pageSize} = tableState;

        let requestData = await this.requestData(tableState, tableConfig, filters);

        let pages = requestData.total / pageSize;

        pages = Math.ceil(pages);

        const res = {
            rows: requestData.data,
            pages
        };

        return res
    };

    // table types
    getFieldValue = function (item, field) {

        let retVal;
        let val = this.getFieldByPath(item, field.prop);
        switch (field.type) {
            case 'multiselect':
                retVal = val ? val.map(el => field.options.filter(option => option._id == el)[0].displayName).join(', ') : '';
                break;
            case 'select':
                if (field.options && (val !== null || val !== undefined)) {
                    const result = field.options.find(option => option.value == val);
                    retVal = result ? result.label : ''
                } else {
                    retVal = '';
                }
                break;
            case 'date':
                retVal = (val && moment(val).isValid()) ? moment(val).format('DD/MM/YYYY') : val;
                break;
            case 'dateTime':
                retVal = (val && moment(val).isValid()) ? moment(val).format('DD/MM/YYYY HH:mm') : val;
                break;
            case 'boolean':
                retVal = val ? 'Yes' : 'No';
                break;
            case 'currency':
                field.suffix = '₪';
                retVal = val ? val + field.suffix : '-';
                break;
            case 'number':
                retVal = field.precision >= 0 && val ? parseFloat(val.toFixed(field.precision)) : val;
                break;
            case 'range':
                retVal = val.from === val.to ? val.from : val.from + '-' + val.to;
                break;
            case 'image':
                retVal = val ? Array.isArray(val) ? val.map(image => (`<img height="50" src="${image}"/>`)) : `<img height="50" src="${val}"/>` : '-';
                break;
            default:
                retVal = `${val ? val : '-'}${val && field.suffix || ''}`;
        }

        return retVal;

    }
}

export default new SmartTableService();
