import {localize} from "../../services/translation/localization";
import {Modal} from "reactstrap";
import React from "react";

const ConfirmationModal = ({isOpen, onConfirm, onCancel, title, description}) => {
    return (
        <Modal contentClassName="sf-modal" isOpen={isOpen}>
            <div className='messages-modal-container'>
                <div className='messages-modal-title'>
                    {title}
                </div>
                <div className={"payment-modal-content"}>
                    <div className={"text-container"}>
                        {description}
                    </div>
                    <div className={"buttons-container"}>
                        <button
                            className='messages-cancel'
                            type='submit'
                            onClick={onCancel}
                        >
                            {localize(null, "smartTable.cancel")}
                        </button>
                        <button
                            className='messages-confirm'
                            type='submit'
                            onClick={onConfirm}
                        >
                            {localize(null, "smartTable.confirm")}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
};
export default ConfirmationModal;
