import React, {Component, Fragment} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
import {HashRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import http from '../../services/http';
import Storage from '../../services/storage';
import Enums from "../../services/enums"
import SmartWrapperService from '../../shared/components/SmartWrapper/src/SmartWrapperService';
import {getStoredUser} from "../../redux/actions/authActions";
import {getData, setEnums} from "../../redux/actions/appActions";
import {showError, showMessage} from "../../redux/actions/messageActions";


SmartWrapperService.setProvider(http);
const $ = window.$;


class App extends Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            loaded: false,
            user: Storage.get('user')
        };

        // if (this.state.user && this.state.user.Token)
        //     http.addHeader(
        //         'Authorization', `${this.state.user.Token}`
        //     );
    }

    async componentDidMount () {
        // await Enums.init();
        await this.props.setEnums();
        const user = await this.props.getStoredUser();
        if (user) {
            await this.props.getData();
            await Enums.init();
        }
        this.setState({loading: false, loaded: true});
        // window.addEventListener('load', async () => {
        //     this.setState({loading: false});
        //     setTimeout(() => this.setState({loaded: true}), 500);
        // });
        // const {user} = this.state;
        // if (!user)
        //     return;
        // await Permissions.init(this.state.user.id, this.state.user.Role);
    }

    render () {
        const {loaded, loading} = this.state;
        return (
            <HashRouter basename='/'>
                <ScrollToTop>
                    <Fragment>
                        {loaded ?
                            <Router/> :
                            <div className={`load${loading ? '' : ' loaded'}`}>
                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                                    </svg>
                                </div>
                            </div>
                        }
                    </Fragment>
                </ScrollToTop>
            </HashRouter>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    getStoredUser,
    getData,
    setEnums
};
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default hot(module)(ConnectedApp);
