import React from 'react'
import Select, {components} from 'react-select';
import downArrow from '../../assets/component/down-arrow-icon.svg';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={downArrow} alt="" className="arrow"/>
        </components.DropdownIndicator>
    );
};

const Input = props => {
    const value = props.selectProps.value.length > 0 ? props.selectProps.value[0].value : '';
    return <components.Input {...props} data-og={props.selectProps.name} value={value} style={{color: 'transparent'}}/>;
};

const CustomSelect = ({name, value, defaultValue, onCustomChange = () => {}, noOptionsMessage = () => 'לא נמצאו תוצאות',
                          isMulti, isSearchable, isDisabled, input, options, label, placeholder = "", dataOg,
                          labelIcon, required, width = "100px", containerClass = '', meta: {touched, error}}) => {
    const errorClass = touched && error ? 'error-color' : '';
    const containerClasses = ['input-group', containerClass, errorClass];
    const inputStyle = {
        fontSize: '16px',
        letterSpacing: '0.37px',
        color: '#0067a5',
        padding: '0',
        textAlign: 'start'
    };

    const customStyles = {
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
            // margin: '5px 0 0 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            visibility: 'hidden',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            ...inputStyle,
        }),
        option: (provided, state) => ({
            ...provided,
            ...inputStyle,
            padding: '10px',
            color: state.isSelected ? 'white' : '#5b5a5f',
        }),
        input: (provided, state) => ({
            ...inputStyle,
            fontSize: '16px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            ...inputStyle,
            fontSize: '16px',
            color: '#a19a9a',
        }),
        control: (provided, state) => ({
            display: 'flex',
            flexDirection: 'row',
            minWidth: width,
            height: '20px',
            alignItems: 'center',
            padding: '4px 10px',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            opacity: state.isDisabled ? 0.5 : 1
            // borderBottom: '1px solid #49a044',
            //cursor: 'pointer'
        }),

    };
    const customTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            text: "#0067a5",
            primary: "#0067a5",
            primary25: "#f1f2f3",
            primary50: "#cccccc",
            natural10: "#0067a5",
        }
    });
    return (
        <div className={containerClasses.join(' ')}>
            <div className="label-input">
                <div className="label-wrapper">
                    {labelIcon && <img src={labelIcon} alt=""/>}
                    <label htmlFor={input.name}>{required ? '*' : ''}{label}</label>
                </div>
                <div className="input-wrapper">
                    <Select name={input.name}
                            errorState={touched && error}
                            value={input.value}
                        // defaultValue={defaultValue}
                            isDisabled={isDisabled}
                            onChange={(value) => {
                                input.onChange(value);
                                onCustomChange(value)
                            }}
                            options={options}
                            isMulti={isMulti}
                            components={{DropdownIndicator, Input}}
                            placeholder={placeholder}
                            menuPlacement="auto"
                            noOptionsMessage={noOptionsMessage}
                            styles={customStyles}
                            theme={customTheme}/>
                </div>
            </div>
            {touched && error && <div className='input-error-message'>{error}
            </div>}
        </div>
    )
};
export default CustomSelect;
