import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import {
  sidebarReducer,
  appReducer,
  themeReducer,
  authReducer,
  userReducer,
  messageReducer
} from "../../redux/reducers/index";

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  app: appReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  auth: authReducer,
  user: userReducer,
  message: messageReducer
});

let middleware = [thunk];

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
